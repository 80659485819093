import i18n from "i18next";
import AuthService from "../AuthService/AuthService";
import { showNotification } from "../Notification/Notification";

const errorMessages = [];
errorMessages[403] = "not_authorized";
errorMessages[404] = "not_found";
errorMessages[500] = "server_error";
errorMessages[600] = "token_expired";
errorMessages[601] = "subscription_expired";
errorMessages[602] = "user_exists";
errorMessages[603] = "test_category_does_not_exist";
errorMessages[604] = "collection_not_empty";
errorMessages[605] = "exercise_not_found";
errorMessages[606] = "user_not_found";
errorMessages[607] = "unable_to_delete";
errorMessages[608] = "exercise_category_not_found";
errorMessages[609] = "reset_token_not_found";
errorMessages[610] = "test_not_found";
errorMessages[611] = "test_template_not_found";
errorMessages[612] = "video_not_found";
errorMessages[613] = "resource_not_found";
errorMessages[614] = "club_not_found";
errorMessages[615] = "unsupported_file_type";
errorMessages[616] = "squad_not_found";
errorMessages[617] = "payment_not_found";
errorMessages[618] = "activation_token_not_found";
errorMessages[619] = "activation_token_expired";
errorMessages[620] = "user_role_not_found";
errorMessages[621] = "unauthenticated";
errorMessages[622] = "user_account_not_activated";
errorMessages[623] = "email_does_not_match";
errorMessages[624] = "payment_creation_failed";
errorMessages[625] = "club_members_exceeded";
errorMessages[626] = "review_not_found";
errorMessages[627] = "email_communication_error";
errorMessages[666] = "authentication_failed";

const getErrorMessage = (errorCode) => {
  if (errorMessages[errorCode]) {
    return i18n.t(errorMessages[errorCode]);
  } else {
    return i18n.t("unknown_error");
  }
};

const handleErrorMessage = (errorCode) => {
  if (errorCode === 666) AuthService.logout();
  else if (
    errorCode !== 610 &&
    errorCode !== 611 &&
    errorCode !== 614 &&
    errorCode !== 605
  ) {
    showNotification(getErrorMessage(errorCode));
  }
};

export { handleErrorMessage };
