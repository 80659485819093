import React from 'react';
import './Popup.css';

export const Popup = ({ setPopupData, contentComponent: ContentComponent, contentProps, title }) => {
    const closePopup = () => {
        setPopupData(null);
    }

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <div className={'popup-title'}>{title}</div>
                <button className="close-btn" onClick={closePopup}>✕</button>
                {ContentComponent && <ContentComponent {...contentProps} />}
            </div>
        </div>
    );
};