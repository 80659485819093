import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import tinycolor from "tinycolor2";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormInput,
  FormTextarea,
  Row,
} from "shards-react";
import API from "../Api/API";
import { handleErrorMessage } from "../ErrorMessages/ErrorMessages";
import { showNotification } from "../Notification/Notification";

const ContactForm = ({ primaryColor}) => {
  const { t } = useTranslation(["contact"]);

  const [user, setUser] = useState(null);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const response = await fetchUser();
      setUser(response);
    };
    getData();
  }, []);

  useEffect(() => {
    if (user) {
      setEmail(user.email);
    } else {
      setEmail("");
    }
  }, [user]);

  const fetchUser = () => {
    return axios
      .get(API.url + "api/user/details", {
        withCredentials: true,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        handleErrorMessage(err.response.status);
      });
  };

  const primaryColorText = {
    color: primaryColor,
  };

  const primaryColorBg = {
    backgroundColor: primaryColor,
    borderColor: primaryColor,
  };

  const lighterPrimaryColor = tinycolor(primaryColor).brighten(85).toString();
  const backgroundColorStyle = {
    backgroundColor: lighterPrimaryColor,
  };

  const closeDialog = (e) => {
    e.preventDefault();
    document.getElementById("support_form").style.display = "none";
    document.getElementById("support_message_input").value = "";
    document.getElementById("subject_input").value = "";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisabled(true);
    const text = document.getElementById("support_message_input").value;

    const payload = {
      subject: subject,
      text: text,
      contactEmail: email,
      contactPhoneNumber: phone,
    };

    axios
      .post(API.url + "api/mail/support", payload, { withCredentials: true })
      .then(() => {
        closeDialog(e);
        setDisabled(false);
        showNotification(t("message_sent_successfully"));
      })
      .catch((error) => handleErrorMessage(error.response.status));
  };

  return (
    <div
      id="support_form"
      className="popup_form_container"
      style={{ display: "none" }}
    >
      <Card
        className="popup_form"
        style={backgroundColorStyle}
        onMouseDown={function (event) {
          event.stopPropagation();
        }}
      >
        <Button
          className={"close_dialog_button"}
          style={primaryColorText}
          onClick={closeDialog}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
        <CardBody style={{overflowY: "scroll"}}>
          <Container>
            <Form onSubmit={handleSubmit}>
              <Row className="popup_form_title_container mb-5">
                <Col lg={{ size: 10, offset: 1 }}>
                  <h4
                    style={primaryColorText}
                  >
                    {t("support_text")}
                  </h4>
                </Col>
              </Row>
              <Row className="align_center popup_form_label_row">
                <Col lg={{ size: 10, offset: 1 }}>
                  <label htmlFor="contact_email_input" className="form-label">
                    {t("your_email")}
                  </label>
                </Col>
              </Row>
              <Row className="align_center popup_form_content_row">
                <Col lg={{ size: 10, offset: 1 }}>
                  <FormInput
                    size="lg"
                    type="text"
                    id="contact_email_input"
                    value={email}
                    disabled={true}
                    required
                  />
                </Col>
              </Row>
              <Row className="align_center popup_form_label_row">
                <Col lg={{ size: 10, offset: 1 }}>
                  <label htmlFor="contact_phone_input" className="form-label">
                    {t("your_phone_number")}
                  </label>
                </Col>
              </Row>
              <Row className="align_center popup_form_content_row">
                <Col lg={{ size: 10, offset: 1 }}>
                  <FormInput
                    size="lg"
                    type="text"
                    id="contact_phone_input"
                    value={phone}
                    placeholder={t("your_phone_number")}
                    onChange={(event) => {
                      setPhone(event.target.value);
                    }}
                    required
                  />
                </Col>
              </Row>
              <Row className="align_center popup_form_label_row mt-5">
                <Col lg={{ size: 10, offset: 1 }}>
                  <label htmlFor="subject_input" className="form-label">
                    {t("subject")}
                  </label>
                </Col>
              </Row>
              <Row className="align_center popup_form_content_row">
                <Col lg={{ size: 10, offset: 1 }}>
                  <FormInput
                    size="lg"
                    type="text"
                    id="subject_input"
                    placeholder={t("subject")}
                    value={subject}
                    onChange={(event) => {
                      setSubject(event.target.value);
                    }}
                    required
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg={{ size: 10, offset: 1 }}>
                  <FormTextarea
                    id={"support_message_input"}
                    placeholder={t("message")}
                    style={{ minHeight: '86pt'}}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col lg={{ size: 4, offset: 4 }}>
                  <Button
                    className="width-100 button save-button"
                    style={primaryColorBg}
                    disabled={disabled}
                  >
                    {t("send")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </CardBody>
      </Card>
    </div>
  );
};

export default ContactForm;
