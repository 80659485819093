import React, {createContext, useState} from 'react';
import {Popup} from "./Popup";

export const PopupContext = createContext();

export const PopupProvider = ({ children }) => {

    // const [popupData, setPopupData] = useState({contentComponent: ({message}) => <div>{message}</div>, title: 'Title', contentProps: {message: 'Message'}});
    const [popupData, setPopupData] = useState(null);

    return (
        <PopupContext.Provider value={{ setPopupData }}>
            {popupData && <Popup setPopupData={setPopupData} contentComponent={popupData.contentComponent} contentProps={popupData.contentProps} title={popupData.title}></Popup>}
            {children}
        </PopupContext.Provider>
    );
};
