import { useEffect, useState } from "react";
import { Button, Col, Form, FormInput, FormSelect, Row } from "shards-react";

import axios from "axios";
import { useTranslation } from "react-i18next";
import API from "../Api/API";
import { handleErrorMessage } from "../ErrorMessages/ErrorMessages";

const UserForm = ({
  id,
  display,
  primaryColor,
  onSubmit,
  user,
  userEmail,
  title,
  disabledSaveButton,
  managerForm,
  managerEditing,
  showBackButton,
  onBack,
  showPosition,
}) => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [sex, setSex] = useState("FEMALE");
  const [birthDate, setBirthDate] = useState("");
  const [street, setStreet] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [position, setPosition] = useState(0);

  const [positions, setPositions] = useState([]);

  const { t } = useTranslation(["profile", "positions"]);

  useEffect(() => {
    const getData = async () => {
      if (user) {
        setName(user.name);
        setSurname(user.surname);
        setEmail(user.email);
        setPhoneNumber(user.phoneNumber);
        setSex(user.sex);
        setBirthDate(user.birthDate);
        setStreet(user.street);
        setHouseNumber(user.houseNumber);
        setZipCode(user.zipCode);
        setCity(user.city);
        setCountry(user.country);
        setPosition(user.positionId);

        if (managerEditing) {
          const response = await fetchPositions();
          setPositions(response);
        }
      } else {
        clearValues();
      }

      if (userEmail) {
        setEmail(userEmail);
      } else {
        setEmail("");
      }
    };
    getData();
  }, [user, userEmail]);

  const fetchSport = async () => {
    if (user && user.id) {
      return axios
        .get(API.url + "api/squad/user/" + user.id, { withCredentials: true })
        .then((response) => {
          return response.data.sport ? response.data.sport.id : 0;
        })
        .catch((error) => {
          handleErrorMessage(error.response.status);
        });
    }
  };

  const fetchPositions = async () => {
    const sport = await fetchSport();
    if (sport && sport !== 0) {
      return axios
        .get(API.url + "api/position/all/" + sport, { withCredentials: true })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          handleErrorMessage(error.response.status);
        });
    } else {
      return [];
    }
  };

  const primaryColorText = {
    color: primaryColor,
  };

  const primaryColorBg = {
    backgroundColor: primaryColor,
    borderColor: primaryColor,
  };

  const clearValues = () => {
    setName("");
    setSurname("");
    setEmail("");
    setPhoneNumber("");
    setSex("UNSPECIFIED");
    setBirthDate("");
    setStreet("");
    setHouseNumber("");
    setZipCode("");
    setCity("");
    setCountry("");
    setPosition(0);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(e, {
      name,
      surname,
      email,
      phoneNumber,
      sex,
      birthDate,
      street,
      houseNumber,
      zipCode,
      city,
      country,
      photoPath: user ? user.photoPath : "",
      position,
    });
  };

  const handleBack = (e) => {
    e.preventDefault();
    clearValues();
    onBack();
  };

  return (
    <Form
      id={id}
      onSubmit={handleSubmit}
      style={{ display: display }}
      className="width-100 form_flex_container"
    >
      {title && title !== "" && (
        <Row className="width-100">
          <Col lg={{ size: 10, offset: 1 }} className="align_left">
            <h3 style={{ ...primaryColorText, fontWeight: "bold" }}>{title}</h3>
          </Col>
        </Row>
      )}
      <Row className="input_row width-100 m-0">
        <Col lg={{ size: 5, offset: 1 }}>
          <Col lg={{ size: 12 }} className="label_container">
            <label htmlFor="name_input" className="form-label">
              {t("name") + "*"}
            </label>
          </Col>
          <Col lg={{ size: 12 }}>
            <FormInput
              id="name_input"
              required
              type="text"
              size="lg"
              value={name}
              disabled={managerEditing ? false : true}
              onChange={(e) => setName(e.target.value)}
            />
          </Col>
        </Col>
        <Col lg={{ size: 5 }}>
          <Col lg={{ size: 12 }} className="label_container">
            <label htmlFor="surname_input" className="form-label">
              {t("surname") + "*"}
            </label>
          </Col>
          <Col lg={{ size: 12 }}>
            <FormInput
              id="surname_input"
              required
              type="text"
              size="lg"
              value={surname}
              disabled={managerEditing ? false : true}
              onChange={(e) => setSurname(e.target.value)}
            />
          </Col>
        </Col>
      </Row>
      <Row className="input_row width-100 m-0">
        <Col lg={{ size: 10, offset: 1 }}>
          <Col lg={{ size: 12 }} className="label_container">
            <label htmlFor="email_input" className="form-label">
              {t("email") + "*"}
            </label>
          </Col>
          <Col lg={{ size: 12 }}>
            <FormInput
              id="email_input"
              required
              type="email"
              value={email}
              disabled={true}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Col>
        </Col>
      </Row>
      <Row className="input_row width-100 m-0">
        <Col lg={{ size: 10, offset: 1 }}>
          <Col lg={{ size: 12 }} className="label_container">
            <label htmlFor="phone_input" className="form-label">
              {t("phone_number") + "*"}
            </label>
          </Col>
          <Col lg={{ size: 12 }}>
            <FormInput
              id="phone_input"
              type="text"
              required
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </Col>
        </Col>
      </Row>
      <Row className="input_row width-100 m-0">
        <Col lg={{ size: 3, offset: 1 }}>
          <Col lg={{ size: 12 }} className="label_container">
            <label htmlFor="sex_input" className="form-label">
              {t("sex") + "*"}
            </label>
          </Col>
          <Col lg={{ size: 12 }}>
            <FormSelect
              id="sex_select"
              required
              value={sex}
              onChange={(e) => setSex(e.target.value)}
              style={{
                borderRadius: "0.375rem",
                margin: 0,
                width: "100%",
              }}
            >
              <option value="UNSPECIFIED">{t("unspecified")}</option>
              <option value="MALE">{t("male")}</option>
              <option value="FEMALE">{t("female")}</option>
            </FormSelect>
          </Col>
        </Col>
        <Col lg={{ size: 7 }}>
          <Col lg={{ size: 12 }} className="label_container">
            <label htmlFor="birth_date_input" className="form-label">
              {t("birth_date") + (managerForm ? "" : "*")}
            </label>
          </Col>
          <Col lg={{ size: 12 }}>
            <FormInput
              id="birth_date_input"
              required={!managerForm}
              type="date"
              value={birthDate}
              disabled={managerEditing ? false : true}
              onChange={(e) => setBirthDate(e.target.value)}
            />
          </Col>
        </Col>
      </Row>
      <div className="address_container">
        <Row className="input_row width-100 m-0">
          <Col lg={{ size: 7, offset: 1 }}>
            <Col lg={{ size: 12 }} className="label_container">
              <label htmlFor="street_input" className="form-label">
                {t("street")}
              </label>
            </Col>
            <Col lg={{ size: 12 }}>
              <FormInput
                id="street_input"
                type="text"
                size="sm"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
              />
            </Col>
          </Col>
          <Col lg={{ size: 3 }}>
            <Col lg={{ size: 12 }} className="label_container">
              <label htmlFor="house_number_input" className="form-label">
                {t("house_number")}
              </label>
            </Col>
            <Col lg={{ size: 12 }}>
              <FormInput
                id="house_number_input"
                type="text"
                size="sm"
                value={houseNumber}
                onChange={(e) => setHouseNumber(e.target.value)}
              />
            </Col>
          </Col>
        </Row>
        <Row className="input_row width-100 m-0">
          <Col lg={{ size: 3, offset: 1 }}>
            <Col lg={{ size: 12 }} className="label_container">
              <label htmlFor="zip_input" className="form-label">
                {t("zip")}
              </label>
            </Col>
            <Col lg={{ size: 12 }}>
              <FormInput
                id="zip_input"
                type="text"
                size="sm"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              />
            </Col>
          </Col>
          <Col lg={{ size: 7 }}>
            <Col lg={{ size: 12 }} className="label_container">
              <label htmlFor="city_input" className="form-label">
                {t("city")}
              </label>
            </Col>
            <Col lg={{ size: 12 }}>
              <FormInput
                id="city_input"
                type="text"
                size="sm"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
            </Col>
          </Col>
        </Row>
        <Row className="input_row width-100 m-0">
          <Col lg={{ size: 10, offset: 1 }}>
            <Col lg={{ size: 12 }} className="label_container">
              <label htmlFor="country_input" className="form-label">
                {t("country") + (managerForm ? "" : "*")}
              </label>
            </Col>
            <Col lg={{ size: 12 }}>
              <FormInput
                id="country_input"
                required={!managerForm}
                type="text"
                size="sm"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              />
            </Col>
          </Col>
        </Row>
      </div>
      {managerEditing && showPosition && !managerForm && user && (
        <Row className="input_row width-100 m-0">
          <Col lg={{ size: 10, offset: 1 }}>
            <Col lg={{ size: 12 }} className="label_container">
              <label htmlFor="position_select" className="form-label">
                {t("position")}
              </label>
            </Col>
            <Col lg={{ size: 12 }}>
              <FormSelect
                id="position_select"
                required
                value={position}
                onChange={(e) => setPosition(e.target.value)}
                style={{
                  borderRadius: "0.375rem",
                  margin: 0,
                  width: "100%",
                }}
              >
                <option value={0}>{t("unspecified")}</option>
                {positions.map((pos) => (
                  <option key={pos.id} value={pos.id}>
                    {t(pos.name, { ns: "positions" })}
                  </option>
                ))}
              </FormSelect>
            </Col>
          </Col>
        </Row>
      )}
      {showBackButton && (
        <Row className="m-0">
          <Col lg={{ size: 12, offset: 0 }}>
            <Button
              id={"save_user_button"}
              size="lg"
              onClick={handleBack}
              className="form_button cancel-button width-100"
            >
              {t("back")}
            </Button>
          </Col>
        </Row>
      )}
      <Row>
        <Col lg={{ size: 12, offset: 0 }}>
          <Button
            id={"save_user_button"}
            disabled={disabledSaveButton}
            style={primaryColorBg}
            size="lg"
            className="form_button save-button width-100 mb-3"
          >
            {t("save")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default UserForm;
