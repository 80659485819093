import React, {createContext, Suspense, useContext, useEffect, useRef, useState} from 'react';
import {GraphCanvas} from "./GraphCanvas";
import {ExportUtil} from "./ExportUtil";
import {pdf} from "@react-pdf/renderer";
import {ThemeContext} from "../Theming/ThemingProvider";
import ExportPDF from "./ExportPDF";
import {fetchClub, fetchClubLogo, fetchSquad, fetchUser} from "./ExportService";
import {TableCanvas} from "./TableCanvas";
import html2canvas from 'html2canvas';

export const ExportContext = createContext();

export const ExportProvider = ({children}) => {
    const [exportData, setExportData] = useState(null);
    const {theme} = useContext(ThemeContext);
    const graph = useRef(null);
    const table = useRef(null);
    const [isLoaded, setIsLoaded] = useState(false);

    function getImageSize(url) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.onload = () => resolve({ width: img.width, height: img.height });
            img.onerror = reject;
            img.src = url;
        });
    }

    useEffect(() => {
        (async () => {
            if (exportData) {
                const [clubLogo, clubDetail, userDetail, squadDetail] = await Promise.all([
                    fetchClubLogo(),
                    fetchClub(),
                    exportData.userId ? fetchUser(exportData.userId) : null,
                    !exportData.squadName && exportData.squadId ? fetchSquad(exportData.squadId) : null
                ]);
                let tableUrl;
                let tableImage;
                let tableImages;
                let radarGraphs;
                if(exportData.plotTableFn){
                    if(exportData.tableSubquery) {
                        tableImages = [];
                        for (const r of [...table.current.querySelectorAll(exportData.tableSubquery)]) {
                            let url = await html2canvas(r).then((canvas) => canvas.toDataURL());
                            tableImages.push(url);
                        }
                    } else {
                        tableUrl = await html2canvas(table.current).then((canvas) => canvas.toDataURL());
                        let sizes = await getImageSize(tableUrl);
                        tableImage = {url: tableUrl, width: sizes.width, height: sizes.height}
                    }
                }
                const graphDescription = exportData.graphDescriptionFn?.();
                let graphUrl;
                if (exportData?.plotGraphFn) {
                    const svgTarget = graph.current?.querySelector('svg');
                    const canvasTarget = graph.current?.querySelector('canvas');
                    if (svgTarget) {
                        graphUrl = ExportUtil.getDataUrlFromSvg(svgTarget);
                    } else if (canvasTarget) {
                        graphUrl = await ExportUtil.getDataUrlFromCanvas(canvasTarget);
                    }
                } else if(exportData.getGraphSvgFn){
                    graphUrl = ExportUtil.getDataUrlFromSvg(exportData.getGraphSvgFn());
                } else if(exportData.radarGraphsParent) {
                    const allGraphs = exportData.radarGraphsParent.querySelectorAll('.radar_graph');
                    radarGraphs = [...allGraphs].map(g => ([g.querySelector('.radar_graph_title').innerHTML, ExportUtil.getDataUrlFromSvg(g.querySelector('.graph svg'))]));
                }
                let graphImage;
                if(graphUrl){
                    let sizes = await getImageSize(graphUrl);
                    graphImage = {url: graphUrl, width: sizes.width, height: sizes.height}
                }
                const doPdfDownload = async () => {
                    try {
                        const blob = await pdf(
                            <ExportPDF
                                primaryColor={theme.mainColor}
                                clubLogoSrc={clubLogo}
                                graphImage={graphImage}
                                tableImage={tableImage}
                                clubDetail={clubDetail}
                                squadName={exportData.squadName ?? squadDetail?.name}
                                graphDescription={graphDescription}
                                graphName={exportData.graphName}
                                lastActualization={exportData.lastActualization}
                                setIsLoaded={setIsLoaded}
                                user={userDetail}
                                tableImages={tableImages}
                                radarGraphs={radarGraphs}
                            />
                        ).toBlob();
                        const pdfURL = URL.createObjectURL(blob);
                        if(exportData.goal === 'download') {
                            ExportUtil.downloadURI(pdfURL, `${ExportUtil.getTimestamp()}_${exportData.fileName}.pdf`);
                        } else {
                            ExportUtil.shareBlob(blob, `${ExportUtil.getTimestamp()}_${exportData.fileName}.pdf`);
                        }
                        setExportData(null);
                    } catch (e) {
                        // TODO: provisional solution for Error: ExportPDF suspended while rendering, but no fallback UI was specified.
                        setTimeout(() => doPdfDownload(), 1000);
                    }
                }
                await doPdfDownload();
            }
        })()
    }, [exportData]);

    useEffect(() => {

    }, [isLoaded]);

    return (
        <ExportContext.Provider value={{exportData, setExportData}}>
            {exportData?.plotGraphFn &&
                <div ref={graph} style={{position: "absolute", height: `${exportData.graphHeight}px`, left: 9999, zIndex: 9999}}>
                    <GraphCanvas plotGraphFn={exportData.plotGraphFn}></GraphCanvas>
                </div>}
            {exportData?.plotTableFn && <div style={{opacity: 0}}>
                <div ref={table} style={{position: "absolute", left: 0, zIndex: 9999}}>
                    <TableCanvas plotTableFn={exportData.plotTableFn}></TableCanvas>
                </div>
            </div>}
            {children}
        </ExportContext.Provider>
    );
};
