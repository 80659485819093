import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";

import font from "../../Fonts/LeType - ClioRegular.otf";
import logo_color from "../../Images/logo_color.png";


Font.register({
  family: "Clio",
  src: font,
});

const styles = StyleSheet.create({
  page: {
    fontFamily: "Clio",
    padding: "40px",
  },
  logo: {
    width: "25%",
  },
  clubLogo: {
    width: "10%",
  },
  graphImg: {
    width: "100%"
  },
  graphImgPanel: {
    width: "100%",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  graphImgWrap: {
    width: "450px",
    height: '450px',
  },
  footer: {
    width: "100%",
    position: "absolute",
    bottom: "20px",
    right: "20px",
    textAlign: "right",
    fontSize: "8px",
    color: "#3e2663",
    paddingTop: "15px",
  },
  header: {
    paddingBottom: "15px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },

  marginBottom: {
    marginBottom: "20px"
  },

  paddingSides: {
    padding: "0 15px"
  },

  personalInfoLabel: {
    fontSize: "8px",
  },

  personalInfo: {
    fontSize: "10px",
  },

  personalInfoRow: {
    flexDirection: "row",
    padding: "2px",
  },
});

const ExportPDF = ({
                     primaryColor,
                     clubLogoSrc,
                     lastActualization,
                     graphImage,
                     tableImage,
                     clubDetail,
                     graphDescription,
                     graphName,
                     squadName,
                     user,
                     tableImages,
                     radarGraphs
                   }) => {


  const categoryTitle = {
    color: primaryColor,
    fontWeight: "bold",
    fontSize: "18px",
  };

  const dateTitle = {
    color: primaryColor,
    fontWeight: "bold",
    fontSize: "14px",
  };

  const dateOptions = { year: "numeric", month: "long", day: "numeric" };

  const { t, i18n } = useTranslation([
    "club",
    "test",
    "test_name",
    "test_category_name",
    "profile",
  ]);

  const clubInfo = () => {
    return clubDetail && <View style={{...styles.marginBottom, ...styles.paddingSides}}>
      <Text style={categoryTitle}>{`${t("club")}: ${clubDetail.name}`}</Text>
    </View>
  }

  const squadInfo = () => {
    return squadName && <View style={{...styles.marginBottom, ...styles.paddingSides}}>
      <Text style={categoryTitle}>{`${t("squad", {ns: "profile"})}: ${squadName}`}</Text>
    </View>
  }

  const lastUpdate = () => {
    return lastActualization && <View style={{...styles.marginBottom, ...styles.paddingSides}}>
      <Text style={dateTitle}>
        {t("last_actualization", {ns: 'test'}) +
            ": " +
            lastActualization.toLocaleString(i18n.language, dateOptions)}
      </Text>
    </View>;
  }

  const header = () => {
    return <View style={styles.header} fixed>
      <Image src={logo_color} style={styles.logo} />
      {clubLogoSrc && <Image src={clubLogoSrc} style={styles.clubLogo} />}
    </View>
  }

  const graphNameSection = () => {
    return graphName && <View style={{...styles.marginBottom, ...styles.paddingSides}}>
      <Text style={categoryTitle}>{graphName.trim()}</Text>
    </View>
  }

  const graph = () => {
    return graphImage && <View style={{...styles.marginBottom, ...styles.paddingSides, ...styles.graphImgPanel}}>
      <View style={{...styles.graphImgWrap}}>
        <Image src={graphImage.url} style={styles.graphImg} />
      </View>
    </View>
  }

  const table = () => {
    return tableImage && <View style={{...styles.marginBottom, ...styles.paddingSides}}>
      <Image src={tableImage.url} style={styles.graphImg} />
    </View>
  }

  const tableImags = () => {
    return tableImages && tableImages.map(i => {
      return <View style={{...styles.marginBottom, ...styles.paddingSides}}>
        <Image src={i} style={styles.graphImg} />
      </View>
    })
  }

  const footer = () => {
    return <View style={styles.footer} fixed>
      <Text
          render={({ pageNumber, totalPages }) =>
              `+421 907 899 003     support@healthprofile.digital     www.healthprofile.digital     ${pageNumber} / ${totalPages}`
          }
          fixed
      />
    </View>
  }

  const graphDescr = () => {
    return graphDescription && <View style={{width: "100%", ...styles.marginBottom, ...styles.paddingSides}}>
      <Text style={{fontSize: '12px'}}>
        {graphDescription}
      </Text>
    </View>
  }

  const radars = () => {
    return radarGraphs && (
      <>
      { radarGraphs.map((r, i) => <>
        <View break={i > 0}>
          <View style={{...styles.marginBottom, ...styles.paddingSides}}>
            <Text style={categoryTitle}>{r[0]}</Text>
          </View>
          <View style={{...styles.marginBottom, ...styles.paddingSides, ...styles.graphImgPanel}}>
            <View style={{...styles.graphImgWrap}}>
              <Image src={r[1]} style={styles.graphImg}/>
            </View>
          </View>
        </View>
      </>)}

      </>
    )
  }

  const personalInfo = () => {
    return user && <View style={{...styles.marginBottom, ...styles.paddingSides}}>
      <View style={styles.marginBottom}>
        <Text style={categoryTitle}>{t("personal_info", {ns: "test"})}</Text>
      </View>
      <View style={styles.personalInfoRow}>
        <View style={{width: "20%"}}>
          <Text style={styles.personalInfoLabel}>
            {t("name_and_surname", {ns: "test"})}
          </Text>
        </View>
        <View style={{width: "80%"}}>
          <Text style={styles.personalInfo}>
            {user && user.firstName + " " + user.lastName}
          </Text>
        </View>
      </View>
      <View style={styles.personalInfoRow}>
        <View style={{width: "20%"}}>
          <Text style={styles.personalInfoLabel}>{t("address")}</Text>
        </View>
        <View style={{width: "80%"}}>
          <Text style={styles.personalInfo}>
            {user &&
                user.street +
                " " +
                user.descriptionNumber +
                ", " +
                user.zip +
                " " +
                user.domicile +
                ", " +
                user.country}
          </Text>
        </View>
      </View>
      <View style={styles.personalInfoRow}>
        <View style={{width: "20%"}}>
          <Text style={styles.personalInfoLabel}>{t("birth_date", {ns: "test"})}</Text>
        </View>
        <View style={{width: "80%"}}>
          <Text style={styles.personalInfo}>
            {user &&
                new Date(Date.parse(user.birthday)).toLocaleString(
                    i18n.language,
                    dateOptions
                )}
          </Text>
        </View>
      </View>
      <View style={styles.personalInfoRow}>
        <View style={{width: "20%"}}>
          <Text style={styles.personalInfoLabel}>{t("sex", {ns: "test"})}</Text>
        </View>
        <View style={{width: "80%"}}>
          <Text style={styles.personalInfo}>
            {user &&
                (user.sex === "MALE"
                    ? t("male")
                    : user.sex === "FEMALE"
                        ? t("female")
                        : t("unspecified"))}
          </Text>
        </View>
      </View>
      <View style={styles.personalInfoRow}>
        <View style={{width: "20%"}}>
          <Text style={styles.personalInfoLabel}>{t("phone_number", {ns: "test"})}</Text>
        </View>
        <View style={{width: "80%"}}>
          <Text style={styles.personalInfo}>
            {user && user.phoneNumber}
          </Text>
        </View>
      </View>
      <View style={styles.personalInfoRow}>
        <View style={{width: "20%"}}>
          <Text style={styles.personalInfoLabel}>{t("email")}</Text>
        </View>
        <View style={{width: "80%"}}>
          <Text style={styles.personalInfo}>{user && user.email}</Text>
        </View>
      </View>
    </View>
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {header()}
        {clubInfo()}
        {squadInfo()}
        {personalInfo()}
        {graphNameSection()}
        {lastUpdate()}
        {graph()}
        {radars()}
        {table()}
        {tableImags()}
        {graphDescr()}
        {footer()}
      </Page>
    </Document>
  );


};

export default ExportPDF;
