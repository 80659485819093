import Cookies from "universal-cookie";
import axios from "axios";
import API from "../Api/API";
import {handleErrorMessage} from "../ErrorMessages/ErrorMessages";

export const fetchClubLogo = async () => {
    const cookies = new Cookies();
    const clubId = parseInt(cookies.get("selectedClubId"));
    if (clubId !== 0) {
        return axios
            .get(API.url + "api/club/" + clubId, { withCredentials: true })
            .then(async (res) => {
                const response = res.data;
                if (response.logoPath) {
                    return axios
                        .post(
                            API.url + "api/club/download/" + response.id,
                            {},
                            {
                                withCredentials: true,
                                responseType: "blob",
                            }
                        )
                        .then((res2) => {
                            return window.URL.createObjectURL(new Blob([res2.data]));
                        })
                        .catch(() => {
                            return null;
                        });
                } else {
                    return null;
                }
            })
            .catch((error) => {
                handleErrorMessage(error.response.status);
                return null;
            });
    }
};

export const fetchClub = async () => {
    const clubId = new Cookies().get("selectedClubId");
    return clubId ? axios
        .get(API.url + "api/club/" + clubId, { withCredentials: true })
        .then((res) => res.data)
        .catch((error) => handleErrorMessage(error.response.status)) : null;
};

export const fetchSquad = async (squadId) => {
    return squadId ? axios
        .get(API.url + "api/squad/" + squadId, { withCredentials: true })
        .then((res) => res.data)
        .catch((error) => handleErrorMessage(error.response.status)) : null;
};


export const fetchUser = async (id) => {
    return id ? axios
        .get(API.url + "api/user/id/" + id, {
            withCredentials: true,
        })
        .then((res) => res.data)
        .catch((error) => handleErrorMessage(error.response.status)) : null;
};
