import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import tinycolor from "tinycolor2";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Col,
  Container,
  Form,
  FormInput,
  Row,
  Tooltip,
} from "shards-react";

import female_profile from "../../Images/female_profile.png";
import male_profile from "../../Images/male_profile.png";
import API from "../Api/API";
import AsyncImage from "../AsyncImage/AsyncImage";
import AuthService from "../AuthService/AuthService";
import { enlargeImage } from "../EnlargeImage/EnlargeImage";
import { handleErrorMessage } from "../ErrorMessages/ErrorMessages";
import { showNotification } from "../Notification/Notification";
import UserForm from "../UserForm/UserForm";

const SquadManagersList = ({
  primaryColor,
  squad,
  setDeleteText,
  setOnDelete,
  setRefresh,
}) => {
  const [email, setEmail] = useState("");

  const [addManagerTooltip, setAddManagerTooltip] = useState(false);
  const [removeManagerTooltips, setRemoveManagerTooltips] = useState(false);
  const [disabledSaveButton, setDisabledSaveButton] = useState(false);

  const { t } = useTranslation(["club"]);

  const primaryColorText = {
    color: primaryColor,
  };

  const tableStyle = {
    borderColor: "lightgray",
    verticalAlign: "middle",
  };

  const primaryColorBg = {
    backgroundColor: primaryColor,
    borderColor: primaryColor,
  };

  const lighterBgColorStyle = {
    backgroundColor: "white",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: primaryColor,
  };

  useEffect(() => {
    const getData = async () => {
      if (squad) {
        setRemoveManagerTooltips([...squad.managers].fill(false));
        squad.managers.forEach(async (manager) => {
          if (manager.photoPath === null) {
            if (manager.sex === "FEMALE") manager.photoSrc = female_profile;
            else manager.photoSrc = male_profile;
          }
        });
      }
    };
    getData();
  }, [squad]);

  const showManagerList = useCallback((e) => {
    if (e) e.preventDefault();
    document.getElementById(
      "new_manager_email_form_existing_squad"
    ).style.display = "none";
    document.getElementById("new_manager_form_existing_squad").style.display =
      "none";
    document.getElementById("squad_edit_form").style.display = "block";
    document.getElementById("squad_managers").style.display = "block";
    document.getElementById("squad_managers_list").style.display = "block";
  }, []);

  const saveNewSquadManager = (e, manager) => {
    const existingMember = squad.managers.find(
      (member) => member.email === email
    );
    if (!existingMember) {
      const managers = squad.managers;
      managers.push(manager);

      const payload = {
        id: squad.id,
        name: squad.name,
        clubId: squad.clubId,
        members: squad.members,
        managers: managers,
        sport: squad.sport,
      };

      axios
        .post(API.url + "api/squad/save", payload, { withCredentials: true })
        .then(() => {
          showManagerList(e);
          setRefresh(r => !r);
          showNotification(t("user_saved_as_squad_manager"));
          setEmail("");
        })
        .catch((error) => {
          handleErrorMessage(error.response.status);
          setEmail("");
        });
    } else {
      showManagerList(e);
      setEmail("");
    }
  };

  const createNewClubManager = (e, user) => {
    e.preventDefault();
    setDisabledSaveButton(true);
    const tempMember = {
      id: 0,
      firstName: user.name,
      lastName: user.surname,
      email: user.email,
      phoneNumber: user.phoneNumber,
      sex: user.sex,
      birthday: user.birthDate,
      street: user.street,
      domicile: user.city,
      descriptionNumber: user.houseNumber,
      zip: user.zipCode,
      country: user.country,
      password: "",
    };

    AuthService.checkExpiration().then(() => {
      axios
        .put(API.url + "api/user/register", tempMember, {
          withCredentials: true,
        })
        .then(() => {
          axios
            .get(API.url + "api/user/" + email, { withCredentials: true })
            .then((response) => {
              const member = response.data;
              saveNewSquadManager(e, member);
              setDisabledSaveButton(false);
            })
            .catch((error) => {
              handleErrorMessage(error.response.status);
              setDisabledSaveButton(false);
            });
        })
        .catch((error) => {
          handleErrorMessage(error.response.status);
          setDisabledSaveButton(false);
        });
    });
  };

  const showNewUserForm = (e) => {
    e.preventDefault();

    document.getElementById("squad_edit_form").style.display = "none";
    document.getElementById("squad_managers").style.display = "none";
    document.getElementById(
      "new_manager_email_form_existing_squad"
    ).style.display = "none";
    document.getElementById("new_manager_form_existing_squad").style.display =
      "block";
  };

  const showEmailForm = () => {
    document.getElementById("squad_edit_form").style.display = "none";
    document.getElementById("squad_managers").style.display = "none";
    document.getElementById(
      "new_manager_email_form_existing_squad"
    ).style.display = "block";
    document.getElementById("new_manager_form_existing_squad").style.display =
      "none";
  };

  const showNewManagerForm = (e) => {
    e.preventDefault();

    document.getElementById("squad_managers").style.display = "none";
    document.getElementById("squad_edit_form").style.display = "none";
    document.getElementById("new_manager_form_existing_squad").style.display =
      "none";
    document.getElementById(
      "new_manager_email_form_existing_squad"
    ).style.display = "block";
  };

  const showDeleteManager = (e, index) => {
    const manager = squad.managers[index];
    setDeleteText(
      t("delete_squad_manager_name", {
        name: manager.lastName + " " + manager.firstName,
        squadname: squad.name,
      })
    );
    setOnDelete(() => () => removeMember(e, manager.id));
    document.getElementById("delete_dialog").style.display = "flex";
  };

  const removeMember = (e, userId) => {
    e.preventDefault();

    let managers = squad.managers;
    managers = managers.filter((manager) => manager.id !== userId);
    squad.managers = managers;
    const payload = {
      id: squad.id,
      name: squad.name,
      clubId: squad.clubId,
      members: squad.members,
      managers: managers,
      sport: squad.sport,
    };
    AuthService.checkExpiration().then(() => {
      axios
        .post(API.url + "api/squad/save", payload, { withCredentials: true })
        .then(() => {
          document.getElementById("delete_dialog").style.display = "none";
          setRefresh(r => !r);
          showNotification(t("squad_manager_removed"));
        })
        .catch((error) => {
          handleErrorMessage(error.response.status);
        });
    });
  };

  const checkUserEmail = (e) => {
    e.preventDefault();
    AuthService.checkExpiration().then(() => {
      axios
        .get(API.url + "api/user/" + email, { withCredentials: true })
        .then((response) => {
          const member = response.data;
          saveNewSquadManager(e, member);
        })
        .catch((error) => {
          if (error.response.status === 606) showNewUserForm(e);
          else {
            handleErrorMessage(error.response.status);
          }
        });
    });
  };

  return (
    <Container id="squad_managers_list">
      <Row id="squad_managers">
        <Col lg={{ size: 10, offset: 1 }}>
          <Row className="width-100 align_left">
            <Col lg={{ size: 12 }}>
              <h6 className="test_options_title">
                {t("squad_manager_list") + " "}
                <Button
                  className="button plus-button"
                  id={"add_club_manager_button"}
                  onClick={showNewManagerForm}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <Tooltip
                    target={"#add_club_manager_button"}
                    className="tooltip"
                    open={addManagerTooltip}
                    toggle={() => setAddManagerTooltip(!addManagerTooltip)}
                    placement="top"
                    style={lighterBgColorStyle}
                    noArrow={true}
                  >
                    <span style={primaryColorText} className="mb-0">
                      {t("add_squad_manager")}
                    </span>
                  </Tooltip>
                </Button>
              </h6>
            </Col>
          </Row>
          <Row className="width-100 mb-3">
            <Col lg={{ size: 12 }}>
              <h6 className="align_center" style={{fontSize: "0.85em"}}>
                {t("squad_manager_list_info")}
              </h6>
            </Col>
          </Row>
          {squad && squad.managers && squad.managers.length > 0 ? (
            <table className="table table-hover">
              <tbody>
                {squad &&
                  squad.managers.map((manager, index) => (
                    <tr key={"manager_" + index}>
                      <td className="manager_table_item" style={tableStyle}>
                        <div className="profile_image_container">
                          {manager.photoPath ? (
                            <AsyncImage
                              src={API.url + "api/user/download/" + manager.id}
                              className="profile_photo"
                              onClick={enlargeImage}
                            />
                          ) : (
                            <img
                              src={manager.photoSrc}
                              className="profile_photo"
                              onClick={enlargeImage}
                              alt=""
                            />
                          )}
                        </div>
                      </td>
                      <td className="club_table_item" style={tableStyle}>
                        <h6
                          style={primaryColorText}
                          className="club_member_name"
                        >
                          {manager.lastName + " " + manager.firstName}
                        </h6>
                      </td>
                      <td className="club_table_item" style={tableStyle}>
                        <h6
                          style={primaryColorText}
                          className="club_member_email"
                        >
                          {manager.email}
                        </h6>
                      </td>
                      <td style={tableStyle}>
                        <Button
                          className="button smaller-button"
                          id={"manager_" + manager.id + "_remove_button"}
                          style={primaryColorBg}
                          onClick={(event) => {
                            showDeleteManager(event, index);
                          }}
                        >
                          <FontAwesomeIcon icon={faMinus} />
                          <Tooltip
                            target={"#manager_" + manager.id + "_remove_button"}
                            className="tooltip"
                            open={
                              removeManagerTooltips &&
                              removeManagerTooltips[index]
                            }
                            toggle={() =>
                              setRemoveManagerTooltips((c) => {
                                const temp = [...c];
                                temp[index] = !temp[index];
                                return temp;
                              })
                            }
                            placement="top"
                            style={lighterBgColorStyle}
                            noArrow={true}
                          >
                            <span style={primaryColorText} className="mb-0">
                              {t("remove_squad_manager")}
                            </span>
                          </Tooltip>
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : (
            <h4 style={primaryColorText} className="empty_list_text">
              {t("no_squad_managers")}
            </h4>
          )}
        </Col>
      </Row>
      <Form
        id="new_manager_email_form_existing_squad"
        className="popup_form_content_container height-100"
        style={{ display: "none" }}
      >
        <Row className="width-100 align_center popup_form_label_row">
          <Col lg={{ size: 10, offset: 1 }}>
            <label htmlFor="test_category_global_input" className="form-label">
              {t("squad_manager_email")}
            </label>
          </Col>
        </Row>
        <Row className="width-100 align_right popup_form_content_row ">
          <Col lg={{ size: 10, offset: 1 }}>
            <FormInput
              size="lg"
              type="email"
              id="member_email_input"
              placeholder={t("email")}
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
              required
            />
          </Col>
        </Row>
        <Row className="width-100 mt-4">
          <Col lg={{ size: 4, offset: 4 }}>
            <Button
              className="width-100 button save-button"
              style={primaryColorBg}
              onClick={checkUserEmail}
            >
              {t("add")}
            </Button>
          </Col>
        </Row>
      </Form>
      <Row
        className="popup_form_content_container height-100"
        id={"new_manager_form_existing_squad"}
        style={{ display: "none" }}
      >
        <UserForm
          primaryColor={primaryColor}
          onSubmit={createNewClubManager}
          userEmail={email}
          user={null}
          title={t("new_squad_manager")}
          disabledSaveButton={disabledSaveButton}
          managerForm={true}
          managerEditing={true}
          showBackButton={true}
          onBack={showEmailForm}
        />
      </Row>
    </Container>
  );
};

export default SquadManagersList;
