import { changeLanguage } from "i18next";
import { useEffect, useState } from "react";

const LanguageSelector = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("sk");

  useEffect(() => {
    const lng = localStorage.getItem("i18nextLng");
    if (lng && lng.length > 1) {
      setSelectedLanguage(lng.substring(0, 2));
    }
  }, []);

  return (
    <div>
      <span
        className={
          selectedLanguage === "sk" ? "language language-selected" : "language"
        }
        onClick={() => {
          changeLanguage("sk");
          setSelectedLanguage("sk");
        }}
      >
        sk
      </span>
      <span className="language-separator">|</span>
      <span
        className={
          selectedLanguage === "en" ? "language language-selected" : "language"
        }
        onClick={() => {
          changeLanguage("en");
          setSelectedLanguage("en");
        }}
      >
        en
      </span>
      <span className="language-separator">|</span>
      <span
        className={
          selectedLanguage === "de" ? "language language-selected" : "language"
        }
        onClick={() => {
          changeLanguage("de");
          setSelectedLanguage("de");
        }}
      >
        de
      </span>
    </div>
  );
};

export default LanguageSelector;
