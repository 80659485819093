import React, {createContext, useContext, useEffect, useState} from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children, pc }) => {
    const [theme, setTheme] = useState({
        mainColor: '#000000'
    });

    useEffect(() => setTheme({...theme, mainColor: pc}), [pc]);

    return (
        <ThemeContext.Provider value={{ theme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export const useTheme = () => useContext(ThemeContext);