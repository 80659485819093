import axios from "axios";
import { useEffect, useState } from "react";
import { handleErrorMessage } from "../ErrorMessages/ErrorMessages";

const AsyncImage = (props) => {
  const [loadedSrc, setLoadedSrc] = useState(null);
  useEffect(() => {
    setLoadedSrc(null);
    if (props.src) {
      axios
        .post(props.src, props.body ? props.body : {}, {
          withCredentials: true,
          responseType: "blob",
        })
        .then((res) => {
          setLoadedSrc(window.URL.createObjectURL(new Blob([res.data])));
        })
        .catch((error) => {
          handleErrorMessage(error.response.status);
        });
    }
  }, [props.src, props.refresh]);

  if (loadedSrc) {
    return (
      <img
        {...props}
        src={loadedSrc}
        alt=""
        onContextMenu={(e) => {
          e.preventDefault();
        }}
      />
    );
  }
  return null;
};

export default AsyncImage;
