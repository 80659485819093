let zoomLevel = 1;
let origEventX = null;
let origEventY = null;
let currentMoveX = 0;
let currentMoveY = 0;
let lastMoveX = 0;
let lastMoveY = 0;
let mousedown = false;

export const enlargeImage = (e) => {
  e.preventDefault();
  e.stopPropagation();

  let bg = document.createElement("div");
  bg.id = "image_enlarge_bg";
  bg.className = "enlarged_image_background";
  bg.onclick = function () {
    removeEnlargedImage(this);
  };

  let btnGroup = document.createElement("div");
  btnGroup.classList.add("btn-group", "enlarged-image-btn-group");
  bg.appendChild(btnGroup);

  let button = document.createElement("button");
  button.classList.add("button", "left-button", "enlarged-image-button");
  button.innerHTML = "-";
  button.onclick = function (e) {
    e.stopPropagation();
    zoomImage(-1);
  };
  btnGroup.appendChild(button);

  button = document.createElement("button");
  button.classList.add("button", "right-button", "enlarged-image-button");
  button.innerHTML = "+";
  button.onclick = function (e) {
    e.stopPropagation();
    zoomImage(1);
  };
  btnGroup.appendChild(button);

  addImage(e.target, bg);

  bg.style.animation = "none";
};

const removeEnlargedImage = (background) => {
  mousedown = false;
  origEventX = origEventY = null;
  lastMoveX = currentMoveX = lastMoveY = currentMoveY = 0;

  background.style.opacity = 0;
  background.style.animation = "bg_out 200ms ease-out 0s 1";
  let image = document.getElementById("image_enlarge");
  image.style.transform = "scale(0)";
  image.style.animation = "image_out 300ms ease-out 0s 1";
  setTimeout(function () {
    background.remove();
    image.remove();
    zoomLevel = 1;
  }, 500);
};

const zoomImage = (dir) => {
  let image = document.getElementById("image_enlarge");
  zoomLevel = dir > 0 ? zoomLevel + 0.1 : Math.max(zoomLevel - 0.1, 1);
  image.style.transform = `translate(-50%, -50%) scale(${zoomLevel}) `;
};

const addImage = (target, bg) => {
  let image;

  if (target.classList.contains("resource_image")) {
    image = document.createElement("div");
    image.appendChild(target.cloneNode(true));
  } else {
    image = target.parentNode.cloneNode(true);
  }

  image.id = "image_enlarge";
  image.className = "enlarged_image";
  image.style.width = "90vw";
  image.style.height = "90vh";
  let trueImg = image.getElementsByTagName("img")[0];
  if (trueImg !== null) {
    trueImg.classList.remove(
      "not_full_screen_resource",
      "not_full_screen_image"
    );
    trueImg.classList.add("fullscreen");
    trueImg.style.objectFit = "contain";
    trueImg.style.objectPosition = "center center";
    trueImg.style.display = "block";
    trueImg.oncontextmenu = function (e) {
      e.preventDefault();
    };
  }

  image.onmousedown = function (e) {
    e.preventDefault();
    e.stopPropagation();
    origEventX = e.clientX;
    origEventY = e.clientY;
    mousedown = true;
  };
  image.onmouseup = function (e) {
    e.preventDefault();
    e.stopPropagation();
    origEventX = origEventY = null;
    lastMoveX = currentMoveX;
    lastMoveY = currentMoveY;
    currentMoveX = currentMoveY = 0;
    mousedown = false;
  };
  image.onmousemove = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (mousedown) {
      let x = lastMoveX + e.clientX - origEventX;
      let y = lastMoveY + e.clientY - origEventY;

      currentMoveX = x;
      currentMoveY = y;

      image.style.transform = `translate(calc(-50% + ${x}px), calc(-50% + ${y}px)) scale(${zoomLevel})`;
    }
  };
  image.onclick = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (Math.abs(lastMoveX + lastMoveY) < 1) {
      removeEnlargedImage(bg);
    }
  };

  const removeArray = [];
  image.childNodes.forEach((child) => {
    if (child.tagName !== "IMG") {
      removeArray.push(child);
    }
  });
  removeArray.forEach((child) => {
    image.removeChild(child);
  });

  document.getElementsByTagName("body")[0].appendChild(bg);
  bg.appendChild(image);

  if (trueImg && trueImg.classList.contains("bigger-image")) {
    const smallerImage = document.querySelector(
      ".smaller-image[style='display: none;']"
    );
    if (smallerImage !== null) {
      target = smallerImage;
    }
  }

  let button;
  let prevImage;
  let tempTarget = target;
  while (tempTarget.previousSibling !== null) {
    tempTarget = tempTarget.previousSibling;
    if (tempTarget.tagName === "IMG") {
      prevImage = tempTarget;
      break;
    }
  }
  if (prevImage) {
    button = document.createElement("button");
    button.classList.add(
      "button",
      "left-button",
      "enlarged-image-button",
      "arrow-button",
      "left-arrow-button"
    );
    button.innerHTML = "←";
    button.onclick = function (event) {
      event.stopPropagation();
      showNextImage(prevImage);
    };
    bg.appendChild(button);
  }

  let nextImage;
  tempTarget = target;
  while (tempTarget.nextSibling !== null) {
    tempTarget = tempTarget.nextSibling;
    if (tempTarget.tagName === "IMG") {
      nextImage = tempTarget;
      break;
    }
  }

  if (nextImage) {
    button = document.createElement("button");
    button.classList.add(
      "button",
      "right-button",
      "enlarged-image-button",
      "arrow-button",
      "right-arrow-button"
    );
    button.innerHTML = "→";
    button.onclick = function (event) {
      event.stopPropagation();
      showNextImage(nextImage);
    };
    bg.appendChild(button);
  }
};

const showNextImage = (image) => {
  document.getElementById("image_enlarge").remove();
  const arrowButtons = document.getElementsByClassName("arrow-button");
  while (arrowButtons.length > 0) {
    arrowButtons[0].remove();
  }
  zoomLevel = 1;
  addImage(image, document.getElementById("image_enlarge_bg"));
};
