import requestAnimationFrame from "./raf.js";
import RGBColor from "./rgbcolor.js";
import {SVGPathData} from "./svg-pathdata.js";
import {canvasRGBA} from "./stackblur-canvas.js";
function defaultSetTimout() {
  throw new Error("setTimeout has not been defined");
}
function defaultClearTimeout() {
  throw new Error("clearTimeout has not been defined");
}
var cachedSetTimeout = defaultSetTimout;
var cachedClearTimeout = defaultClearTimeout;
var globalContext;
if (typeof window !== "undefined") {
  globalContext = window;
} else if (typeof self !== "undefined") {
  globalContext = self;
} else {
  globalContext = {};
}
if (typeof globalContext.setTimeout === "function") {
  cachedSetTimeout = setTimeout;
}
if (typeof globalContext.clearTimeout === "function") {
  cachedClearTimeout = clearTimeout;
}
function runTimeout(fun) {
  if (cachedSetTimeout === setTimeout) {
    return setTimeout(fun, 0);
  }
  if ((cachedSetTimeout === defaultSetTimout || !cachedSetTimeout) && setTimeout) {
    cachedSetTimeout = setTimeout;
    return setTimeout(fun, 0);
  }
  try {
    return cachedSetTimeout(fun, 0);
  } catch (e) {
    try {
      return cachedSetTimeout.call(null, fun, 0);
    } catch (e2) {
      return cachedSetTimeout.call(this, fun, 0);
    }
  }
}
function runClearTimeout(marker) {
  if (cachedClearTimeout === clearTimeout) {
    return clearTimeout(marker);
  }
  if ((cachedClearTimeout === defaultClearTimeout || !cachedClearTimeout) && clearTimeout) {
    cachedClearTimeout = clearTimeout;
    return clearTimeout(marker);
  }
  try {
    return cachedClearTimeout(marker);
  } catch (e) {
    try {
      return cachedClearTimeout.call(null, marker);
    } catch (e2) {
      return cachedClearTimeout.call(this, marker);
    }
  }
}
var queue = [];
var draining = false;
var currentQueue;
var queueIndex = -1;
function cleanUpNextTick() {
  if (!draining || !currentQueue) {
    return;
  }
  draining = false;
  if (currentQueue.length) {
    queue = currentQueue.concat(queue);
  } else {
    queueIndex = -1;
  }
  if (queue.length) {
    drainQueue();
  }
}
function drainQueue() {
  if (draining) {
    return;
  }
  var timeout = runTimeout(cleanUpNextTick);
  draining = true;
  var len = queue.length;
  while (len) {
    currentQueue = queue;
    queue = [];
    while (++queueIndex < len) {
      if (currentQueue) {
        currentQueue[queueIndex].run();
      }
    }
    queueIndex = -1;
    len = queue.length;
  }
  currentQueue = null;
  draining = false;
  runClearTimeout(timeout);
}
function nextTick(fun) {
  var args = new Array(arguments.length - 1);
  if (arguments.length > 1) {
    for (var i = 1; i < arguments.length; i++) {
      args[i - 1] = arguments[i];
    }
  }
  queue.push(new Item(fun, args));
  if (queue.length === 1 && !draining) {
    runTimeout(drainQueue);
  }
}
function Item(fun, array) {
  this.fun = fun;
  this.array = array;
}
Item.prototype.run = function() {
  this.fun.apply(null, this.array);
};
var title = "browser";
var platform = "browser";
var browser = true;
var argv = [];
var version = "";
var versions = {};
var release = {};
var config = {};
function noop() {
}
var on = noop;
var addListener = noop;
var once = noop;
var off = noop;
var removeListener = noop;
var removeAllListeners = noop;
var emit = noop;
function binding(name) {
  throw new Error("process.binding is not supported");
}
function cwd() {
  return "/";
}
function chdir(dir) {
  throw new Error("process.chdir is not supported");
}
function umask() {
  return 0;
}
var performance = globalContext.performance || {};
var performanceNow = performance.now || performance.mozNow || performance.msNow || performance.oNow || performance.webkitNow || function() {
  return new Date().getTime();
};
function hrtime(previousTimestamp) {
  var clocktime = performanceNow.call(performance) * 1e-3;
  var seconds = Math.floor(clocktime);
  var nanoseconds = Math.floor(clocktime % 1 * 1e9);
  if (previousTimestamp) {
    seconds = seconds - previousTimestamp[0];
    nanoseconds = nanoseconds - previousTimestamp[1];
    if (nanoseconds < 0) {
      seconds--;
      nanoseconds += 1e9;
    }
  }
  return [seconds, nanoseconds];
}
var startTime = new Date();
function uptime() {
  var currentTime = new Date();
  var dif = currentTime - startTime;
  return dif / 1e3;
}
var process = {
  nextTick,
  title,
  browser,
  env: {NODE_ENV: "production"},
  argv,
  version,
  versions,
  on,
  addListener,
  once,
  off,
  removeListener,
  removeAllListeners,
  emit,
  binding,
  cwd,
  chdir,
  umask,
  hrtime,
  platform,
  release,
  config,
  uptime
};
function offscreen() {
  let {DOMParser: DOMParserFallback} = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
  const preset = {
    window: null,
    ignoreAnimation: true,
    ignoreMouse: true,
    DOMParser: DOMParserFallback,
    createCanvas(width, height) {
      return new OffscreenCanvas(width, height);
    },
    async createImage(url) {
      const response = await fetch(url);
      const blob = await response.blob();
      const img = await createImageBitmap(blob);
      return img;
    }
  };
  if (typeof globalThis.DOMParser !== "undefined" || typeof DOMParserFallback === "undefined") {
    Reflect.deleteProperty(preset, "DOMParser");
  }
  return preset;
}
function node(param) {
  let {DOMParser: DOMParser2, canvas, fetch: fetch2} = param;
  return {
    window: null,
    ignoreAnimation: true,
    ignoreMouse: true,
    DOMParser: DOMParser2,
    fetch: fetch2,
    createCanvas: canvas.createCanvas,
    createImage: canvas.loadImage
  };
}
var index = /* @__PURE__ */ Object.freeze({
  __proto__: null,
  offscreen,
  node
});
function compressSpaces(str) {
  return str.replace(/(?!\u3000)\s+/gm, " ");
}
function trimLeft(str) {
  return str.replace(/^[\n \t]+/, "");
}
function trimRight(str) {
  return str.replace(/[\n \t]+$/, "");
}
function toNumbers(str) {
  const matches = str.match(/-?(\d+(?:\.\d*(?:[eE][+-]?\d+)?)?|\.\d+)(?=\D|$)/gm);
  return matches ? matches.map(parseFloat) : [];
}
function toMatrixValue(str) {
  const numbers = toNumbers(str);
  const matrix = [
    numbers[0] || 0,
    numbers[1] || 0,
    numbers[2] || 0,
    numbers[3] || 0,
    numbers[4] || 0,
    numbers[5] || 0
  ];
  return matrix;
}
const allUppercase = /^[A-Z-]+$/;
function normalizeAttributeName(name) {
  if (allUppercase.test(name)) {
    return name.toLowerCase();
  }
  return name;
}
function parseExternalUrl(url) {
  const urlMatch = /url\(('([^']+)'|"([^"]+)"|([^'")]+))\)/.exec(url);
  if (!urlMatch) {
    return "";
  }
  return urlMatch[2] || urlMatch[3] || urlMatch[4] || "";
}
function normalizeColor(color) {
  if (!color.startsWith("rgb")) {
    return color;
  }
  let rgbParts = 3;
  const normalizedColor = color.replace(/\d+(\.\d+)?/g, (num, isFloat) => rgbParts-- && isFloat ? String(Math.round(parseFloat(num))) : num);
  return normalizedColor;
}
const attributeRegex = /(\[[^\]]+\])/g;
const idRegex = /(#[^\s+>~.[:]+)/g;
const classRegex = /(\.[^\s+>~.[:]+)/g;
const pseudoElementRegex = /(::[^\s+>~.[:]+|:first-line|:first-letter|:before|:after)/gi;
const pseudoClassWithBracketsRegex = /(:[\w-]+\([^)]*\))/gi;
const pseudoClassRegex = /(:[^\s+>~.[:]+)/g;
const elementRegex = /([^\s+>~.[:]+)/g;
function findSelectorMatch(selector, regex) {
  const matches = regex.exec(selector);
  if (!matches) {
    return [
      selector,
      0
    ];
  }
  return [
    selector.replace(regex, " "),
    matches.length
  ];
}
function getSelectorSpecificity(selector) {
  const specificity = [
    0,
    0,
    0
  ];
  let currentSelector = selector.replace(/:not\(([^)]*)\)/g, "     $1 ").replace(/{[\s\S]*/gm, " ");
  let delta = 0;
  [currentSelector, delta] = findSelectorMatch(currentSelector, attributeRegex);
  specificity[1] += delta;
  [currentSelector, delta] = findSelectorMatch(currentSelector, idRegex);
  specificity[0] += delta;
  [currentSelector, delta] = findSelectorMatch(currentSelector, classRegex);
  specificity[1] += delta;
  [currentSelector, delta] = findSelectorMatch(currentSelector, pseudoElementRegex);
  specificity[2] += delta;
  [currentSelector, delta] = findSelectorMatch(currentSelector, pseudoClassWithBracketsRegex);
  specificity[1] += delta;
  [currentSelector, delta] = findSelectorMatch(currentSelector, pseudoClassRegex);
  specificity[1] += delta;
  currentSelector = currentSelector.replace(/[*\s+>~]/g, " ").replace(/[#.]/g, " ");
  [currentSelector, delta] = findSelectorMatch(currentSelector, elementRegex);
  specificity[2] += delta;
  return specificity.join("");
}
const PSEUDO_ZERO = 1e-8;
function vectorMagnitude(v) {
  return Math.sqrt(Math.pow(v[0], 2) + Math.pow(v[1], 2));
}
function vectorsRatio(u, v) {
  return (u[0] * v[0] + u[1] * v[1]) / (vectorMagnitude(u) * vectorMagnitude(v));
}
function vectorsAngle(u, v) {
  return (u[0] * v[1] < u[1] * v[0] ? -1 : 1) * Math.acos(vectorsRatio(u, v));
}
function CB1(t) {
  return t * t * t;
}
function CB2(t) {
  return 3 * t * t * (1 - t);
}
function CB3(t) {
  return 3 * t * (1 - t) * (1 - t);
}
function CB4(t) {
  return (1 - t) * (1 - t) * (1 - t);
}
function QB1(t) {
  return t * t;
}
function QB2(t) {
  return 2 * t * (1 - t);
}
function QB3(t) {
  return (1 - t) * (1 - t);
}
class Property {
  static empty(document2) {
    return new Property(document2, "EMPTY", "");
  }
  split() {
    let separator = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : " ";
    const {document: document2, name} = this;
    return compressSpaces(this.getString()).trim().split(separator).map((value) => new Property(document2, name, value));
  }
  hasValue(zeroIsValue) {
    const value = this.value;
    return value !== null && value !== "" && (zeroIsValue || value !== 0) && typeof value !== "undefined";
  }
  isString(regexp) {
    const {value} = this;
    const result = typeof value === "string";
    if (!result || !regexp) {
      return result;
    }
    return regexp.test(value);
  }
  isUrlDefinition() {
    return this.isString(/^url\(/);
  }
  isPixels() {
    if (!this.hasValue()) {
      return false;
    }
    const asString = this.getString();
    switch (true) {
      case asString.endsWith("px"):
      case /^[0-9]+$/.test(asString):
        return true;
      default:
        return false;
    }
  }
  setValue(value) {
    this.value = value;
    return this;
  }
  getValue(def) {
    if (typeof def === "undefined" || this.hasValue()) {
      return this.value;
    }
    return def;
  }
  getNumber(def) {
    if (!this.hasValue()) {
      if (typeof def === "undefined") {
        return 0;
      }
      return parseFloat(def);
    }
    const {value} = this;
    let n = parseFloat(value);
    if (this.isString(/%$/)) {
      n /= 100;
    }
    return n;
  }
  getString(def) {
    if (typeof def === "undefined" || this.hasValue()) {
      return typeof this.value === "undefined" ? "" : String(this.value);
    }
    return String(def);
  }
  getColor(def) {
    let color = this.getString(def);
    if (this.isNormalizedColor) {
      return color;
    }
    this.isNormalizedColor = true;
    color = normalizeColor(color);
    this.value = color;
    return color;
  }
  getDpi() {
    return 96;
  }
  getRem() {
    return this.document.rootEmSize;
  }
  getEm() {
    return this.document.emSize;
  }
  getUnits() {
    return this.getString().replace(/[0-9.-]/g, "");
  }
  getPixels(axisOrIsFontSize) {
    let processPercent = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    if (!this.hasValue()) {
      return 0;
    }
    const [axis, isFontSize] = typeof axisOrIsFontSize === "boolean" ? [
      void 0,
      axisOrIsFontSize
    ] : [
      axisOrIsFontSize
    ];
    const {viewPort} = this.document.screen;
    switch (true) {
      case this.isString(/vmin$/):
        return this.getNumber() / 100 * Math.min(viewPort.computeSize("x"), viewPort.computeSize("y"));
      case this.isString(/vmax$/):
        return this.getNumber() / 100 * Math.max(viewPort.computeSize("x"), viewPort.computeSize("y"));
      case this.isString(/vw$/):
        return this.getNumber() / 100 * viewPort.computeSize("x");
      case this.isString(/vh$/):
        return this.getNumber() / 100 * viewPort.computeSize("y");
      case this.isString(/rem$/):
        return this.getNumber() * this.getRem();
      case this.isString(/em$/):
        return this.getNumber() * this.getEm();
      case this.isString(/ex$/):
        return this.getNumber() * this.getEm() / 2;
      case this.isString(/px$/):
        return this.getNumber();
      case this.isString(/pt$/):
        return this.getNumber() * this.getDpi() * (1 / 72);
      case this.isString(/pc$/):
        return this.getNumber() * 15;
      case this.isString(/cm$/):
        return this.getNumber() * this.getDpi() / 2.54;
      case this.isString(/mm$/):
        return this.getNumber() * this.getDpi() / 25.4;
      case this.isString(/in$/):
        return this.getNumber() * this.getDpi();
      case (this.isString(/%$/) && isFontSize):
        return this.getNumber() * this.getEm();
      case this.isString(/%$/):
        return this.getNumber() * viewPort.computeSize(axis);
      default: {
        const n = this.getNumber();
        if (processPercent && n < 1) {
          return n * viewPort.computeSize(axis);
        }
        return n;
      }
    }
  }
  getMilliseconds() {
    if (!this.hasValue()) {
      return 0;
    }
    if (this.isString(/ms$/)) {
      return this.getNumber();
    }
    return this.getNumber() * 1e3;
  }
  getRadians() {
    if (!this.hasValue()) {
      return 0;
    }
    switch (true) {
      case this.isString(/deg$/):
        return this.getNumber() * (Math.PI / 180);
      case this.isString(/grad$/):
        return this.getNumber() * (Math.PI / 200);
      case this.isString(/rad$/):
        return this.getNumber();
      default:
        return this.getNumber() * (Math.PI / 180);
    }
  }
  getDefinition() {
    const asString = this.getString();
    const match = /#([^)'"]+)/.exec(asString);
    const name = (match === null || match === void 0 ? void 0 : match[1]) || asString;
    return this.document.definitions[name];
  }
  getFillStyleDefinition(element, opacity) {
    let def = this.getDefinition();
    if (!def) {
      return null;
    }
    if (typeof def.createGradient === "function" && "getBoundingBox" in element) {
      return def.createGradient(this.document.ctx, element, opacity);
    }
    if (typeof def.createPattern === "function") {
      if (def.getHrefAttribute().hasValue()) {
        const patternTransform = def.getAttribute("patternTransform");
        def = def.getHrefAttribute().getDefinition();
        if (def && patternTransform.hasValue()) {
          def.getAttribute("patternTransform", true).setValue(patternTransform.value);
        }
      }
      if (def) {
        return def.createPattern(this.document.ctx, element, opacity);
      }
    }
    return null;
  }
  getTextBaseline() {
    if (!this.hasValue()) {
      return null;
    }
    const key = this.getString();
    return Property.textBaselineMapping[key] || null;
  }
  addOpacity(opacity) {
    let value = this.getColor();
    const len = value.length;
    let commas = 0;
    for (let i = 0; i < len; i++) {
      if (value[i] === ",") {
        commas++;
      }
      if (commas === 3) {
        break;
      }
    }
    if (opacity.hasValue() && this.isString() && commas !== 3) {
      const color = new RGBColor(value);
      if (color.ok) {
        color.alpha = opacity.getNumber();
        value = color.toRGBA();
      }
    }
    return new Property(this.document, this.name, value);
  }
  constructor(document2, name, value) {
    this.document = document2;
    this.name = name;
    this.value = value;
    this.isNormalizedColor = false;
  }
}
Property.textBaselineMapping = {
  baseline: "alphabetic",
  "before-edge": "top",
  "text-before-edge": "top",
  middle: "middle",
  central: "middle",
  "after-edge": "bottom",
  "text-after-edge": "bottom",
  ideographic: "ideographic",
  alphabetic: "alphabetic",
  hanging: "hanging",
  mathematical: "alphabetic"
};
class ViewPort {
  clear() {
    this.viewPorts = [];
  }
  setCurrent(width, height) {
    this.viewPorts.push({
      width,
      height
    });
  }
  removeCurrent() {
    this.viewPorts.pop();
  }
  getRoot() {
    const [root] = this.viewPorts;
    if (!root) {
      return getDefault();
    }
    return root;
  }
  getCurrent() {
    const {viewPorts} = this;
    const current = viewPorts[viewPorts.length - 1];
    if (!current) {
      return getDefault();
    }
    return current;
  }
  get width() {
    return this.getCurrent().width;
  }
  get height() {
    return this.getCurrent().height;
  }
  computeSize(d) {
    if (typeof d === "number") {
      return d;
    }
    if (d === "x") {
      return this.width;
    }
    if (d === "y") {
      return this.height;
    }
    return Math.sqrt(Math.pow(this.width, 2) + Math.pow(this.height, 2)) / Math.sqrt(2);
  }
  constructor() {
    this.viewPorts = [];
  }
}
ViewPort.DEFAULT_VIEWPORT_WIDTH = 800;
ViewPort.DEFAULT_VIEWPORT_HEIGHT = 600;
function getDefault() {
  return {
    width: ViewPort.DEFAULT_VIEWPORT_WIDTH,
    height: ViewPort.DEFAULT_VIEWPORT_HEIGHT
  };
}
class Point {
  static parse(point) {
    let defaultValue = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 0;
    const [x = defaultValue, y = defaultValue] = toNumbers(point);
    return new Point(x, y);
  }
  static parseScale(scale) {
    let defaultValue = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 1;
    const [x = defaultValue, y = x] = toNumbers(scale);
    return new Point(x, y);
  }
  static parsePath(path) {
    const points = toNumbers(path);
    const len = points.length;
    const pathPoints = [];
    for (let i = 0; i < len; i += 2) {
      pathPoints.push(new Point(points[i], points[i + 1]));
    }
    return pathPoints;
  }
  angleTo(point) {
    return Math.atan2(point.y - this.y, point.x - this.x);
  }
  applyTransform(transform) {
    const {x, y} = this;
    const xp = x * transform[0] + y * transform[2] + transform[4];
    const yp = x * transform[1] + y * transform[3] + transform[5];
    this.x = xp;
    this.y = yp;
  }
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }
}
class Mouse {
  isWorking() {
    return this.working;
  }
  start() {
    if (this.working) {
      return;
    }
    const {screen, onClick, onMouseMove} = this;
    const canvas = screen.ctx.canvas;
    canvas.onclick = onClick;
    canvas.onmousemove = onMouseMove;
    this.working = true;
  }
  stop() {
    if (!this.working) {
      return;
    }
    const canvas = this.screen.ctx.canvas;
    this.working = false;
    canvas.onclick = null;
    canvas.onmousemove = null;
  }
  hasEvents() {
    return this.working && this.events.length > 0;
  }
  runEvents() {
    if (!this.working) {
      return;
    }
    const {screen: document2, events, eventElements} = this;
    const {style} = document2.ctx.canvas;
    let element;
    if (style) {
      style.cursor = "";
    }
    events.forEach((param, i) => {
      let {run} = param;
      element = eventElements[i];
      while (element) {
        run(element);
        element = element.parent;
      }
    });
    this.events = [];
    this.eventElements = [];
  }
  checkPath(element, ctx) {
    if (!this.working || !ctx) {
      return;
    }
    const {events, eventElements} = this;
    events.forEach((param, i) => {
      let {x, y} = param;
      if (!eventElements[i] && ctx.isPointInPath && ctx.isPointInPath(x, y)) {
        eventElements[i] = element;
      }
    });
  }
  checkBoundingBox(element, boundingBox) {
    if (!this.working || !boundingBox) {
      return;
    }
    const {events, eventElements} = this;
    events.forEach((param, i) => {
      let {x, y} = param;
      if (!eventElements[i] && boundingBox.isPointInBox(x, y)) {
        eventElements[i] = element;
      }
    });
  }
  mapXY(x, y) {
    const {window: window2, ctx} = this.screen;
    const point = new Point(x, y);
    let element = ctx.canvas;
    while (element) {
      point.x -= element.offsetLeft;
      point.y -= element.offsetTop;
      element = element.offsetParent;
    }
    if (window2 === null || window2 === void 0 ? void 0 : window2.scrollX) {
      point.x += window2.scrollX;
    }
    if (window2 === null || window2 === void 0 ? void 0 : window2.scrollY) {
      point.y += window2.scrollY;
    }
    return point;
  }
  onClick(event) {
    const {x, y} = this.mapXY(event.clientX, event.clientY);
    this.events.push({
      type: "onclick",
      x,
      y,
      run(eventTarget) {
        if (eventTarget.onClick) {
          eventTarget.onClick();
        }
      }
    });
  }
  onMouseMove(event) {
    const {x, y} = this.mapXY(event.clientX, event.clientY);
    this.events.push({
      type: "onmousemove",
      x,
      y,
      run(eventTarget) {
        if (eventTarget.onMouseMove) {
          eventTarget.onMouseMove();
        }
      }
    });
  }
  constructor(screen) {
    this.screen = screen;
    this.working = false;
    this.events = [];
    this.eventElements = [];
    this.onClick = this.onClick.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
  }
}
const defaultWindow = typeof window !== "undefined" ? window : null;
const defaultFetch$1 = typeof fetch !== "undefined" ? fetch.bind(void 0) : void 0;
class Screen {
  wait(checker) {
    this.waits.push(checker);
  }
  ready() {
    if (!this.readyPromise) {
      return Promise.resolve();
    }
    return this.readyPromise;
  }
  isReady() {
    if (this.isReadyLock) {
      return true;
    }
    const isReadyLock = this.waits.every((_) => _());
    if (isReadyLock) {
      this.waits = [];
      if (this.resolveReady) {
        this.resolveReady();
      }
    }
    this.isReadyLock = isReadyLock;
    return isReadyLock;
  }
  setDefaults(ctx) {
    ctx.strokeStyle = "rgba(0,0,0,0)";
    ctx.lineCap = "butt";
    ctx.lineJoin = "miter";
    ctx.miterLimit = 4;
  }
  setViewBox(param) {
    let {document: document2, ctx, aspectRatio, width, desiredWidth, height, desiredHeight, minX = 0, minY = 0, refX, refY, clip = false, clipX = 0, clipY = 0} = param;
    const cleanAspectRatio = compressSpaces(aspectRatio).replace(/^defer\s/, "");
    const [aspectRatioAlign, aspectRatioMeetOrSlice] = cleanAspectRatio.split(" ");
    const align = aspectRatioAlign || "xMidYMid";
    const meetOrSlice = aspectRatioMeetOrSlice || "meet";
    const scaleX = width / desiredWidth;
    const scaleY = height / desiredHeight;
    const scaleMin = Math.min(scaleX, scaleY);
    const scaleMax = Math.max(scaleX, scaleY);
    let finalDesiredWidth = desiredWidth;
    let finalDesiredHeight = desiredHeight;
    if (meetOrSlice === "meet") {
      finalDesiredWidth *= scaleMin;
      finalDesiredHeight *= scaleMin;
    }
    if (meetOrSlice === "slice") {
      finalDesiredWidth *= scaleMax;
      finalDesiredHeight *= scaleMax;
    }
    const refXProp = new Property(document2, "refX", refX);
    const refYProp = new Property(document2, "refY", refY);
    const hasRefs = refXProp.hasValue() && refYProp.hasValue();
    if (hasRefs) {
      ctx.translate(-scaleMin * refXProp.getPixels("x"), -scaleMin * refYProp.getPixels("y"));
    }
    if (clip) {
      const scaledClipX = scaleMin * clipX;
      const scaledClipY = scaleMin * clipY;
      ctx.beginPath();
      ctx.moveTo(scaledClipX, scaledClipY);
      ctx.lineTo(width, scaledClipY);
      ctx.lineTo(width, height);
      ctx.lineTo(scaledClipX, height);
      ctx.closePath();
      ctx.clip();
    }
    if (!hasRefs) {
      const isMeetMinY = meetOrSlice === "meet" && scaleMin === scaleY;
      const isSliceMaxY = meetOrSlice === "slice" && scaleMax === scaleY;
      const isMeetMinX = meetOrSlice === "meet" && scaleMin === scaleX;
      const isSliceMaxX = meetOrSlice === "slice" && scaleMax === scaleX;
      if (align.startsWith("xMid") && (isMeetMinY || isSliceMaxY)) {
        ctx.translate(width / 2 - finalDesiredWidth / 2, 0);
      }
      if (align.endsWith("YMid") && (isMeetMinX || isSliceMaxX)) {
        ctx.translate(0, height / 2 - finalDesiredHeight / 2);
      }
      if (align.startsWith("xMax") && (isMeetMinY || isSliceMaxY)) {
        ctx.translate(width - finalDesiredWidth, 0);
      }
      if (align.endsWith("YMax") && (isMeetMinX || isSliceMaxX)) {
        ctx.translate(0, height - finalDesiredHeight);
      }
    }
    switch (true) {
      case align === "none":
        ctx.scale(scaleX, scaleY);
        break;
      case meetOrSlice === "meet":
        ctx.scale(scaleMin, scaleMin);
        break;
      case meetOrSlice === "slice":
        ctx.scale(scaleMax, scaleMax);
        break;
    }
    ctx.translate(-minX, -minY);
  }
  start(element) {
    let {enableRedraw = false, ignoreMouse = false, ignoreAnimation = false, ignoreDimensions = false, ignoreClear = false, forceRedraw, scaleWidth, scaleHeight, offsetX, offsetY} = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : {};
    const {mouse} = this;
    const frameDuration = 1e3 / Screen.FRAMERATE;
    this.isReadyLock = false;
    this.frameDuration = frameDuration;
    this.readyPromise = new Promise((resolve) => {
      this.resolveReady = resolve;
    });
    if (this.isReady()) {
      this.render(element, ignoreDimensions, ignoreClear, scaleWidth, scaleHeight, offsetX, offsetY);
    }
    if (!enableRedraw) {
      return;
    }
    let now = Date.now();
    let then = now;
    let delta = 0;
    const tick = () => {
      now = Date.now();
      delta = now - then;
      if (delta >= frameDuration) {
        then = now - delta % frameDuration;
        if (this.shouldUpdate(ignoreAnimation, forceRedraw)) {
          this.render(element, ignoreDimensions, ignoreClear, scaleWidth, scaleHeight, offsetX, offsetY);
          mouse.runEvents();
        }
      }
      this.intervalId = requestAnimationFrame(tick);
    };
    if (!ignoreMouse) {
      mouse.start();
    }
    this.intervalId = requestAnimationFrame(tick);
  }
  stop() {
    if (this.intervalId) {
      requestAnimationFrame.cancel(this.intervalId);
      this.intervalId = null;
    }
    this.mouse.stop();
  }
  shouldUpdate(ignoreAnimation, forceRedraw) {
    if (!ignoreAnimation) {
      const {frameDuration} = this;
      const shouldUpdate1 = this.animations.reduce((shouldUpdate, animation) => animation.update(frameDuration) || shouldUpdate, false);
      if (shouldUpdate1) {
        return true;
      }
    }
    if (typeof forceRedraw === "function" && forceRedraw()) {
      return true;
    }
    if (!this.isReadyLock && this.isReady()) {
      return true;
    }
    if (this.mouse.hasEvents()) {
      return true;
    }
    return false;
  }
  render(element, ignoreDimensions, ignoreClear, scaleWidth, scaleHeight, offsetX, offsetY) {
    const {viewPort, ctx, isFirstRender} = this;
    const canvas = ctx.canvas;
    viewPort.clear();
    if (canvas.width && canvas.height) {
      viewPort.setCurrent(canvas.width, canvas.height);
    }
    const widthStyle = element.getStyle("width");
    const heightStyle = element.getStyle("height");
    if (!ignoreDimensions && (isFirstRender || typeof scaleWidth !== "number" && typeof scaleHeight !== "number")) {
      if (widthStyle.hasValue()) {
        canvas.width = widthStyle.getPixels("x");
        if (canvas.style) {
          canvas.style.width = "".concat(canvas.width, "px");
        }
      }
      if (heightStyle.hasValue()) {
        canvas.height = heightStyle.getPixels("y");
        if (canvas.style) {
          canvas.style.height = "".concat(canvas.height, "px");
        }
      }
    }
    let cWidth = canvas.clientWidth || canvas.width;
    let cHeight = canvas.clientHeight || canvas.height;
    if (ignoreDimensions && widthStyle.hasValue() && heightStyle.hasValue()) {
      cWidth = widthStyle.getPixels("x");
      cHeight = heightStyle.getPixels("y");
    }
    viewPort.setCurrent(cWidth, cHeight);
    if (typeof offsetX === "number") {
      element.getAttribute("x", true).setValue(offsetX);
    }
    if (typeof offsetY === "number") {
      element.getAttribute("y", true).setValue(offsetY);
    }
    if (typeof scaleWidth === "number" || typeof scaleHeight === "number") {
      const viewBox = toNumbers(element.getAttribute("viewBox").getString());
      let xRatio = 0;
      let yRatio = 0;
      if (typeof scaleWidth === "number") {
        const widthStyle2 = element.getStyle("width");
        if (widthStyle2.hasValue()) {
          xRatio = widthStyle2.getPixels("x") / scaleWidth;
        } else if (viewBox[2] && !isNaN(viewBox[2])) {
          xRatio = viewBox[2] / scaleWidth;
        }
      }
      if (typeof scaleHeight === "number") {
        const heightStyle2 = element.getStyle("height");
        if (heightStyle2.hasValue()) {
          yRatio = heightStyle2.getPixels("y") / scaleHeight;
        } else if (viewBox[3] && !isNaN(viewBox[3])) {
          yRatio = viewBox[3] / scaleHeight;
        }
      }
      if (!xRatio) {
        xRatio = yRatio;
      }
      if (!yRatio) {
        yRatio = xRatio;
      }
      element.getAttribute("width", true).setValue(scaleWidth);
      element.getAttribute("height", true).setValue(scaleHeight);
      const transformStyle = element.getStyle("transform", true, true);
      transformStyle.setValue("".concat(transformStyle.getString(), " scale(").concat(1 / xRatio, ", ").concat(1 / yRatio, ")"));
    }
    if (!ignoreClear) {
      ctx.clearRect(0, 0, cWidth, cHeight);
    }
    element.render(ctx);
    if (isFirstRender) {
      this.isFirstRender = false;
    }
  }
  constructor(ctx, {fetch: fetch2 = defaultFetch$1, window: window2 = defaultWindow} = {}) {
    this.ctx = ctx;
    this.viewPort = new ViewPort();
    this.mouse = new Mouse(this);
    this.animations = [];
    this.waits = [];
    this.frameDuration = 0;
    this.isReadyLock = false;
    this.isFirstRender = true;
    this.intervalId = null;
    this.window = window2;
    if (!fetch2) {
      throw new Error("Can't find 'fetch' in 'globalThis', please provide it via options");
    }
    this.fetch = fetch2;
  }
}
Screen.defaultWindow = defaultWindow;
Screen.defaultFetch = defaultFetch$1;
Screen.FRAMERATE = 30;
Screen.MAX_VIRTUAL_PIXELS = 3e4;
const {defaultFetch} = Screen;
const DefaultDOMParser = typeof DOMParser !== "undefined" ? DOMParser : void 0;
class Parser {
  async parse(resource) {
    if (resource.startsWith("<")) {
      return this.parseFromString(resource);
    }
    return this.load(resource);
  }
  parseFromString(xml) {
    const parser = new this.DOMParser();
    try {
      return this.checkDocument(parser.parseFromString(xml, "image/svg+xml"));
    } catch (err) {
      return this.checkDocument(parser.parseFromString(xml, "text/xml"));
    }
  }
  checkDocument(document2) {
    const parserError = document2.getElementsByTagName("parsererror")[0];
    if (parserError) {
      throw new Error(parserError.textContent || "Unknown parse error");
    }
    return document2;
  }
  async load(url) {
    const response = await this.fetch(url);
    const xml = await response.text();
    return this.parseFromString(xml);
  }
  constructor({fetch: fetch2 = defaultFetch, DOMParser: DOMParser2 = DefaultDOMParser} = {}) {
    if (!fetch2) {
      throw new Error("Can't find 'fetch' in 'globalThis', please provide it via options");
    }
    if (!DOMParser2) {
      throw new Error("Can't find 'DOMParser' in 'globalThis', please provide it via options");
    }
    this.fetch = fetch2;
    this.DOMParser = DOMParser2;
  }
}
class Translate {
  apply(ctx) {
    const {x, y} = this.point;
    ctx.translate(x || 0, y || 0);
  }
  unapply(ctx) {
    const {x, y} = this.point;
    ctx.translate(-1 * x || 0, -1 * y || 0);
  }
  applyToPoint(point) {
    const {x, y} = this.point;
    point.applyTransform([
      1,
      0,
      0,
      1,
      x || 0,
      y || 0
    ]);
  }
  constructor(_, point) {
    this.type = "translate";
    this.point = Point.parse(point);
  }
}
class Rotate {
  apply(ctx) {
    const {cx, cy, originX, originY, angle} = this;
    const tx = cx + originX.getPixels("x");
    const ty = cy + originY.getPixels("y");
    ctx.translate(tx, ty);
    ctx.rotate(angle.getRadians());
    ctx.translate(-tx, -ty);
  }
  unapply(ctx) {
    const {cx, cy, originX, originY, angle} = this;
    const tx = cx + originX.getPixels("x");
    const ty = cy + originY.getPixels("y");
    ctx.translate(tx, ty);
    ctx.rotate(-1 * angle.getRadians());
    ctx.translate(-tx, -ty);
  }
  applyToPoint(point) {
    const {cx, cy, angle} = this;
    const rad = angle.getRadians();
    point.applyTransform([
      1,
      0,
      0,
      1,
      cx || 0,
      cy || 0
    ]);
    point.applyTransform([
      Math.cos(rad),
      Math.sin(rad),
      -Math.sin(rad),
      Math.cos(rad),
      0,
      0
    ]);
    point.applyTransform([
      1,
      0,
      0,
      1,
      -cx || 0,
      -cy || 0
    ]);
  }
  constructor(document2, rotate, transformOrigin) {
    this.type = "rotate";
    const numbers = toNumbers(rotate);
    this.angle = new Property(document2, "angle", numbers[0]);
    this.originX = transformOrigin[0];
    this.originY = transformOrigin[1];
    this.cx = numbers[1] || 0;
    this.cy = numbers[2] || 0;
  }
}
class Scale {
  apply(ctx) {
    const {scale: {x, y}, originX, originY} = this;
    const tx = originX.getPixels("x");
    const ty = originY.getPixels("y");
    ctx.translate(tx, ty);
    ctx.scale(x, y || x);
    ctx.translate(-tx, -ty);
  }
  unapply(ctx) {
    const {scale: {x, y}, originX, originY} = this;
    const tx = originX.getPixels("x");
    const ty = originY.getPixels("y");
    ctx.translate(tx, ty);
    ctx.scale(1 / x, 1 / y || x);
    ctx.translate(-tx, -ty);
  }
  applyToPoint(point) {
    const {x, y} = this.scale;
    point.applyTransform([
      x || 0,
      0,
      0,
      y || 0,
      0,
      0
    ]);
  }
  constructor(_, scale, transformOrigin) {
    this.type = "scale";
    const scaleSize = Point.parseScale(scale);
    if (scaleSize.x === 0 || scaleSize.y === 0) {
      scaleSize.x = PSEUDO_ZERO;
      scaleSize.y = PSEUDO_ZERO;
    }
    this.scale = scaleSize;
    this.originX = transformOrigin[0];
    this.originY = transformOrigin[1];
  }
}
class Matrix {
  apply(ctx) {
    const {originX, originY, matrix} = this;
    const tx = originX.getPixels("x");
    const ty = originY.getPixels("y");
    ctx.translate(tx, ty);
    ctx.transform(matrix[0], matrix[1], matrix[2], matrix[3], matrix[4], matrix[5]);
    ctx.translate(-tx, -ty);
  }
  unapply(ctx) {
    const {originX, originY, matrix} = this;
    const a = matrix[0];
    const b = matrix[2];
    const c2 = matrix[4];
    const d = matrix[1];
    const e = matrix[3];
    const f = matrix[5];
    const g = 0;
    const h = 0;
    const i = 1;
    const det = 1 / (a * (e * i - f * h) - b * (d * i - f * g) + c2 * (d * h - e * g));
    const tx = originX.getPixels("x");
    const ty = originY.getPixels("y");
    ctx.translate(tx, ty);
    ctx.transform(det * (e * i - f * h), det * (f * g - d * i), det * (c2 * h - b * i), det * (a * i - c2 * g), det * (b * f - c2 * e), det * (c2 * d - a * f));
    ctx.translate(-tx, -ty);
  }
  applyToPoint(point) {
    point.applyTransform(this.matrix);
  }
  constructor(_, matrix, transformOrigin) {
    this.type = "matrix";
    this.matrix = toMatrixValue(matrix);
    this.originX = transformOrigin[0];
    this.originY = transformOrigin[1];
  }
}
class Skew extends Matrix {
  constructor(document2, skew, transformOrigin) {
    super(document2, skew, transformOrigin);
    this.type = "skew";
    this.angle = new Property(document2, "angle", skew);
  }
}
class SkewX extends Skew {
  constructor(document2, skew, transformOrigin) {
    super(document2, skew, transformOrigin);
    this.type = "skewX";
    this.matrix = [
      1,
      0,
      Math.tan(this.angle.getRadians()),
      1,
      0,
      0
    ];
  }
}
class SkewY extends Skew {
  constructor(document2, skew, transformOrigin) {
    super(document2, skew, transformOrigin);
    this.type = "skewY";
    this.matrix = [
      1,
      Math.tan(this.angle.getRadians()),
      0,
      1,
      0,
      0
    ];
  }
}
function parseTransforms(transform) {
  return compressSpaces(transform).trim().replace(/\)([a-zA-Z])/g, ") $1").replace(/\)(\s?,\s?)/g, ") ").split(/\s(?=[a-z])/);
}
function parseTransform(transform) {
  const [type = "", value = ""] = transform.split("(");
  return [
    type.trim(),
    value.trim().replace(")", "")
  ];
}
class Transform {
  static fromElement(document2, element) {
    const transformStyle = element.getStyle("transform", false, true);
    if (transformStyle.hasValue()) {
      const [transformOriginXProperty, transformOriginYProperty = transformOriginXProperty] = element.getStyle("transform-origin", false, true).split();
      if (transformOriginXProperty && transformOriginYProperty) {
        const transformOrigin = [
          transformOriginXProperty,
          transformOriginYProperty
        ];
        return new Transform(document2, transformStyle.getString(), transformOrigin);
      }
    }
    return null;
  }
  apply(ctx) {
    this.transforms.forEach((transform) => transform.apply(ctx));
  }
  unapply(ctx) {
    this.transforms.forEach((transform) => transform.unapply(ctx));
  }
  applyToPoint(point) {
    this.transforms.forEach((transform) => transform.applyToPoint(point));
  }
  constructor(document2, transform1, transformOrigin) {
    this.document = document2;
    this.transforms = [];
    const data = parseTransforms(transform1);
    data.forEach((transform) => {
      if (transform === "none") {
        return;
      }
      const [type, value] = parseTransform(transform);
      const TransformType = Transform.transformTypes[type];
      if (TransformType) {
        this.transforms.push(new TransformType(this.document, value, transformOrigin));
      }
    });
  }
}
Transform.transformTypes = {
  translate: Translate,
  rotate: Rotate,
  scale: Scale,
  matrix: Matrix,
  skewX: SkewX,
  skewY: SkewY
};
class Element {
  getAttribute(name) {
    let createIfNotExists = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    const attr = this.attributes[name];
    if (!attr && createIfNotExists) {
      const attr2 = new Property(this.document, name, "");
      this.attributes[name] = attr2;
      return attr2;
    }
    return attr || Property.empty(this.document);
  }
  getHrefAttribute() {
    let href;
    for (const key in this.attributes) {
      if (key === "href" || key.endsWith(":href")) {
        href = this.attributes[key];
        break;
      }
    }
    return href || Property.empty(this.document);
  }
  getStyle(name) {
    let createIfNotExists = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false, skipAncestors = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
    const style = this.styles[name];
    if (style) {
      return style;
    }
    const attr = this.getAttribute(name);
    if (attr.hasValue()) {
      this.styles[name] = attr;
      return attr;
    }
    if (!skipAncestors) {
      const {parent} = this;
      if (parent) {
        const parentStyle = parent.getStyle(name);
        if (parentStyle.hasValue()) {
          return parentStyle;
        }
      }
    }
    if (createIfNotExists) {
      const style2 = new Property(this.document, name, "");
      this.styles[name] = style2;
      return style2;
    }
    return Property.empty(this.document);
  }
  render(ctx) {
    if (this.getStyle("display").getString() === "none" || this.getStyle("visibility").getString() === "hidden") {
      return;
    }
    ctx.save();
    if (this.getStyle("mask").hasValue()) {
      const mask = this.getStyle("mask").getDefinition();
      if (mask) {
        this.applyEffects(ctx);
        mask.apply(ctx, this);
      }
    } else if (this.getStyle("filter").getValue("none") !== "none") {
      const filter = this.getStyle("filter").getDefinition();
      if (filter) {
        this.applyEffects(ctx);
        filter.apply(ctx, this);
      }
    } else {
      this.setContext(ctx);
      this.renderChildren(ctx);
      this.clearContext(ctx);
    }
    ctx.restore();
  }
  setContext(_) {
  }
  applyEffects(ctx) {
    const transform = Transform.fromElement(this.document, this);
    if (transform) {
      transform.apply(ctx);
    }
    const clipPathStyleProp = this.getStyle("clip-path", false, true);
    if (clipPathStyleProp.hasValue()) {
      const clip = clipPathStyleProp.getDefinition();
      if (clip) {
        clip.apply(ctx);
      }
    }
  }
  clearContext(_) {
  }
  renderChildren(ctx) {
    this.children.forEach((child) => {
      child.render(ctx);
    });
  }
  addChild(childNode) {
    const child = childNode instanceof Element ? childNode : this.document.createElement(childNode);
    child.parent = this;
    if (!Element.ignoreChildTypes.includes(child.type)) {
      this.children.push(child);
    }
  }
  matchesSelector(selector) {
    var ref;
    const {node: node2} = this;
    if (typeof node2.matches === "function") {
      return node2.matches(selector);
    }
    const styleClasses = (ref = node2.getAttribute) === null || ref === void 0 ? void 0 : ref.call(node2, "class");
    if (!styleClasses || styleClasses === "") {
      return false;
    }
    return styleClasses.split(" ").some((styleClass) => ".".concat(styleClass) === selector);
  }
  addStylesFromStyleDefinition() {
    const {styles, stylesSpecificity} = this.document;
    let styleProp;
    for (const selector in styles) {
      if (!selector.startsWith("@") && this.matchesSelector(selector)) {
        const style = styles[selector];
        const specificity = stylesSpecificity[selector];
        if (style) {
          for (const name in style) {
            let existingSpecificity = this.stylesSpecificity[name];
            if (typeof existingSpecificity === "undefined") {
              existingSpecificity = "000";
            }
            if (specificity && specificity >= existingSpecificity) {
              styleProp = style[name];
              if (styleProp) {
                this.styles[name] = styleProp;
              }
              this.stylesSpecificity[name] = specificity;
            }
          }
        }
      }
    }
  }
  removeStyles(element, ignoreStyles) {
    const toRestore1 = ignoreStyles.reduce((toRestore, name) => {
      const styleProp = element.getStyle(name);
      if (!styleProp.hasValue()) {
        return toRestore;
      }
      const value = styleProp.getString();
      styleProp.setValue("");
      return [
        ...toRestore,
        [
          name,
          value
        ]
      ];
    }, []);
    return toRestore1;
  }
  restoreStyles(element, styles) {
    styles.forEach((param) => {
      let [name, value] = param;
      element.getStyle(name, true).setValue(value);
    });
  }
  isFirstChild() {
    var ref;
    return ((ref = this.parent) === null || ref === void 0 ? void 0 : ref.children.indexOf(this)) === 0;
  }
  constructor(document2, node2, captureTextNodes = false) {
    this.document = document2;
    this.node = node2;
    this.captureTextNodes = captureTextNodes;
    this.type = "";
    this.attributes = {};
    this.styles = {};
    this.stylesSpecificity = {};
    this.animationFrozen = false;
    this.animationFrozenValue = "";
    this.parent = null;
    this.children = [];
    if (!node2 || node2.nodeType !== 1) {
      return;
    }
    Array.from(node2.attributes).forEach((attribute) => {
      const nodeName = normalizeAttributeName(attribute.nodeName);
      this.attributes[nodeName] = new Property(document2, nodeName, attribute.value);
    });
    this.addStylesFromStyleDefinition();
    if (this.getAttribute("style").hasValue()) {
      const styles = this.getAttribute("style").getString().split(";").map((_) => _.trim());
      styles.forEach((style) => {
        if (!style) {
          return;
        }
        const [name, value] = style.split(":").map((_) => _.trim());
        if (name) {
          this.styles[name] = new Property(document2, name, value);
        }
      });
    }
    const {definitions} = document2;
    const id = this.getAttribute("id");
    if (id.hasValue()) {
      if (!definitions[id.getString()]) {
        definitions[id.getString()] = this;
      }
    }
    Array.from(node2.childNodes).forEach((childNode) => {
      if (childNode.nodeType === 1) {
        this.addChild(childNode);
      } else if (captureTextNodes && (childNode.nodeType === 3 || childNode.nodeType === 4)) {
        const textNode = document2.createTextNode(childNode);
        if (textNode.getText().length > 0) {
          this.addChild(textNode);
        }
      }
    });
  }
}
Element.ignoreChildTypes = [
  "title"
];
class UnknownElement extends Element {
  constructor(document2, node2, captureTextNodes) {
    super(document2, node2, captureTextNodes);
  }
}
function wrapFontFamily(fontFamily) {
  const trimmed = fontFamily.trim();
  return /^('|")/.test(trimmed) ? trimmed : '"'.concat(trimmed, '"');
}
function prepareFontFamily(fontFamily) {
  return typeof process === "undefined" ? fontFamily : fontFamily.trim().split(",").map(wrapFontFamily).join(",");
}
function prepareFontStyle(fontStyle) {
  if (!fontStyle) {
    return "";
  }
  const targetFontStyle = fontStyle.trim().toLowerCase();
  switch (targetFontStyle) {
    case "normal":
    case "italic":
    case "oblique":
    case "inherit":
    case "initial":
    case "unset":
      return targetFontStyle;
    default:
      if (/^oblique\s+(-|)\d+deg$/.test(targetFontStyle)) {
        return targetFontStyle;
      }
      return "";
  }
}
function prepareFontWeight(fontWeight) {
  if (!fontWeight) {
    return "";
  }
  const targetFontWeight = fontWeight.trim().toLowerCase();
  switch (targetFontWeight) {
    case "normal":
    case "bold":
    case "lighter":
    case "bolder":
    case "inherit":
    case "initial":
    case "unset":
      return targetFontWeight;
    default:
      if (/^[\d.]+$/.test(targetFontWeight)) {
        return targetFontWeight;
      }
      return "";
  }
}
class Font {
  static parse() {
    let font = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "", inherit = arguments.length > 1 ? arguments[1] : void 0;
    let fontStyle = "";
    let fontVariant = "";
    let fontWeight = "";
    let fontSize = "";
    let fontFamily = "";
    const parts = compressSpaces(font).trim().split(" ");
    const set = {
      fontSize: false,
      fontStyle: false,
      fontWeight: false,
      fontVariant: false
    };
    parts.forEach((part) => {
      switch (true) {
        case (!set.fontStyle && Font.styles.includes(part)):
          if (part !== "inherit") {
            fontStyle = part;
          }
          set.fontStyle = true;
          break;
        case (!set.fontVariant && Font.variants.includes(part)):
          if (part !== "inherit") {
            fontVariant = part;
          }
          set.fontStyle = true;
          set.fontVariant = true;
          break;
        case (!set.fontWeight && Font.weights.includes(part)):
          if (part !== "inherit") {
            fontWeight = part;
          }
          set.fontStyle = true;
          set.fontVariant = true;
          set.fontWeight = true;
          break;
        case !set.fontSize:
          if (part !== "inherit") {
            fontSize = part.split("/")[0] || "";
          }
          set.fontStyle = true;
          set.fontVariant = true;
          set.fontWeight = true;
          set.fontSize = true;
          break;
        default:
          if (part !== "inherit") {
            fontFamily += part;
          }
      }
    });
    return new Font(fontStyle, fontVariant, fontWeight, fontSize, fontFamily, inherit);
  }
  toString() {
    return [
      prepareFontStyle(this.fontStyle),
      this.fontVariant,
      prepareFontWeight(this.fontWeight),
      this.fontSize,
      prepareFontFamily(this.fontFamily)
    ].join(" ").trim();
  }
  constructor(fontStyle, fontVariant, fontWeight, fontSize, fontFamily, inherit) {
    const inheritFont = inherit ? typeof inherit === "string" ? Font.parse(inherit) : inherit : {};
    this.fontFamily = fontFamily || inheritFont.fontFamily;
    this.fontSize = fontSize || inheritFont.fontSize;
    this.fontStyle = fontStyle || inheritFont.fontStyle;
    this.fontWeight = fontWeight || inheritFont.fontWeight;
    this.fontVariant = fontVariant || inheritFont.fontVariant;
  }
}
Font.styles = "normal|italic|oblique|inherit";
Font.variants = "normal|small-caps|inherit";
Font.weights = "normal|bold|bolder|lighter|100|200|300|400|500|600|700|800|900|inherit";
class BoundingBox {
  get x() {
    return this.x1;
  }
  get y() {
    return this.y1;
  }
  get width() {
    return this.x2 - this.x1;
  }
  get height() {
    return this.y2 - this.y1;
  }
  addPoint(x, y) {
    if (typeof x !== "undefined") {
      if (isNaN(this.x1) || isNaN(this.x2)) {
        this.x1 = x;
        this.x2 = x;
      }
      if (x < this.x1) {
        this.x1 = x;
      }
      if (x > this.x2) {
        this.x2 = x;
      }
    }
    if (typeof y !== "undefined") {
      if (isNaN(this.y1) || isNaN(this.y2)) {
        this.y1 = y;
        this.y2 = y;
      }
      if (y < this.y1) {
        this.y1 = y;
      }
      if (y > this.y2) {
        this.y2 = y;
      }
    }
  }
  addX(x) {
    this.addPoint(x, 0);
  }
  addY(y) {
    this.addPoint(0, y);
  }
  addBoundingBox(boundingBox) {
    if (!boundingBox) {
      return;
    }
    const {x1, y1, x2, y2} = boundingBox;
    this.addPoint(x1, y1);
    this.addPoint(x2, y2);
  }
  sumCubic(t, p0, p1, p2, p3) {
    return Math.pow(1 - t, 3) * p0 + 3 * Math.pow(1 - t, 2) * t * p1 + 3 * (1 - t) * Math.pow(t, 2) * p2 + Math.pow(t, 3) * p3;
  }
  bezierCurveAdd(forX, p0, p1, p2, p3) {
    const b = 6 * p0 - 12 * p1 + 6 * p2;
    const a = -3 * p0 + 9 * p1 - 9 * p2 + 3 * p3;
    const c2 = 3 * p1 - 3 * p0;
    if (a === 0) {
      if (b === 0) {
        return;
      }
      const t = -c2 / b;
      if (0 < t && t < 1) {
        if (forX) {
          this.addX(this.sumCubic(t, p0, p1, p2, p3));
        } else {
          this.addY(this.sumCubic(t, p0, p1, p2, p3));
        }
      }
      return;
    }
    const b2ac = Math.pow(b, 2) - 4 * c2 * a;
    if (b2ac < 0) {
      return;
    }
    const t1 = (-b + Math.sqrt(b2ac)) / (2 * a);
    if (0 < t1 && t1 < 1) {
      if (forX) {
        this.addX(this.sumCubic(t1, p0, p1, p2, p3));
      } else {
        this.addY(this.sumCubic(t1, p0, p1, p2, p3));
      }
    }
    const t2 = (-b - Math.sqrt(b2ac)) / (2 * a);
    if (0 < t2 && t2 < 1) {
      if (forX) {
        this.addX(this.sumCubic(t2, p0, p1, p2, p3));
      } else {
        this.addY(this.sumCubic(t2, p0, p1, p2, p3));
      }
    }
  }
  addBezierCurve(p0x, p0y, p1x, p1y, p2x, p2y, p3x, p3y) {
    this.addPoint(p0x, p0y);
    this.addPoint(p3x, p3y);
    this.bezierCurveAdd(true, p0x, p1x, p2x, p3x);
    this.bezierCurveAdd(false, p0y, p1y, p2y, p3y);
  }
  addQuadraticCurve(p0x, p0y, p1x, p1y, p2x, p2y) {
    const cp1x = p0x + 2 / 3 * (p1x - p0x);
    const cp1y = p0y + 2 / 3 * (p1y - p0y);
    const cp2x = cp1x + 1 / 3 * (p2x - p0x);
    const cp2y = cp1y + 1 / 3 * (p2y - p0y);
    this.addBezierCurve(p0x, p0y, cp1x, cp2x, cp1y, cp2y, p2x, p2y);
  }
  isPointInBox(x, y) {
    const {x1, y1, x2, y2} = this;
    return x1 <= x && x <= x2 && y1 <= y && y <= y2;
  }
  constructor(x1 = Number.NaN, y1 = Number.NaN, x2 = Number.NaN, y2 = Number.NaN) {
    this.x1 = x1;
    this.y1 = y1;
    this.x2 = x2;
    this.y2 = y2;
    this.addPoint(x1, y1);
    this.addPoint(x2, y2);
  }
}
class RenderedElement extends Element {
  calculateOpacity() {
    let opacity = 1;
    let element = this;
    while (element) {
      const opacityStyle = element.getStyle("opacity", false, true);
      if (opacityStyle.hasValue(true)) {
        opacity *= opacityStyle.getNumber();
      }
      element = element.parent;
    }
    return opacity;
  }
  setContext(ctx) {
    let fromMeasure = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    if (!fromMeasure) {
      const fillStyleProp = this.getStyle("fill");
      const fillOpacityStyleProp = this.getStyle("fill-opacity");
      const strokeStyleProp = this.getStyle("stroke");
      const strokeOpacityProp = this.getStyle("stroke-opacity");
      if (fillStyleProp.isUrlDefinition()) {
        const fillStyle = fillStyleProp.getFillStyleDefinition(this, fillOpacityStyleProp);
        if (fillStyle) {
          ctx.fillStyle = fillStyle;
        }
      } else if (fillStyleProp.hasValue()) {
        if (fillStyleProp.getString() === "currentColor") {
          fillStyleProp.setValue(this.getStyle("color").getColor());
        }
        const fillStyle = fillStyleProp.getColor();
        if (fillStyle !== "inherit") {
          ctx.fillStyle = fillStyle === "none" ? "rgba(0,0,0,0)" : fillStyle;
        }
      }
      if (fillOpacityStyleProp.hasValue()) {
        const fillStyle = new Property(this.document, "fill", ctx.fillStyle).addOpacity(fillOpacityStyleProp).getColor();
        ctx.fillStyle = fillStyle;
      }
      if (strokeStyleProp.isUrlDefinition()) {
        const strokeStyle = strokeStyleProp.getFillStyleDefinition(this, strokeOpacityProp);
        if (strokeStyle) {
          ctx.strokeStyle = strokeStyle;
        }
      } else if (strokeStyleProp.hasValue()) {
        if (strokeStyleProp.getString() === "currentColor") {
          strokeStyleProp.setValue(this.getStyle("color").getColor());
        }
        const strokeStyle = strokeStyleProp.getString();
        if (strokeStyle !== "inherit") {
          ctx.strokeStyle = strokeStyle === "none" ? "rgba(0,0,0,0)" : strokeStyle;
        }
      }
      if (strokeOpacityProp.hasValue()) {
        const strokeStyle = new Property(this.document, "stroke", ctx.strokeStyle).addOpacity(strokeOpacityProp).getString();
        ctx.strokeStyle = strokeStyle;
      }
      const strokeWidthStyleProp = this.getStyle("stroke-width");
      if (strokeWidthStyleProp.hasValue()) {
        const newLineWidth = strokeWidthStyleProp.getPixels();
        ctx.lineWidth = !newLineWidth ? PSEUDO_ZERO : newLineWidth;
      }
      const strokeLinecapStyleProp = this.getStyle("stroke-linecap");
      const strokeLinejoinStyleProp = this.getStyle("stroke-linejoin");
      const strokeMiterlimitProp = this.getStyle("stroke-miterlimit");
      const strokeDasharrayStyleProp = this.getStyle("stroke-dasharray");
      const strokeDashoffsetProp = this.getStyle("stroke-dashoffset");
      if (strokeLinecapStyleProp.hasValue()) {
        ctx.lineCap = strokeLinecapStyleProp.getString();
      }
      if (strokeLinejoinStyleProp.hasValue()) {
        ctx.lineJoin = strokeLinejoinStyleProp.getString();
      }
      if (strokeMiterlimitProp.hasValue()) {
        ctx.miterLimit = strokeMiterlimitProp.getNumber();
      }
      if (strokeDasharrayStyleProp.hasValue() && strokeDasharrayStyleProp.getString() !== "none") {
        const gaps = toNumbers(strokeDasharrayStyleProp.getString());
        if (typeof ctx.setLineDash !== "undefined") {
          ctx.setLineDash(gaps);
        } else if (typeof ctx.webkitLineDash !== "undefined") {
          ctx.webkitLineDash = gaps;
        } else if (typeof ctx.mozDash !== "undefined" && !(gaps.length === 1 && gaps[0] === 0)) {
          ctx.mozDash = gaps;
        }
        const offset = strokeDashoffsetProp.getPixels();
        if (typeof ctx.lineDashOffset !== "undefined") {
          ctx.lineDashOffset = offset;
        } else if (typeof ctx.webkitLineDashOffset !== "undefined") {
          ctx.webkitLineDashOffset = offset;
        } else if (typeof ctx.mozDashOffset !== "undefined") {
          ctx.mozDashOffset = offset;
        }
      }
    }
    this.modifiedEmSizeStack = false;
    if (typeof ctx.font !== "undefined") {
      const fontStyleProp = this.getStyle("font");
      const fontStyleStyleProp = this.getStyle("font-style");
      const fontVariantStyleProp = this.getStyle("font-variant");
      const fontWeightStyleProp = this.getStyle("font-weight");
      const fontSizeStyleProp = this.getStyle("font-size");
      const fontFamilyStyleProp = this.getStyle("font-family");
      const font = new Font(fontStyleStyleProp.getString(), fontVariantStyleProp.getString(), fontWeightStyleProp.getString(), fontSizeStyleProp.hasValue() ? "".concat(fontSizeStyleProp.getPixels(true), "px") : "", fontFamilyStyleProp.getString(), Font.parse(fontStyleProp.getString(), ctx.font));
      fontStyleStyleProp.setValue(font.fontStyle);
      fontVariantStyleProp.setValue(font.fontVariant);
      fontWeightStyleProp.setValue(font.fontWeight);
      fontSizeStyleProp.setValue(font.fontSize);
      fontFamilyStyleProp.setValue(font.fontFamily);
      ctx.font = font.toString();
      if (fontSizeStyleProp.isPixels()) {
        this.document.emSize = fontSizeStyleProp.getPixels();
        this.modifiedEmSizeStack = true;
      }
    }
    if (!fromMeasure) {
      this.applyEffects(ctx);
      ctx.globalAlpha = this.calculateOpacity();
    }
  }
  clearContext(ctx) {
    super.clearContext(ctx);
    if (this.modifiedEmSizeStack) {
      this.document.popEmSize();
    }
  }
  constructor(...args) {
    super(...args);
    this.modifiedEmSizeStack = false;
  }
}
class TextElement extends RenderedElement {
  setContext(ctx) {
    let fromMeasure = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
    super.setContext(ctx, fromMeasure);
    const textBaseline = this.getStyle("dominant-baseline").getTextBaseline() || this.getStyle("alignment-baseline").getTextBaseline();
    if (textBaseline) {
      ctx.textBaseline = textBaseline;
    }
  }
  initializeCoordinates() {
    this.x = 0;
    this.y = 0;
    this.leafTexts = [];
    this.textChunkStart = 0;
    this.minX = Number.POSITIVE_INFINITY;
    this.maxX = Number.NEGATIVE_INFINITY;
  }
  getBoundingBox(ctx) {
    if (this.type !== "text") {
      return this.getTElementBoundingBox(ctx);
    }
    this.initializeCoordinates();
    this.adjustChildCoordinatesRecursive(ctx);
    let boundingBox = null;
    this.children.forEach((_, i) => {
      const childBoundingBox = this.getChildBoundingBox(ctx, this, this, i);
      if (!boundingBox) {
        boundingBox = childBoundingBox;
      } else {
        boundingBox.addBoundingBox(childBoundingBox);
      }
    });
    return boundingBox;
  }
  getFontSize() {
    const {document: document2, parent} = this;
    const inheritFontSize = Font.parse(document2.ctx.font).fontSize;
    const fontSize = parent.getStyle("font-size").getNumber(inheritFontSize);
    return fontSize;
  }
  getTElementBoundingBox(ctx) {
    const fontSize = this.getFontSize();
    return new BoundingBox(this.x, this.y - fontSize, this.x + this.measureText(ctx), this.y);
  }
  getGlyph(font, text, i) {
    const char = text[i];
    let glyph;
    if (font.isArabic) {
      var ref;
      const len = text.length;
      const prevChar = text[i - 1];
      const nextChar = text[i + 1];
      let arabicForm = "isolated";
      if ((i === 0 || prevChar === " ") && i < len - 1 && nextChar !== " ") {
        arabicForm = "terminal";
      }
      if (i > 0 && prevChar !== " " && i < len - 1 && nextChar !== " ") {
        arabicForm = "medial";
      }
      if (i > 0 && prevChar !== " " && (i === len - 1 || nextChar === " ")) {
        arabicForm = "initial";
      }
      glyph = ((ref = font.arabicGlyphs[char]) === null || ref === void 0 ? void 0 : ref[arabicForm]) || font.glyphs[char];
    } else {
      glyph = font.glyphs[char];
    }
    if (!glyph) {
      glyph = font.missingGlyph;
    }
    return glyph;
  }
  getText() {
    return "";
  }
  getTextFromNode(node2) {
    const textNode = node2 || this.node;
    const childNodes = Array.from(textNode.parentNode.childNodes);
    const index2 = childNodes.indexOf(textNode);
    const lastIndex = childNodes.length - 1;
    let text = compressSpaces(textNode.textContent || "");
    if (index2 === 0) {
      text = trimLeft(text);
    }
    if (index2 === lastIndex) {
      text = trimRight(text);
    }
    return text;
  }
  renderChildren(ctx) {
    if (this.type !== "text") {
      this.renderTElementChildren(ctx);
      return;
    }
    this.initializeCoordinates();
    this.adjustChildCoordinatesRecursive(ctx);
    this.children.forEach((_, i) => {
      this.renderChild(ctx, this, this, i);
    });
    const {mouse} = this.document.screen;
    if (mouse.isWorking()) {
      mouse.checkBoundingBox(this, this.getBoundingBox(ctx));
    }
  }
  renderTElementChildren(ctx) {
    const {document: document2, parent} = this;
    const renderText = this.getText();
    const customFont = parent.getStyle("font-family").getDefinition();
    if (customFont) {
      const {unitsPerEm} = customFont.fontFace;
      const ctxFont = Font.parse(document2.ctx.font);
      const fontSize = parent.getStyle("font-size").getNumber(ctxFont.fontSize);
      const fontStyle = parent.getStyle("font-style").getString(ctxFont.fontStyle);
      const scale = fontSize / unitsPerEm;
      const text = customFont.isRTL ? renderText.split("").reverse().join("") : renderText;
      const dx = toNumbers(parent.getAttribute("dx").getString());
      const len = text.length;
      for (let i = 0; i < len; i++) {
        const glyph = this.getGlyph(customFont, text, i);
        ctx.translate(this.x, this.y);
        ctx.scale(scale, -scale);
        const lw = ctx.lineWidth;
        ctx.lineWidth = ctx.lineWidth * unitsPerEm / fontSize;
        if (fontStyle === "italic") {
          ctx.transform(1, 0, 0.4, 1, 0, 0);
        }
        glyph.render(ctx);
        if (fontStyle === "italic") {
          ctx.transform(1, 0, -0.4, 1, 0, 0);
        }
        ctx.lineWidth = lw;
        ctx.scale(1 / scale, -1 / scale);
        ctx.translate(-this.x, -this.y);
        this.x += fontSize * (glyph.horizAdvX || customFont.horizAdvX) / unitsPerEm;
        if (typeof dx[i] !== "undefined" && !isNaN(dx[i])) {
          this.x += dx[i];
        }
      }
      return;
    }
    const {x, y} = this;
    if (ctx.fillStyle) {
      ctx.fillText(renderText, x, y);
    }
    if (ctx.strokeStyle) {
      ctx.strokeText(renderText, x, y);
    }
  }
  applyAnchoring() {
    if (this.textChunkStart >= this.leafTexts.length) {
      return;
    }
    const firstElement = this.leafTexts[this.textChunkStart];
    const textAnchor = firstElement.getStyle("text-anchor").getString("start");
    const isRTL = false;
    let shift = 0;
    if (textAnchor === "start" && !isRTL || textAnchor === "end" && isRTL) {
      shift = firstElement.x - this.minX;
    } else if (textAnchor === "end" && !isRTL || textAnchor === "start" && isRTL) {
      shift = firstElement.x - this.maxX;
    } else {
      shift = firstElement.x - (this.minX + this.maxX) / 2;
    }
    for (let i = this.textChunkStart; i < this.leafTexts.length; i++) {
      this.leafTexts[i].x += shift;
    }
    this.minX = Number.POSITIVE_INFINITY;
    this.maxX = Number.NEGATIVE_INFINITY;
    this.textChunkStart = this.leafTexts.length;
  }
  adjustChildCoordinatesRecursive(ctx) {
    this.children.forEach((_, i) => {
      this.adjustChildCoordinatesRecursiveCore(ctx, this, this, i);
    });
    this.applyAnchoring();
  }
  adjustChildCoordinatesRecursiveCore(ctx, textParent, parent, i1) {
    const child = parent.children[i1];
    if (child.children.length > 0) {
      child.children.forEach((_, i) => {
        textParent.adjustChildCoordinatesRecursiveCore(ctx, textParent, child, i);
      });
    } else {
      this.adjustChildCoordinates(ctx, textParent, parent, i1);
    }
  }
  adjustChildCoordinates(ctx, textParent, parent, i) {
    const child = parent.children[i];
    if (typeof child.measureText !== "function") {
      return child;
    }
    ctx.save();
    child.setContext(ctx, true);
    const xAttr = child.getAttribute("x");
    const yAttr = child.getAttribute("y");
    const dxAttr = child.getAttribute("dx");
    const dyAttr = child.getAttribute("dy");
    const customFont = child.getStyle("font-family").getDefinition();
    const isRTL = Boolean(customFont === null || customFont === void 0 ? void 0 : customFont.isRTL);
    if (i === 0) {
      if (!xAttr.hasValue()) {
        xAttr.setValue(child.getInheritedAttribute("x"));
      }
      if (!yAttr.hasValue()) {
        yAttr.setValue(child.getInheritedAttribute("y"));
      }
      if (!dxAttr.hasValue()) {
        dxAttr.setValue(child.getInheritedAttribute("dx"));
      }
      if (!dyAttr.hasValue()) {
        dyAttr.setValue(child.getInheritedAttribute("dy"));
      }
    }
    const width = child.measureText(ctx);
    if (isRTL) {
      textParent.x -= width;
    }
    if (xAttr.hasValue()) {
      textParent.applyAnchoring();
      child.x = xAttr.getPixels("x");
      if (dxAttr.hasValue()) {
        child.x += dxAttr.getPixels("x");
      }
    } else {
      if (dxAttr.hasValue()) {
        textParent.x += dxAttr.getPixels("x");
      }
      child.x = textParent.x;
    }
    textParent.x = child.x;
    if (!isRTL) {
      textParent.x += width;
    }
    if (yAttr.hasValue()) {
      child.y = yAttr.getPixels("y");
      if (dyAttr.hasValue()) {
        child.y += dyAttr.getPixels("y");
      }
    } else {
      if (dyAttr.hasValue()) {
        textParent.y += dyAttr.getPixels("y");
      }
      child.y = textParent.y;
    }
    textParent.y = child.y;
    textParent.leafTexts.push(child);
    textParent.minX = Math.min(textParent.minX, child.x, child.x + width);
    textParent.maxX = Math.max(textParent.maxX, child.x, child.x + width);
    child.clearContext(ctx);
    ctx.restore();
    return child;
  }
  getChildBoundingBox(ctx, textParent, parent, i2) {
    const child = parent.children[i2];
    if (typeof child.getBoundingBox !== "function") {
      return null;
    }
    const boundingBox = child.getBoundingBox(ctx);
    if (boundingBox) {
      child.children.forEach((_, i) => {
        const childBoundingBox = textParent.getChildBoundingBox(ctx, textParent, child, i);
        boundingBox.addBoundingBox(childBoundingBox);
      });
    }
    return boundingBox;
  }
  renderChild(ctx, textParent, parent, i3) {
    const child = parent.children[i3];
    child.render(ctx);
    child.children.forEach((_, i) => {
      textParent.renderChild(ctx, textParent, child, i);
    });
  }
  measureText(ctx) {
    const {measureCache} = this;
    if (~measureCache) {
      return measureCache;
    }
    const renderText = this.getText();
    const measure = this.measureTargetText(ctx, renderText);
    this.measureCache = measure;
    return measure;
  }
  measureTargetText(ctx, targetText) {
    if (!targetText.length) {
      return 0;
    }
    const {parent} = this;
    const customFont = parent.getStyle("font-family").getDefinition();
    if (customFont) {
      const fontSize = this.getFontSize();
      const text = customFont.isRTL ? targetText.split("").reverse().join("") : targetText;
      const dx = toNumbers(parent.getAttribute("dx").getString());
      const len = text.length;
      let measure2 = 0;
      for (let i = 0; i < len; i++) {
        const glyph = this.getGlyph(customFont, text, i);
        measure2 += (glyph.horizAdvX || customFont.horizAdvX) * fontSize / customFont.fontFace.unitsPerEm;
        if (typeof dx[i] !== "undefined" && !isNaN(dx[i])) {
          measure2 += dx[i];
        }
      }
      return measure2;
    }
    if (!ctx.measureText) {
      return targetText.length * 10;
    }
    ctx.save();
    this.setContext(ctx, true);
    const {width: measure} = ctx.measureText(targetText);
    this.clearContext(ctx);
    ctx.restore();
    return measure;
  }
  getInheritedAttribute(name) {
    let current = this;
    while (current instanceof TextElement && current.isFirstChild() && current.parent) {
      const parentAttr = current.parent.getAttribute(name);
      if (parentAttr.hasValue(true)) {
        return parentAttr.getString("0");
      }
      current = current.parent;
    }
    return null;
  }
  constructor(document2, node2, captureTextNodes) {
    super(document2, node2, new.target === TextElement ? true : captureTextNodes);
    this.type = "text";
    this.x = 0;
    this.y = 0;
    this.leafTexts = [];
    this.textChunkStart = 0;
    this.minX = Number.POSITIVE_INFINITY;
    this.maxX = Number.NEGATIVE_INFINITY;
    this.measureCache = -1;
  }
}
class TSpanElement extends TextElement {
  getText() {
    return this.text;
  }
  constructor(document2, node2, captureTextNodes) {
    super(document2, node2, new.target === TSpanElement ? true : captureTextNodes);
    this.type = "tspan";
    this.text = this.children.length > 0 ? "" : this.getTextFromNode();
  }
}
class TextNode extends TSpanElement {
  constructor(...args) {
    super(...args);
    this.type = "textNode";
  }
}
class PathParser extends SVGPathData {
  reset() {
    this.i = -1;
    this.command = null;
    this.previousCommand = null;
    this.start = new Point(0, 0);
    this.control = new Point(0, 0);
    this.current = new Point(0, 0);
    this.points = [];
    this.angles = [];
  }
  isEnd() {
    const {i, commands} = this;
    return i >= commands.length - 1;
  }
  next() {
    const command = this.commands[++this.i];
    this.previousCommand = this.command;
    this.command = command;
    return command;
  }
  getPoint() {
    let xProp = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : "x", yProp = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "y";
    const point = new Point(this.command[xProp], this.command[yProp]);
    return this.makeAbsolute(point);
  }
  getAsControlPoint(xProp, yProp) {
    const point = this.getPoint(xProp, yProp);
    this.control = point;
    return point;
  }
  getAsCurrentPoint(xProp, yProp) {
    const point = this.getPoint(xProp, yProp);
    this.current = point;
    return point;
  }
  getReflectedControlPoint() {
    const previousCommand = this.previousCommand.type;
    if (previousCommand !== SVGPathData.CURVE_TO && previousCommand !== SVGPathData.SMOOTH_CURVE_TO && previousCommand !== SVGPathData.QUAD_TO && previousCommand !== SVGPathData.SMOOTH_QUAD_TO) {
      return this.current;
    }
    const {current: {x: cx, y: cy}, control: {x: ox, y: oy}} = this;
    const point = new Point(2 * cx - ox, 2 * cy - oy);
    return point;
  }
  makeAbsolute(point) {
    if (this.command.relative) {
      const {x, y} = this.current;
      point.x += x;
      point.y += y;
    }
    return point;
  }
  addMarker(point, from, priorTo) {
    const {points, angles} = this;
    if (priorTo && angles.length > 0 && !angles[angles.length - 1]) {
      angles[angles.length - 1] = points[points.length - 1].angleTo(priorTo);
    }
    this.addMarkerAngle(point, from ? from.angleTo(point) : null);
  }
  addMarkerAngle(point, angle) {
    this.points.push(point);
    this.angles.push(angle);
  }
  getMarkerPoints() {
    return this.points;
  }
  getMarkerAngles() {
    const {angles} = this;
    const len = angles.length;
    for (let i = 0; i < len; i++) {
      if (!angles[i]) {
        for (let j = i + 1; j < len; j++) {
          if (angles[j]) {
            angles[i] = angles[j];
            break;
          }
        }
      }
    }
    return angles;
  }
  constructor(path) {
    super(path.replace(/([+\-.])\s+/gm, "$1").replace(/[^MmZzLlHhVvCcSsQqTtAae\d\s.,+-].*/g, ""));
    this.control = new Point(0, 0);
    this.start = new Point(0, 0);
    this.current = new Point(0, 0);
    this.command = null;
    this.commands = this.commands;
    this.i = -1;
    this.previousCommand = null;
    this.points = [];
    this.angles = [];
  }
}
class PathElement extends RenderedElement {
  path(ctx) {
    const {pathParser} = this;
    const boundingBox = new BoundingBox();
    pathParser.reset();
    if (ctx) {
      ctx.beginPath();
    }
    while (!pathParser.isEnd()) {
      switch (pathParser.next().type) {
        case PathParser.MOVE_TO:
          this.pathM(ctx, boundingBox);
          break;
        case PathParser.LINE_TO:
          this.pathL(ctx, boundingBox);
          break;
        case PathParser.HORIZ_LINE_TO:
          this.pathH(ctx, boundingBox);
          break;
        case PathParser.VERT_LINE_TO:
          this.pathV(ctx, boundingBox);
          break;
        case PathParser.CURVE_TO:
          this.pathC(ctx, boundingBox);
          break;
        case PathParser.SMOOTH_CURVE_TO:
          this.pathS(ctx, boundingBox);
          break;
        case PathParser.QUAD_TO:
          this.pathQ(ctx, boundingBox);
          break;
        case PathParser.SMOOTH_QUAD_TO:
          this.pathT(ctx, boundingBox);
          break;
        case PathParser.ARC:
          this.pathA(ctx, boundingBox);
          break;
        case PathParser.CLOSE_PATH:
          this.pathZ(ctx, boundingBox);
          break;
      }
    }
    return boundingBox;
  }
  getBoundingBox(_ctx) {
    return this.path();
  }
  getMarkers() {
    const {pathParser} = this;
    const points = pathParser.getMarkerPoints();
    const angles = pathParser.getMarkerAngles();
    const markers = points.map((point, i) => [
      point,
      angles[i]
    ]);
    return markers;
  }
  renderChildren(ctx) {
    this.path(ctx);
    this.document.screen.mouse.checkPath(this, ctx);
    const fillRuleStyleProp = this.getStyle("fill-rule");
    if (ctx.fillStyle !== "") {
      if (fillRuleStyleProp.getString("inherit") !== "inherit") {
        ctx.fill(fillRuleStyleProp.getString());
      } else {
        ctx.fill();
      }
    }
    if (ctx.strokeStyle !== "") {
      if (this.getAttribute("vector-effect").getString() === "non-scaling-stroke") {
        ctx.save();
        ctx.setTransform(1, 0, 0, 1, 0, 0);
        ctx.stroke();
        ctx.restore();
      } else {
        ctx.stroke();
      }
    }
    const markers = this.getMarkers();
    if (markers) {
      const markersLastIndex = markers.length - 1;
      const markerStartStyleProp = this.getStyle("marker-start");
      const markerMidStyleProp = this.getStyle("marker-mid");
      const markerEndStyleProp = this.getStyle("marker-end");
      if (markerStartStyleProp.isUrlDefinition()) {
        const marker = markerStartStyleProp.getDefinition();
        const [point, angle] = markers[0];
        marker.render(ctx, point, angle);
      }
      if (markerMidStyleProp.isUrlDefinition()) {
        const marker = markerMidStyleProp.getDefinition();
        for (let i = 1; i < markersLastIndex; i++) {
          const [point, angle] = markers[i];
          marker.render(ctx, point, angle);
        }
      }
      if (markerEndStyleProp.isUrlDefinition()) {
        const marker = markerEndStyleProp.getDefinition();
        const [point, angle] = markers[markersLastIndex];
        marker.render(ctx, point, angle);
      }
    }
  }
  static pathM(pathParser) {
    const point = pathParser.getAsCurrentPoint();
    pathParser.start = pathParser.current;
    return {
      point
    };
  }
  pathM(ctx, boundingBox) {
    const {pathParser} = this;
    const {point} = PathElement.pathM(pathParser);
    const {x, y} = point;
    pathParser.addMarker(point);
    boundingBox.addPoint(x, y);
    if (ctx) {
      ctx.moveTo(x, y);
    }
  }
  static pathL(pathParser) {
    const {current} = pathParser;
    const point = pathParser.getAsCurrentPoint();
    return {
      current,
      point
    };
  }
  pathL(ctx, boundingBox) {
    const {pathParser} = this;
    const {current, point} = PathElement.pathL(pathParser);
    const {x, y} = point;
    pathParser.addMarker(point, current);
    boundingBox.addPoint(x, y);
    if (ctx) {
      ctx.lineTo(x, y);
    }
  }
  static pathH(pathParser) {
    const {current, command} = pathParser;
    const point = new Point((command.relative ? current.x : 0) + command.x, current.y);
    pathParser.current = point;
    return {
      current,
      point
    };
  }
  pathH(ctx, boundingBox) {
    const {pathParser} = this;
    const {current, point} = PathElement.pathH(pathParser);
    const {x, y} = point;
    pathParser.addMarker(point, current);
    boundingBox.addPoint(x, y);
    if (ctx) {
      ctx.lineTo(x, y);
    }
  }
  static pathV(pathParser) {
    const {current, command} = pathParser;
    const point = new Point(current.x, (command.relative ? current.y : 0) + command.y);
    pathParser.current = point;
    return {
      current,
      point
    };
  }
  pathV(ctx, boundingBox) {
    const {pathParser} = this;
    const {current, point} = PathElement.pathV(pathParser);
    const {x, y} = point;
    pathParser.addMarker(point, current);
    boundingBox.addPoint(x, y);
    if (ctx) {
      ctx.lineTo(x, y);
    }
  }
  static pathC(pathParser) {
    const {current} = pathParser;
    const point = pathParser.getPoint("x1", "y1");
    const controlPoint = pathParser.getAsControlPoint("x2", "y2");
    const currentPoint = pathParser.getAsCurrentPoint();
    return {
      current,
      point,
      controlPoint,
      currentPoint
    };
  }
  pathC(ctx, boundingBox) {
    const {pathParser} = this;
    const {current, point, controlPoint, currentPoint} = PathElement.pathC(pathParser);
    pathParser.addMarker(currentPoint, controlPoint, point);
    boundingBox.addBezierCurve(current.x, current.y, point.x, point.y, controlPoint.x, controlPoint.y, currentPoint.x, currentPoint.y);
    if (ctx) {
      ctx.bezierCurveTo(point.x, point.y, controlPoint.x, controlPoint.y, currentPoint.x, currentPoint.y);
    }
  }
  static pathS(pathParser) {
    const {current} = pathParser;
    const point = pathParser.getReflectedControlPoint();
    const controlPoint = pathParser.getAsControlPoint("x2", "y2");
    const currentPoint = pathParser.getAsCurrentPoint();
    return {
      current,
      point,
      controlPoint,
      currentPoint
    };
  }
  pathS(ctx, boundingBox) {
    const {pathParser} = this;
    const {current, point, controlPoint, currentPoint} = PathElement.pathS(pathParser);
    pathParser.addMarker(currentPoint, controlPoint, point);
    boundingBox.addBezierCurve(current.x, current.y, point.x, point.y, controlPoint.x, controlPoint.y, currentPoint.x, currentPoint.y);
    if (ctx) {
      ctx.bezierCurveTo(point.x, point.y, controlPoint.x, controlPoint.y, currentPoint.x, currentPoint.y);
    }
  }
  static pathQ(pathParser) {
    const {current} = pathParser;
    const controlPoint = pathParser.getAsControlPoint("x1", "y1");
    const currentPoint = pathParser.getAsCurrentPoint();
    return {
      current,
      controlPoint,
      currentPoint
    };
  }
  pathQ(ctx, boundingBox) {
    const {pathParser} = this;
    const {current, controlPoint, currentPoint} = PathElement.pathQ(pathParser);
    pathParser.addMarker(currentPoint, controlPoint, controlPoint);
    boundingBox.addQuadraticCurve(current.x, current.y, controlPoint.x, controlPoint.y, currentPoint.x, currentPoint.y);
    if (ctx) {
      ctx.quadraticCurveTo(controlPoint.x, controlPoint.y, currentPoint.x, currentPoint.y);
    }
  }
  static pathT(pathParser) {
    const {current} = pathParser;
    const controlPoint = pathParser.getReflectedControlPoint();
    pathParser.control = controlPoint;
    const currentPoint = pathParser.getAsCurrentPoint();
    return {
      current,
      controlPoint,
      currentPoint
    };
  }
  pathT(ctx, boundingBox) {
    const {pathParser} = this;
    const {current, controlPoint, currentPoint} = PathElement.pathT(pathParser);
    pathParser.addMarker(currentPoint, controlPoint, controlPoint);
    boundingBox.addQuadraticCurve(current.x, current.y, controlPoint.x, controlPoint.y, currentPoint.x, currentPoint.y);
    if (ctx) {
      ctx.quadraticCurveTo(controlPoint.x, controlPoint.y, currentPoint.x, currentPoint.y);
    }
  }
  static pathA(pathParser) {
    const {current, command} = pathParser;
    let {rX, rY, xRot, lArcFlag, sweepFlag} = command;
    const xAxisRotation = xRot * (Math.PI / 180);
    const currentPoint = pathParser.getAsCurrentPoint();
    const currp = new Point(Math.cos(xAxisRotation) * (current.x - currentPoint.x) / 2 + Math.sin(xAxisRotation) * (current.y - currentPoint.y) / 2, -Math.sin(xAxisRotation) * (current.x - currentPoint.x) / 2 + Math.cos(xAxisRotation) * (current.y - currentPoint.y) / 2);
    const l = Math.pow(currp.x, 2) / Math.pow(rX, 2) + Math.pow(currp.y, 2) / Math.pow(rY, 2);
    if (l > 1) {
      rX *= Math.sqrt(l);
      rY *= Math.sqrt(l);
    }
    let s = (lArcFlag === sweepFlag ? -1 : 1) * Math.sqrt((Math.pow(rX, 2) * Math.pow(rY, 2) - Math.pow(rX, 2) * Math.pow(currp.y, 2) - Math.pow(rY, 2) * Math.pow(currp.x, 2)) / (Math.pow(rX, 2) * Math.pow(currp.y, 2) + Math.pow(rY, 2) * Math.pow(currp.x, 2)));
    if (isNaN(s)) {
      s = 0;
    }
    const cpp = new Point(s * rX * currp.y / rY, s * -rY * currp.x / rX);
    const centp = new Point((current.x + currentPoint.x) / 2 + Math.cos(xAxisRotation) * cpp.x - Math.sin(xAxisRotation) * cpp.y, (current.y + currentPoint.y) / 2 + Math.sin(xAxisRotation) * cpp.x + Math.cos(xAxisRotation) * cpp.y);
    const a1 = vectorsAngle([
      1,
      0
    ], [
      (currp.x - cpp.x) / rX,
      (currp.y - cpp.y) / rY
    ]);
    const u = [
      (currp.x - cpp.x) / rX,
      (currp.y - cpp.y) / rY
    ];
    const v = [
      (-currp.x - cpp.x) / rX,
      (-currp.y - cpp.y) / rY
    ];
    let ad = vectorsAngle(u, v);
    if (vectorsRatio(u, v) <= -1) {
      ad = Math.PI;
    }
    if (vectorsRatio(u, v) >= 1) {
      ad = 0;
    }
    return {
      currentPoint,
      rX,
      rY,
      sweepFlag,
      xAxisRotation,
      centp,
      a1,
      ad
    };
  }
  pathA(ctx, boundingBox) {
    const {pathParser} = this;
    const {currentPoint, rX, rY, sweepFlag, xAxisRotation, centp, a1, ad} = PathElement.pathA(pathParser);
    const dir = 1 - sweepFlag ? 1 : -1;
    const ah = a1 + dir * (ad / 2);
    const halfWay = new Point(centp.x + rX * Math.cos(ah), centp.y + rY * Math.sin(ah));
    pathParser.addMarkerAngle(halfWay, ah - dir * Math.PI / 2);
    pathParser.addMarkerAngle(currentPoint, ah - dir * Math.PI);
    boundingBox.addPoint(currentPoint.x, currentPoint.y);
    if (ctx && !isNaN(a1) && !isNaN(ad)) {
      const r = rX > rY ? rX : rY;
      const sx = rX > rY ? 1 : rX / rY;
      const sy = rX > rY ? rY / rX : 1;
      ctx.translate(centp.x, centp.y);
      ctx.rotate(xAxisRotation);
      ctx.scale(sx, sy);
      ctx.arc(0, 0, r, a1, a1 + ad, Boolean(1 - sweepFlag));
      ctx.scale(1 / sx, 1 / sy);
      ctx.rotate(-xAxisRotation);
      ctx.translate(-centp.x, -centp.y);
    }
  }
  static pathZ(pathParser) {
    pathParser.current = pathParser.start;
  }
  pathZ(ctx, boundingBox) {
    PathElement.pathZ(this.pathParser);
    if (ctx) {
      if (boundingBox.x1 !== boundingBox.x2 && boundingBox.y1 !== boundingBox.y2) {
        ctx.closePath();
      }
    }
  }
  constructor(document2, node2, captureTextNodes) {
    super(document2, node2, captureTextNodes);
    this.type = "path";
    this.pathParser = new PathParser(this.getAttribute("d").getString());
  }
}
class SVGElement extends RenderedElement {
  setContext(ctx) {
    var ref;
    const {document: document2} = this;
    const {screen, window: window2} = document2;
    const canvas = ctx.canvas;
    screen.setDefaults(ctx);
    if ("style" in canvas && typeof ctx.font !== "undefined" && window2 && typeof window2.getComputedStyle !== "undefined") {
      ctx.font = window2.getComputedStyle(canvas).getPropertyValue("font");
      const fontSizeProp = new Property(document2, "fontSize", Font.parse(ctx.font).fontSize);
      if (fontSizeProp.hasValue()) {
        document2.rootEmSize = fontSizeProp.getPixels("y");
        document2.emSize = document2.rootEmSize;
      }
    }
    if (!this.getAttribute("x").hasValue()) {
      this.getAttribute("x", true).setValue(0);
    }
    if (!this.getAttribute("y").hasValue()) {
      this.getAttribute("y", true).setValue(0);
    }
    let {width, height} = screen.viewPort;
    if (!this.getStyle("width").hasValue()) {
      this.getStyle("width", true).setValue("100%");
    }
    if (!this.getStyle("height").hasValue()) {
      this.getStyle("height", true).setValue("100%");
    }
    if (!this.getStyle("color").hasValue()) {
      this.getStyle("color", true).setValue("black");
    }
    const refXAttr = this.getAttribute("refX");
    const refYAttr = this.getAttribute("refY");
    const viewBoxAttr = this.getAttribute("viewBox");
    const viewBox = viewBoxAttr.hasValue() ? toNumbers(viewBoxAttr.getString()) : null;
    const clip = !this.root && this.getStyle("overflow").getValue("hidden") !== "visible";
    let minX = 0;
    let minY = 0;
    let clipX = 0;
    let clipY = 0;
    if (viewBox) {
      minX = viewBox[0];
      minY = viewBox[1];
    }
    if (!this.root) {
      width = this.getStyle("width").getPixels("x");
      height = this.getStyle("height").getPixels("y");
      if (this.type === "marker") {
        clipX = minX;
        clipY = minY;
        minX = 0;
        minY = 0;
      }
    }
    screen.viewPort.setCurrent(width, height);
    if (this.node && (!this.parent || ((ref = this.node.parentNode) === null || ref === void 0 ? void 0 : ref.nodeName) === "foreignObject") && this.getStyle("transform", false, true).hasValue() && !this.getStyle("transform-origin", false, true).hasValue()) {
      this.getStyle("transform-origin", true, true).setValue("50% 50%");
    }
    super.setContext(ctx);
    ctx.translate(this.getAttribute("x").getPixels("x"), this.getAttribute("y").getPixels("y"));
    if (viewBox) {
      width = viewBox[2];
      height = viewBox[3];
    }
    document2.setViewBox({
      ctx,
      aspectRatio: this.getAttribute("preserveAspectRatio").getString(),
      width: screen.viewPort.width,
      desiredWidth: width,
      height: screen.viewPort.height,
      desiredHeight: height,
      minX,
      minY,
      refX: refXAttr.getValue(),
      refY: refYAttr.getValue(),
      clip,
      clipX,
      clipY
    });
    if (viewBox) {
      screen.viewPort.removeCurrent();
      screen.viewPort.setCurrent(width, height);
    }
  }
  clearContext(ctx) {
    super.clearContext(ctx);
    this.document.screen.viewPort.removeCurrent();
  }
  resize(width) {
    let height = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : width, preserveAspectRatio = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
    const widthAttr = this.getAttribute("width", true);
    const heightAttr = this.getAttribute("height", true);
    const viewBoxAttr = this.getAttribute("viewBox");
    const styleAttr = this.getAttribute("style");
    const originWidth = widthAttr.getNumber(0);
    const originHeight = heightAttr.getNumber(0);
    if (preserveAspectRatio) {
      if (typeof preserveAspectRatio === "string") {
        this.getAttribute("preserveAspectRatio", true).setValue(preserveAspectRatio);
      } else {
        const preserveAspectRatioAttr = this.getAttribute("preserveAspectRatio");
        if (preserveAspectRatioAttr.hasValue()) {
          preserveAspectRatioAttr.setValue(preserveAspectRatioAttr.getString().replace(/^\s*(\S.*\S)\s*$/, "$1"));
        }
      }
    }
    widthAttr.setValue(width);
    heightAttr.setValue(height);
    if (!viewBoxAttr.hasValue()) {
      viewBoxAttr.setValue("0 0 ".concat(originWidth || width, " ").concat(originHeight || height));
    }
    if (styleAttr.hasValue()) {
      const widthStyle = this.getStyle("width");
      const heightStyle = this.getStyle("height");
      if (widthStyle.hasValue()) {
        widthStyle.setValue("".concat(width, "px"));
      }
      if (heightStyle.hasValue()) {
        heightStyle.setValue("".concat(height, "px"));
      }
    }
  }
  constructor(...args) {
    super(...args);
    this.type = "svg";
    this.root = false;
  }
}
class RectElement extends PathElement {
  path(ctx) {
    const x = this.getAttribute("x").getPixels("x");
    const y = this.getAttribute("y").getPixels("y");
    const width = this.getStyle("width", false, true).getPixels("x");
    const height = this.getStyle("height", false, true).getPixels("y");
    const rxAttr = this.getAttribute("rx");
    const ryAttr = this.getAttribute("ry");
    let rx = rxAttr.getPixels("x");
    let ry = ryAttr.getPixels("y");
    if (rxAttr.hasValue() && !ryAttr.hasValue()) {
      ry = rx;
    }
    if (ryAttr.hasValue() && !rxAttr.hasValue()) {
      rx = ry;
    }
    rx = Math.min(rx, width / 2);
    ry = Math.min(ry, height / 2);
    if (ctx) {
      const KAPPA = 4 * ((Math.sqrt(2) - 1) / 3);
      ctx.beginPath();
      if (height > 0 && width > 0) {
        ctx.moveTo(x + rx, y);
        ctx.lineTo(x + width - rx, y);
        ctx.bezierCurveTo(x + width - rx + KAPPA * rx, y, x + width, y + ry - KAPPA * ry, x + width, y + ry);
        ctx.lineTo(x + width, y + height - ry);
        ctx.bezierCurveTo(x + width, y + height - ry + KAPPA * ry, x + width - rx + KAPPA * rx, y + height, x + width - rx, y + height);
        ctx.lineTo(x + rx, y + height);
        ctx.bezierCurveTo(x + rx - KAPPA * rx, y + height, x, y + height - ry + KAPPA * ry, x, y + height - ry);
        ctx.lineTo(x, y + ry);
        ctx.bezierCurveTo(x, y + ry - KAPPA * ry, x + rx - KAPPA * rx, y, x + rx, y);
        ctx.closePath();
      }
    }
    return new BoundingBox(x, y, x + width, y + height);
  }
  getMarkers() {
    return null;
  }
  constructor(...args) {
    super(...args);
    this.type = "rect";
  }
}
class CircleElement extends PathElement {
  path(ctx) {
    const cx = this.getAttribute("cx").getPixels("x");
    const cy = this.getAttribute("cy").getPixels("y");
    const r = this.getAttribute("r").getPixels();
    if (ctx && r > 0) {
      ctx.beginPath();
      ctx.arc(cx, cy, r, 0, Math.PI * 2, false);
      ctx.closePath();
    }
    return new BoundingBox(cx - r, cy - r, cx + r, cy + r);
  }
  getMarkers() {
    return null;
  }
  constructor(...args) {
    super(...args);
    this.type = "circle";
  }
}
class EllipseElement extends PathElement {
  path(ctx) {
    const KAPPA = 4 * ((Math.sqrt(2) - 1) / 3);
    const rx = this.getAttribute("rx").getPixels("x");
    const ry = this.getAttribute("ry").getPixels("y");
    const cx = this.getAttribute("cx").getPixels("x");
    const cy = this.getAttribute("cy").getPixels("y");
    if (ctx && rx > 0 && ry > 0) {
      ctx.beginPath();
      ctx.moveTo(cx + rx, cy);
      ctx.bezierCurveTo(cx + rx, cy + KAPPA * ry, cx + KAPPA * rx, cy + ry, cx, cy + ry);
      ctx.bezierCurveTo(cx - KAPPA * rx, cy + ry, cx - rx, cy + KAPPA * ry, cx - rx, cy);
      ctx.bezierCurveTo(cx - rx, cy - KAPPA * ry, cx - KAPPA * rx, cy - ry, cx, cy - ry);
      ctx.bezierCurveTo(cx + KAPPA * rx, cy - ry, cx + rx, cy - KAPPA * ry, cx + rx, cy);
      ctx.closePath();
    }
    return new BoundingBox(cx - rx, cy - ry, cx + rx, cy + ry);
  }
  getMarkers() {
    return null;
  }
  constructor(...args) {
    super(...args);
    this.type = "ellipse";
  }
}
class LineElement extends PathElement {
  getPoints() {
    return [
      new Point(this.getAttribute("x1").getPixels("x"), this.getAttribute("y1").getPixels("y")),
      new Point(this.getAttribute("x2").getPixels("x"), this.getAttribute("y2").getPixels("y"))
    ];
  }
  path(ctx) {
    const [{x: x0, y: y0}, {x: x1, y: y1}] = this.getPoints();
    if (ctx) {
      ctx.beginPath();
      ctx.moveTo(x0, y0);
      ctx.lineTo(x1, y1);
    }
    return new BoundingBox(x0, y0, x1, y1);
  }
  getMarkers() {
    const [p0, p1] = this.getPoints();
    const a = p0.angleTo(p1);
    return [
      [
        p0,
        a
      ],
      [
        p1,
        a
      ]
    ];
  }
  constructor(...args) {
    super(...args);
    this.type = "line";
  }
}
class PolylineElement extends PathElement {
  path(ctx) {
    const {points} = this;
    const [{x: x0, y: y0}] = points;
    const boundingBox = new BoundingBox(x0, y0);
    if (ctx) {
      ctx.beginPath();
      ctx.moveTo(x0, y0);
    }
    points.forEach((param) => {
      let {x, y} = param;
      boundingBox.addPoint(x, y);
      if (ctx) {
        ctx.lineTo(x, y);
      }
    });
    return boundingBox;
  }
  getMarkers() {
    const {points} = this;
    const lastIndex = points.length - 1;
    const markers = [];
    points.forEach((point, i) => {
      if (i === lastIndex) {
        return;
      }
      markers.push([
        point,
        point.angleTo(points[i + 1])
      ]);
    });
    if (markers.length > 0) {
      markers.push([
        points[points.length - 1],
        markers[markers.length - 1][1]
      ]);
    }
    return markers;
  }
  constructor(document2, node2, captureTextNodes) {
    super(document2, node2, captureTextNodes);
    this.type = "polyline";
    this.points = [];
    this.points = Point.parsePath(this.getAttribute("points").getString());
  }
}
class PolygonElement extends PolylineElement {
  path(ctx) {
    const boundingBox = super.path(ctx);
    const [{x, y}] = this.points;
    if (ctx) {
      ctx.lineTo(x, y);
      ctx.closePath();
    }
    return boundingBox;
  }
  constructor(...args) {
    super(...args);
    this.type = "polygon";
  }
}
class PatternElement extends Element {
  createPattern(ctx, _, parentOpacityProp) {
    const width = this.getStyle("width").getPixels("x", true);
    const height = this.getStyle("height").getPixels("y", true);
    const patternSvg = new SVGElement(this.document, null);
    patternSvg.attributes.viewBox = new Property(this.document, "viewBox", this.getAttribute("viewBox").getValue());
    patternSvg.attributes.width = new Property(this.document, "width", "".concat(width, "px"));
    patternSvg.attributes.height = new Property(this.document, "height", "".concat(height, "px"));
    patternSvg.attributes.transform = new Property(this.document, "transform", this.getAttribute("patternTransform").getValue());
    patternSvg.children = this.children;
    const patternCanvas = this.document.createCanvas(width, height);
    const patternCtx = patternCanvas.getContext("2d");
    const xAttr = this.getAttribute("x");
    const yAttr = this.getAttribute("y");
    if (xAttr.hasValue() && yAttr.hasValue()) {
      patternCtx.translate(xAttr.getPixels("x", true), yAttr.getPixels("y", true));
    }
    if (parentOpacityProp.hasValue()) {
      this.styles["fill-opacity"] = parentOpacityProp;
    } else {
      Reflect.deleteProperty(this.styles, "fill-opacity");
    }
    for (let x = -1; x <= 1; x++) {
      for (let y = -1; y <= 1; y++) {
        patternCtx.save();
        patternSvg.attributes.x = new Property(this.document, "x", x * patternCanvas.width);
        patternSvg.attributes.y = new Property(this.document, "y", y * patternCanvas.height);
        patternSvg.render(patternCtx);
        patternCtx.restore();
      }
    }
    const pattern = ctx.createPattern(patternCanvas, "repeat");
    return pattern;
  }
  constructor(...args) {
    super(...args);
    this.type = "pattern";
  }
}
class MarkerElement extends Element {
  render(ctx, point, angle) {
    if (!point) {
      return;
    }
    const {x, y} = point;
    const orient = this.getAttribute("orient").getString("auto");
    const markerUnits = this.getAttribute("markerUnits").getString("strokeWidth");
    ctx.translate(x, y);
    if (orient === "auto") {
      ctx.rotate(angle);
    }
    if (markerUnits === "strokeWidth") {
      ctx.scale(ctx.lineWidth, ctx.lineWidth);
    }
    ctx.save();
    const markerSvg = new SVGElement(this.document);
    markerSvg.type = this.type;
    markerSvg.attributes.viewBox = new Property(this.document, "viewBox", this.getAttribute("viewBox").getValue());
    markerSvg.attributes.refX = new Property(this.document, "refX", this.getAttribute("refX").getValue());
    markerSvg.attributes.refY = new Property(this.document, "refY", this.getAttribute("refY").getValue());
    markerSvg.attributes.width = new Property(this.document, "width", this.getAttribute("markerWidth").getValue());
    markerSvg.attributes.height = new Property(this.document, "height", this.getAttribute("markerHeight").getValue());
    markerSvg.attributes.overflow = new Property(this.document, "overflow", this.getAttribute("overflow").getValue());
    markerSvg.attributes.fill = new Property(this.document, "fill", this.getAttribute("fill").getColor("black"));
    markerSvg.attributes.stroke = new Property(this.document, "stroke", this.getAttribute("stroke").getValue("none"));
    markerSvg.children = this.children;
    markerSvg.render(ctx);
    ctx.restore();
    if (markerUnits === "strokeWidth") {
      ctx.scale(1 / ctx.lineWidth, 1 / ctx.lineWidth);
    }
    if (orient === "auto") {
      ctx.rotate(-angle);
    }
    ctx.translate(-x, -y);
  }
  constructor(...args) {
    super(...args);
    this.type = "marker";
  }
}
class DefsElement extends Element {
  render() {
  }
  constructor(...args) {
    super(...args);
    this.type = "defs";
  }
}
class GElement extends RenderedElement {
  getBoundingBox(ctx) {
    const boundingBox = new BoundingBox();
    this.children.forEach((child) => {
      boundingBox.addBoundingBox(child.getBoundingBox(ctx));
    });
    return boundingBox;
  }
  constructor(...args) {
    super(...args);
    this.type = "g";
  }
}
class GradientElement extends Element {
  getGradientUnits() {
    return this.getAttribute("gradientUnits").getString("objectBoundingBox");
  }
  createGradient(ctx, element, parentOpacityProp) {
    let stopsContainer = this;
    if (this.getHrefAttribute().hasValue()) {
      stopsContainer = this.getHrefAttribute().getDefinition();
      this.inheritStopContainer(stopsContainer);
    }
    const {stops} = stopsContainer;
    const gradient = this.getGradient(ctx, element);
    if (!gradient) {
      return this.addParentOpacity(parentOpacityProp, stops[stops.length - 1].color);
    }
    stops.forEach((stop) => {
      gradient.addColorStop(stop.offset, this.addParentOpacity(parentOpacityProp, stop.color));
    });
    if (this.getAttribute("gradientTransform").hasValue()) {
      const {document: document2} = this;
      const {MAX_VIRTUAL_PIXELS} = Screen;
      const {viewPort} = document2.screen;
      const rootView = viewPort.getRoot();
      const rect = new RectElement(document2);
      rect.attributes.x = new Property(document2, "x", -MAX_VIRTUAL_PIXELS / 3);
      rect.attributes.y = new Property(document2, "y", -MAX_VIRTUAL_PIXELS / 3);
      rect.attributes.width = new Property(document2, "width", MAX_VIRTUAL_PIXELS);
      rect.attributes.height = new Property(document2, "height", MAX_VIRTUAL_PIXELS);
      const group = new GElement(document2);
      group.attributes.transform = new Property(document2, "transform", this.getAttribute("gradientTransform").getValue());
      group.children = [
        rect
      ];
      const patternSvg = new SVGElement(document2);
      patternSvg.attributes.x = new Property(document2, "x", 0);
      patternSvg.attributes.y = new Property(document2, "y", 0);
      patternSvg.attributes.width = new Property(document2, "width", rootView.width);
      patternSvg.attributes.height = new Property(document2, "height", rootView.height);
      patternSvg.children = [
        group
      ];
      const patternCanvas = document2.createCanvas(rootView.width, rootView.height);
      const patternCtx = patternCanvas.getContext("2d");
      patternCtx.fillStyle = gradient;
      patternSvg.render(patternCtx);
      return patternCtx.createPattern(patternCanvas, "no-repeat");
    }
    return gradient;
  }
  inheritStopContainer(stopsContainer) {
    this.attributesToInherit.forEach((attributeToInherit) => {
      if (!this.getAttribute(attributeToInherit).hasValue() && stopsContainer.getAttribute(attributeToInherit).hasValue()) {
        this.getAttribute(attributeToInherit, true).setValue(stopsContainer.getAttribute(attributeToInherit).getValue());
      }
    });
  }
  addParentOpacity(parentOpacityProp, color) {
    if (parentOpacityProp.hasValue()) {
      const colorProp = new Property(this.document, "color", color);
      return colorProp.addOpacity(parentOpacityProp).getColor();
    }
    return color;
  }
  constructor(document2, node2, captureTextNodes) {
    super(document2, node2, captureTextNodes);
    this.attributesToInherit = [
      "gradientUnits"
    ];
    this.stops = [];
    const {stops, children} = this;
    children.forEach((child) => {
      if (child.type === "stop") {
        stops.push(child);
      }
    });
  }
}
class LinearGradientElement extends GradientElement {
  getGradient(ctx, element) {
    const isBoundingBoxUnits = this.getGradientUnits() === "objectBoundingBox";
    const boundingBox = isBoundingBoxUnits ? element.getBoundingBox(ctx) : null;
    if (isBoundingBoxUnits && !boundingBox) {
      return null;
    }
    if (!this.getAttribute("x1").hasValue() && !this.getAttribute("y1").hasValue() && !this.getAttribute("x2").hasValue() && !this.getAttribute("y2").hasValue()) {
      this.getAttribute("x1", true).setValue(0);
      this.getAttribute("y1", true).setValue(0);
      this.getAttribute("x2", true).setValue(1);
      this.getAttribute("y2", true).setValue(0);
    }
    const x1 = isBoundingBoxUnits ? boundingBox.x + boundingBox.width * this.getAttribute("x1").getNumber() : this.getAttribute("x1").getPixels("x");
    const y1 = isBoundingBoxUnits ? boundingBox.y + boundingBox.height * this.getAttribute("y1").getNumber() : this.getAttribute("y1").getPixels("y");
    const x2 = isBoundingBoxUnits ? boundingBox.x + boundingBox.width * this.getAttribute("x2").getNumber() : this.getAttribute("x2").getPixels("x");
    const y2 = isBoundingBoxUnits ? boundingBox.y + boundingBox.height * this.getAttribute("y2").getNumber() : this.getAttribute("y2").getPixels("y");
    if (x1 === x2 && y1 === y2) {
      return null;
    }
    return ctx.createLinearGradient(x1, y1, x2, y2);
  }
  constructor(document2, node2, captureTextNodes) {
    super(document2, node2, captureTextNodes);
    this.type = "linearGradient";
    this.attributesToInherit.push("x1", "y1", "x2", "y2");
  }
}
class RadialGradientElement extends GradientElement {
  getGradient(ctx, element) {
    const isBoundingBoxUnits = this.getGradientUnits() === "objectBoundingBox";
    const boundingBox = element.getBoundingBox(ctx);
    if (isBoundingBoxUnits && !boundingBox) {
      return null;
    }
    if (!this.getAttribute("cx").hasValue()) {
      this.getAttribute("cx", true).setValue("50%");
    }
    if (!this.getAttribute("cy").hasValue()) {
      this.getAttribute("cy", true).setValue("50%");
    }
    if (!this.getAttribute("r").hasValue()) {
      this.getAttribute("r", true).setValue("50%");
    }
    const cx = isBoundingBoxUnits ? boundingBox.x + boundingBox.width * this.getAttribute("cx").getNumber() : this.getAttribute("cx").getPixels("x");
    const cy = isBoundingBoxUnits ? boundingBox.y + boundingBox.height * this.getAttribute("cy").getNumber() : this.getAttribute("cy").getPixels("y");
    let fx = cx;
    let fy = cy;
    if (this.getAttribute("fx").hasValue()) {
      fx = isBoundingBoxUnits ? boundingBox.x + boundingBox.width * this.getAttribute("fx").getNumber() : this.getAttribute("fx").getPixels("x");
    }
    if (this.getAttribute("fy").hasValue()) {
      fy = isBoundingBoxUnits ? boundingBox.y + boundingBox.height * this.getAttribute("fy").getNumber() : this.getAttribute("fy").getPixels("y");
    }
    const r = isBoundingBoxUnits ? (boundingBox.width + boundingBox.height) / 2 * this.getAttribute("r").getNumber() : this.getAttribute("r").getPixels();
    const fr = this.getAttribute("fr").getPixels();
    return ctx.createRadialGradient(fx, fy, fr, cx, cy, r);
  }
  constructor(document2, node2, captureTextNodes) {
    super(document2, node2, captureTextNodes);
    this.type = "radialGradient";
    this.attributesToInherit.push("cx", "cy", "r", "fx", "fy", "fr");
  }
}
class StopElement extends Element {
  constructor(document2, node2, captureTextNodes) {
    super(document2, node2, captureTextNodes);
    this.type = "stop";
    const offset = Math.max(0, Math.min(1, this.getAttribute("offset").getNumber()));
    const stopOpacity = this.getStyle("stop-opacity");
    let stopColor = this.getStyle("stop-color", true);
    if (stopColor.getString() === "") {
      stopColor.setValue("#000");
    }
    if (stopOpacity.hasValue()) {
      stopColor = stopColor.addOpacity(stopOpacity);
    }
    this.offset = offset;
    this.color = stopColor.getColor();
  }
}
class AnimateElement extends Element {
  getProperty() {
    const attributeType = this.getAttribute("attributeType").getString();
    const attributeName = this.getAttribute("attributeName").getString();
    if (attributeType === "CSS") {
      return this.parent.getStyle(attributeName, true);
    }
    return this.parent.getAttribute(attributeName, true);
  }
  calcValue() {
    const {initialUnits} = this;
    const {progress, from, to} = this.getProgress();
    let newValue = from.getNumber() + (to.getNumber() - from.getNumber()) * progress;
    if (initialUnits === "%") {
      newValue *= 100;
    }
    return "".concat(newValue).concat(initialUnits);
  }
  update(delta) {
    const {parent} = this;
    const prop = this.getProperty();
    if (!this.initialValue) {
      this.initialValue = prop.getString();
      this.initialUnits = prop.getUnits();
    }
    if (this.duration > this.maxDuration) {
      const fill = this.getAttribute("fill").getString("remove");
      if (this.getAttribute("repeatCount").getString() === "indefinite" || this.getAttribute("repeatDur").getString() === "indefinite") {
        this.duration = 0;
      } else if (fill === "freeze" && !this.frozen) {
        this.frozen = true;
        if (parent && prop) {
          parent.animationFrozen = true;
          parent.animationFrozenValue = prop.getString();
        }
      } else if (fill === "remove" && !this.removed) {
        this.removed = true;
        if (parent && prop) {
          prop.setValue(parent.animationFrozen ? parent.animationFrozenValue : this.initialValue);
        }
        return true;
      }
      return false;
    }
    this.duration += delta;
    let updated = false;
    if (this.begin < this.duration) {
      let newValue = this.calcValue();
      const typeAttr = this.getAttribute("type");
      if (typeAttr.hasValue()) {
        const type = typeAttr.getString();
        newValue = "".concat(type, "(").concat(newValue, ")");
      }
      prop.setValue(newValue);
      updated = true;
    }
    return updated;
  }
  getProgress() {
    const {document: document2, values} = this;
    let progress = (this.duration - this.begin) / (this.maxDuration - this.begin);
    let from;
    let to;
    if (values.hasValue()) {
      const p = progress * (values.getValue().length - 1);
      const lb = Math.floor(p);
      const ub = Math.ceil(p);
      let value;
      value = values.getValue()[lb];
      from = new Property(document2, "from", value ? parseFloat(value) : 0);
      value = values.getValue()[ub];
      to = new Property(document2, "to", value ? parseFloat(value) : 0);
      progress = (p - lb) / (ub - lb);
    } else {
      from = this.from;
      to = this.to;
    }
    return {
      progress,
      from,
      to
    };
  }
  constructor(document2, node2, captureTextNodes) {
    super(document2, node2, captureTextNodes);
    this.type = "animate";
    this.duration = 0;
    this.initialUnits = "";
    this.removed = false;
    this.frozen = false;
    document2.screen.animations.push(this);
    this.begin = this.getAttribute("begin").getMilliseconds();
    this.maxDuration = this.begin + this.getAttribute("dur").getMilliseconds();
    this.from = this.getAttribute("from");
    this.to = this.getAttribute("to");
    this.values = new Property(document2, "values", null);
    const valuesAttr = this.getAttribute("values");
    if (valuesAttr.hasValue()) {
      this.values.setValue(valuesAttr.getString().split(";"));
    }
  }
}
class AnimateColorElement extends AnimateElement {
  calcValue() {
    const {progress, from, to} = this.getProgress();
    const colorFrom = new RGBColor(from.getColor());
    const colorTo = new RGBColor(to.getColor());
    if (colorFrom.ok && colorTo.ok) {
      const r = colorFrom.r + (colorTo.r - colorFrom.r) * progress;
      const g = colorFrom.g + (colorTo.g - colorFrom.g) * progress;
      const b = colorFrom.b + (colorTo.b - colorFrom.b) * progress;
      return "rgb(".concat(Math.floor(r), ", ").concat(Math.floor(g), ", ").concat(Math.floor(b), ")");
    }
    return this.getAttribute("from").getColor();
  }
  constructor(...args) {
    super(...args);
    this.type = "animateColor";
  }
}
class AnimateTransformElement extends AnimateElement {
  calcValue() {
    const {progress, from: from1, to: to1} = this.getProgress();
    const transformFrom = toNumbers(from1.getString());
    const transformTo = toNumbers(to1.getString());
    const newValue = transformFrom.map((from, i) => {
      const to = transformTo[i];
      return from + (to - from) * progress;
    }).join(" ");
    return newValue;
  }
  constructor(...args) {
    super(...args);
    this.type = "animateTransform";
  }
}
class FontFaceElement extends Element {
  constructor(document2, node2, captureTextNodes) {
    super(document2, node2, captureTextNodes);
    this.type = "font-face";
    this.ascent = this.getAttribute("ascent").getNumber();
    this.descent = this.getAttribute("descent").getNumber();
    this.unitsPerEm = this.getAttribute("units-per-em").getNumber();
  }
}
class GlyphElement extends PathElement {
  constructor(document2, node2, captureTextNodes) {
    super(document2, node2, captureTextNodes);
    this.type = "glyph";
    this.horizAdvX = this.getAttribute("horiz-adv-x").getNumber();
    this.unicode = this.getAttribute("unicode").getString();
    this.arabicForm = this.getAttribute("arabic-form").getString();
  }
}
class MissingGlyphElement extends GlyphElement {
  constructor(...args) {
    super(...args);
    this.type = "missing-glyph";
    this.horizAdvX = 0;
  }
}
class FontElement extends Element {
  render() {
  }
  constructor(document2, node2, captureTextNodes) {
    super(document2, node2, captureTextNodes);
    this.type = "font";
    this.isArabic = false;
    this.glyphs = {};
    this.arabicGlyphs = {};
    this.isRTL = false;
    this.horizAdvX = this.getAttribute("horiz-adv-x").getNumber();
    const {definitions} = document2;
    const {children} = this;
    for (const child of children) {
      if (child instanceof FontFaceElement) {
        this.fontFace = child;
        const fontFamilyStyle = child.getStyle("font-family");
        if (fontFamilyStyle.hasValue()) {
          definitions[fontFamilyStyle.getString()] = this;
        }
      } else if (child instanceof MissingGlyphElement) {
        this.missingGlyph = child;
      } else if (child instanceof GlyphElement) {
        if (child.arabicForm) {
          this.isRTL = true;
          this.isArabic = true;
          const arabicGlyph = this.arabicGlyphs[child.unicode];
          if (typeof arabicGlyph === "undefined") {
            this.arabicGlyphs[child.unicode] = {
              [child.arabicForm]: child
            };
          } else {
            arabicGlyph[child.arabicForm] = child;
          }
        } else {
          this.glyphs[child.unicode] = child;
        }
      }
    }
  }
}
class TRefElement extends TextElement {
  getText() {
    const element = this.getHrefAttribute().getDefinition();
    if (element) {
      const firstChild = element.children[0];
      if (firstChild) {
        return firstChild.getText();
      }
    }
    return "";
  }
  constructor(...args) {
    super(...args);
    this.type = "tref";
  }
}
class AElement extends TextElement {
  getText() {
    return this.text;
  }
  renderChildren(ctx) {
    if (this.hasText) {
      super.renderChildren(ctx);
      const {document: document2, x, y} = this;
      const {mouse} = document2.screen;
      const fontSize = new Property(document2, "fontSize", Font.parse(document2.ctx.font).fontSize);
      if (mouse.isWorking()) {
        mouse.checkBoundingBox(this, new BoundingBox(x, y - fontSize.getPixels("y"), x + this.measureText(ctx), y));
      }
    } else if (this.children.length > 0) {
      const g = new GElement(this.document);
      g.children = this.children;
      g.parent = this;
      g.render(ctx);
    }
  }
  onClick() {
    const {window: window2} = this.document;
    if (window2) {
      window2.open(this.getHrefAttribute().getString());
    }
  }
  onMouseMove() {
    const ctx = this.document.ctx;
    ctx.canvas.style.cursor = "pointer";
  }
  constructor(document2, node1, captureTextNodes) {
    super(document2, node1, captureTextNodes);
    this.type = "a";
    const {childNodes} = node1;
    const firstChild = childNodes[0];
    const hasText = childNodes.length > 0 && Array.from(childNodes).every((node2) => node2.nodeType === 3);
    this.hasText = hasText;
    this.text = hasText ? this.getTextFromNode(firstChild) : "";
  }
}
class TextPathElement extends TextElement {
  getText() {
    return this.text;
  }
  path(ctx) {
    const {dataArray} = this;
    if (ctx) {
      ctx.beginPath();
    }
    dataArray.forEach((param) => {
      let {type, points} = param;
      switch (type) {
        case PathParser.LINE_TO:
          if (ctx) {
            ctx.lineTo(points[0], points[1]);
          }
          break;
        case PathParser.MOVE_TO:
          if (ctx) {
            ctx.moveTo(points[0], points[1]);
          }
          break;
        case PathParser.CURVE_TO:
          if (ctx) {
            ctx.bezierCurveTo(points[0], points[1], points[2], points[3], points[4], points[5]);
          }
          break;
        case PathParser.QUAD_TO:
          if (ctx) {
            ctx.quadraticCurveTo(points[0], points[1], points[2], points[3]);
          }
          break;
        case PathParser.ARC: {
          const [cx, cy, rx, ry, theta, dTheta, psi, fs] = points;
          const r = rx > ry ? rx : ry;
          const scaleX = rx > ry ? 1 : rx / ry;
          const scaleY = rx > ry ? ry / rx : 1;
          if (ctx) {
            ctx.translate(cx, cy);
            ctx.rotate(psi);
            ctx.scale(scaleX, scaleY);
            ctx.arc(0, 0, r, theta, theta + dTheta, Boolean(1 - fs));
            ctx.scale(1 / scaleX, 1 / scaleY);
            ctx.rotate(-psi);
            ctx.translate(-cx, -cy);
          }
          break;
        }
        case PathParser.CLOSE_PATH:
          if (ctx) {
            ctx.closePath();
          }
          break;
      }
    });
  }
  renderChildren(ctx) {
    this.setTextData(ctx);
    ctx.save();
    const textDecoration = this.parent.getStyle("text-decoration").getString();
    const fontSize = this.getFontSize();
    const {glyphInfo} = this;
    const fill = ctx.fillStyle;
    if (textDecoration === "underline") {
      ctx.beginPath();
    }
    glyphInfo.forEach((glyph, i) => {
      const {p0, p1, rotation, text: partialText} = glyph;
      ctx.save();
      ctx.translate(p0.x, p0.y);
      ctx.rotate(rotation);
      if (ctx.fillStyle) {
        ctx.fillText(partialText, 0, 0);
      }
      if (ctx.strokeStyle) {
        ctx.strokeText(partialText, 0, 0);
      }
      ctx.restore();
      if (textDecoration === "underline") {
        if (i === 0) {
          ctx.moveTo(p0.x, p0.y + fontSize / 8);
        }
        ctx.lineTo(p1.x, p1.y + fontSize / 5);
      }
    });
    if (textDecoration === "underline") {
      ctx.lineWidth = fontSize / 20;
      ctx.strokeStyle = fill;
      ctx.stroke();
      ctx.closePath();
    }
    ctx.restore();
  }
  getLetterSpacingAt() {
    let idx = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 0;
    return this.letterSpacingCache[idx] || 0;
  }
  findSegmentToFitChar(ctx, anchor, textFullWidth, fullPathWidth, spacesNumber, inputOffset, dy, c2, charI) {
    let offset = inputOffset;
    let glyphWidth = this.measureText(ctx, c2);
    if (c2 === " " && anchor === "justify" && textFullWidth < fullPathWidth) {
      glyphWidth += (fullPathWidth - textFullWidth) / spacesNumber;
    }
    if (charI > -1) {
      offset += this.getLetterSpacingAt(charI);
    }
    const splineStep = this.textHeight / 20;
    const p0 = this.getEquidistantPointOnPath(offset, splineStep, 0);
    const p1 = this.getEquidistantPointOnPath(offset + glyphWidth, splineStep, 0);
    const segment = {
      p0,
      p1
    };
    const rotation = p0 && p1 ? Math.atan2(p1.y - p0.y, p1.x - p0.x) : 0;
    if (dy) {
      const dyX = Math.cos(Math.PI / 2 + rotation) * dy;
      const dyY = Math.cos(-rotation) * dy;
      segment.p0 = {
        ...p0,
        x: p0.x + dyX,
        y: p0.y + dyY
      };
      segment.p1 = {
        ...p1,
        x: p1.x + dyX,
        y: p1.y + dyY
      };
    }
    offset += glyphWidth;
    return {
      offset,
      segment,
      rotation
    };
  }
  measureText(ctx, text) {
    const {measuresCache} = this;
    const targetText = text || this.getText();
    if (measuresCache.has(targetText)) {
      return measuresCache.get(targetText);
    }
    const measure = this.measureTargetText(ctx, targetText);
    measuresCache.set(targetText, measure);
    return measure;
  }
  setTextData(ctx) {
    if (this.glyphInfo) {
      return;
    }
    const renderText = this.getText();
    const chars = renderText.split("");
    const spacesNumber = renderText.split(" ").length - 1;
    const dx = this.parent.getAttribute("dx").split().map((_) => _.getPixels("x"));
    const dy = this.parent.getAttribute("dy").getPixels("y");
    const anchor = this.parent.getStyle("text-anchor").getString("start");
    const thisSpacing = this.getStyle("letter-spacing");
    const parentSpacing = this.parent.getStyle("letter-spacing");
    let letterSpacing = 0;
    if (!thisSpacing.hasValue() || thisSpacing.getValue() === "inherit") {
      letterSpacing = parentSpacing.getPixels();
    } else if (thisSpacing.hasValue()) {
      if (thisSpacing.getValue() !== "initial" && thisSpacing.getValue() !== "unset") {
        letterSpacing = thisSpacing.getPixels();
      }
    }
    const letterSpacingCache = [];
    const textLen = renderText.length;
    this.letterSpacingCache = letterSpacingCache;
    for (let i1 = 0; i1 < textLen; i1++) {
      letterSpacingCache.push(typeof dx[i1] !== "undefined" ? dx[i1] : letterSpacing);
    }
    const dxSum = letterSpacingCache.reduce((acc, cur, i) => i === 0 ? 0 : acc + cur || 0, 0);
    const textWidth = this.measureText(ctx);
    const textFullWidth = Math.max(textWidth + dxSum, 0);
    this.textWidth = textWidth;
    this.textHeight = this.getFontSize();
    this.glyphInfo = [];
    const fullPathWidth = this.getPathLength();
    const startOffset = this.getStyle("startOffset").getNumber(0) * fullPathWidth;
    let offset = 0;
    if (anchor === "middle" || anchor === "center") {
      offset = -textFullWidth / 2;
    }
    if (anchor === "end" || anchor === "right") {
      offset = -textFullWidth;
    }
    offset += startOffset;
    chars.forEach((char, i) => {
      const {offset: nextOffset, segment, rotation} = this.findSegmentToFitChar(ctx, anchor, textFullWidth, fullPathWidth, spacesNumber, offset, dy, char, i);
      offset = nextOffset;
      if (!segment.p0 || !segment.p1) {
        return;
      }
      this.glyphInfo.push({
        text: chars[i],
        p0: segment.p0,
        p1: segment.p1,
        rotation
      });
    });
  }
  parsePathData(path) {
    this.pathLength = -1;
    if (!path) {
      return [];
    }
    const pathCommands = [];
    const {pathParser} = path;
    pathParser.reset();
    while (!pathParser.isEnd()) {
      const {current} = pathParser;
      const startX = current ? current.x : 0;
      const startY = current ? current.y : 0;
      const command = pathParser.next();
      let nextCommandType = command.type;
      let points = [];
      switch (command.type) {
        case PathParser.MOVE_TO:
          this.pathM(pathParser, points);
          break;
        case PathParser.LINE_TO:
          nextCommandType = this.pathL(pathParser, points);
          break;
        case PathParser.HORIZ_LINE_TO:
          nextCommandType = this.pathH(pathParser, points);
          break;
        case PathParser.VERT_LINE_TO:
          nextCommandType = this.pathV(pathParser, points);
          break;
        case PathParser.CURVE_TO:
          this.pathC(pathParser, points);
          break;
        case PathParser.SMOOTH_CURVE_TO:
          nextCommandType = this.pathS(pathParser, points);
          break;
        case PathParser.QUAD_TO:
          this.pathQ(pathParser, points);
          break;
        case PathParser.SMOOTH_QUAD_TO:
          nextCommandType = this.pathT(pathParser, points);
          break;
        case PathParser.ARC:
          points = this.pathA(pathParser);
          break;
        case PathParser.CLOSE_PATH:
          PathElement.pathZ(pathParser);
          break;
      }
      if (command.type !== PathParser.CLOSE_PATH) {
        pathCommands.push({
          type: nextCommandType,
          points,
          start: {
            x: startX,
            y: startY
          },
          pathLength: this.calcLength(startX, startY, nextCommandType, points)
        });
      } else {
        pathCommands.push({
          type: PathParser.CLOSE_PATH,
          points: [],
          pathLength: 0
        });
      }
    }
    return pathCommands;
  }
  pathM(pathParser, points) {
    const {x, y} = PathElement.pathM(pathParser).point;
    points.push(x, y);
  }
  pathL(pathParser, points) {
    const {x, y} = PathElement.pathL(pathParser).point;
    points.push(x, y);
    return PathParser.LINE_TO;
  }
  pathH(pathParser, points) {
    const {x, y} = PathElement.pathH(pathParser).point;
    points.push(x, y);
    return PathParser.LINE_TO;
  }
  pathV(pathParser, points) {
    const {x, y} = PathElement.pathV(pathParser).point;
    points.push(x, y);
    return PathParser.LINE_TO;
  }
  pathC(pathParser, points) {
    const {point, controlPoint, currentPoint} = PathElement.pathC(pathParser);
    points.push(point.x, point.y, controlPoint.x, controlPoint.y, currentPoint.x, currentPoint.y);
  }
  pathS(pathParser, points) {
    const {point, controlPoint, currentPoint} = PathElement.pathS(pathParser);
    points.push(point.x, point.y, controlPoint.x, controlPoint.y, currentPoint.x, currentPoint.y);
    return PathParser.CURVE_TO;
  }
  pathQ(pathParser, points) {
    const {controlPoint, currentPoint} = PathElement.pathQ(pathParser);
    points.push(controlPoint.x, controlPoint.y, currentPoint.x, currentPoint.y);
  }
  pathT(pathParser, points) {
    const {controlPoint, currentPoint} = PathElement.pathT(pathParser);
    points.push(controlPoint.x, controlPoint.y, currentPoint.x, currentPoint.y);
    return PathParser.QUAD_TO;
  }
  pathA(pathParser) {
    let {rX, rY, sweepFlag, xAxisRotation, centp, a1, ad} = PathElement.pathA(pathParser);
    if (sweepFlag === 0 && ad > 0) {
      ad -= 2 * Math.PI;
    }
    if (sweepFlag === 1 && ad < 0) {
      ad += 2 * Math.PI;
    }
    return [
      centp.x,
      centp.y,
      rX,
      rY,
      a1,
      ad,
      xAxisRotation,
      sweepFlag
    ];
  }
  calcLength(x, y, commandType, points) {
    let len = 0;
    let p1 = null;
    let p2 = null;
    let t = 0;
    switch (commandType) {
      case PathParser.LINE_TO:
        return this.getLineLength(x, y, points[0], points[1]);
      case PathParser.CURVE_TO:
        len = 0;
        p1 = this.getPointOnCubicBezier(0, x, y, points[0], points[1], points[2], points[3], points[4], points[5]);
        for (t = 0.01; t <= 1; t += 0.01) {
          p2 = this.getPointOnCubicBezier(t, x, y, points[0], points[1], points[2], points[3], points[4], points[5]);
          len += this.getLineLength(p1.x, p1.y, p2.x, p2.y);
          p1 = p2;
        }
        return len;
      case PathParser.QUAD_TO:
        len = 0;
        p1 = this.getPointOnQuadraticBezier(0, x, y, points[0], points[1], points[2], points[3]);
        for (t = 0.01; t <= 1; t += 0.01) {
          p2 = this.getPointOnQuadraticBezier(t, x, y, points[0], points[1], points[2], points[3]);
          len += this.getLineLength(p1.x, p1.y, p2.x, p2.y);
          p1 = p2;
        }
        return len;
      case PathParser.ARC: {
        len = 0;
        const start = points[4];
        const dTheta = points[5];
        const end = points[4] + dTheta;
        let inc = Math.PI / 180;
        if (Math.abs(start - end) < inc) {
          inc = Math.abs(start - end);
        }
        p1 = this.getPointOnEllipticalArc(points[0], points[1], points[2], points[3], start, 0);
        if (dTheta < 0) {
          for (t = start - inc; t > end; t -= inc) {
            p2 = this.getPointOnEllipticalArc(points[0], points[1], points[2], points[3], t, 0);
            len += this.getLineLength(p1.x, p1.y, p2.x, p2.y);
            p1 = p2;
          }
        } else {
          for (t = start + inc; t < end; t += inc) {
            p2 = this.getPointOnEllipticalArc(points[0], points[1], points[2], points[3], t, 0);
            len += this.getLineLength(p1.x, p1.y, p2.x, p2.y);
            p1 = p2;
          }
        }
        p2 = this.getPointOnEllipticalArc(points[0], points[1], points[2], points[3], end, 0);
        len += this.getLineLength(p1.x, p1.y, p2.x, p2.y);
        return len;
      }
    }
    return 0;
  }
  getPointOnLine(dist, p1x, p1y, p2x, p2y) {
    let fromX = arguments.length > 5 && arguments[5] !== void 0 ? arguments[5] : p1x, fromY = arguments.length > 6 && arguments[6] !== void 0 ? arguments[6] : p1y;
    const m2 = (p2y - p1y) / (p2x - p1x + PSEUDO_ZERO);
    let run = Math.sqrt(dist * dist / (1 + m2 * m2));
    if (p2x < p1x) {
      run *= -1;
    }
    let rise = m2 * run;
    let pt = null;
    if (p2x === p1x) {
      pt = {
        x: fromX,
        y: fromY + rise
      };
    } else if ((fromY - p1y) / (fromX - p1x + PSEUDO_ZERO) === m2) {
      pt = {
        x: fromX + run,
        y: fromY + rise
      };
    } else {
      let ix = 0;
      let iy = 0;
      const len = this.getLineLength(p1x, p1y, p2x, p2y);
      if (len < PSEUDO_ZERO) {
        return null;
      }
      let u = (fromX - p1x) * (p2x - p1x) + (fromY - p1y) * (p2y - p1y);
      u /= len * len;
      ix = p1x + u * (p2x - p1x);
      iy = p1y + u * (p2y - p1y);
      const pRise = this.getLineLength(fromX, fromY, ix, iy);
      const pRun = Math.sqrt(dist * dist - pRise * pRise);
      run = Math.sqrt(pRun * pRun / (1 + m2 * m2));
      if (p2x < p1x) {
        run *= -1;
      }
      rise = m2 * run;
      pt = {
        x: ix + run,
        y: iy + rise
      };
    }
    return pt;
  }
  getPointOnPath(distance) {
    const fullLen = this.getPathLength();
    let cumulativePathLength = 0;
    let p = null;
    if (distance < -5e-5 || distance - 5e-5 > fullLen) {
      return null;
    }
    const {dataArray} = this;
    for (const command of dataArray) {
      if (command && (command.pathLength < 5e-5 || cumulativePathLength + command.pathLength + 5e-5 < distance)) {
        cumulativePathLength += command.pathLength;
        continue;
      }
      const delta = distance - cumulativePathLength;
      let currentT = 0;
      switch (command.type) {
        case PathParser.LINE_TO:
          p = this.getPointOnLine(delta, command.start.x, command.start.y, command.points[0], command.points[1], command.start.x, command.start.y);
          break;
        case PathParser.ARC: {
          const start = command.points[4];
          const dTheta = command.points[5];
          const end = command.points[4] + dTheta;
          currentT = start + delta / command.pathLength * dTheta;
          if (dTheta < 0 && currentT < end || dTheta >= 0 && currentT > end) {
            break;
          }
          p = this.getPointOnEllipticalArc(command.points[0], command.points[1], command.points[2], command.points[3], currentT, command.points[6]);
          break;
        }
        case PathParser.CURVE_TO:
          currentT = delta / command.pathLength;
          if (currentT > 1) {
            currentT = 1;
          }
          p = this.getPointOnCubicBezier(currentT, command.start.x, command.start.y, command.points[0], command.points[1], command.points[2], command.points[3], command.points[4], command.points[5]);
          break;
        case PathParser.QUAD_TO:
          currentT = delta / command.pathLength;
          if (currentT > 1) {
            currentT = 1;
          }
          p = this.getPointOnQuadraticBezier(currentT, command.start.x, command.start.y, command.points[0], command.points[1], command.points[2], command.points[3]);
          break;
      }
      if (p) {
        return p;
      }
      break;
    }
    return null;
  }
  getLineLength(x1, y1, x2, y2) {
    return Math.sqrt((x2 - x1) * (x2 - x1) + (y2 - y1) * (y2 - y1));
  }
  getPathLength() {
    if (this.pathLength === -1) {
      this.pathLength = this.dataArray.reduce((length, command) => command.pathLength > 0 ? length + command.pathLength : length, 0);
    }
    return this.pathLength;
  }
  getPointOnCubicBezier(pct, p1x, p1y, p2x, p2y, p3x, p3y, p4x, p4y) {
    const x = p4x * CB1(pct) + p3x * CB2(pct) + p2x * CB3(pct) + p1x * CB4(pct);
    const y = p4y * CB1(pct) + p3y * CB2(pct) + p2y * CB3(pct) + p1y * CB4(pct);
    return {
      x,
      y
    };
  }
  getPointOnQuadraticBezier(pct, p1x, p1y, p2x, p2y, p3x, p3y) {
    const x = p3x * QB1(pct) + p2x * QB2(pct) + p1x * QB3(pct);
    const y = p3y * QB1(pct) + p2y * QB2(pct) + p1y * QB3(pct);
    return {
      x,
      y
    };
  }
  getPointOnEllipticalArc(cx, cy, rx, ry, theta, psi) {
    const cosPsi = Math.cos(psi);
    const sinPsi = Math.sin(psi);
    const pt = {
      x: rx * Math.cos(theta),
      y: ry * Math.sin(theta)
    };
    return {
      x: cx + (pt.x * cosPsi - pt.y * sinPsi),
      y: cy + (pt.x * sinPsi + pt.y * cosPsi)
    };
  }
  buildEquidistantCache(inputStep, inputPrecision) {
    const fullLen = this.getPathLength();
    const precision = inputPrecision || 0.25;
    const step = inputStep || fullLen / 100;
    if (!this.equidistantCache || this.equidistantCache.step !== step || this.equidistantCache.precision !== precision) {
      this.equidistantCache = {
        step,
        precision,
        points: []
      };
      let s = 0;
      for (let l = 0; l <= fullLen; l += precision) {
        const p0 = this.getPointOnPath(l);
        const p1 = this.getPointOnPath(l + precision);
        if (!p0 || !p1) {
          continue;
        }
        s += this.getLineLength(p0.x, p0.y, p1.x, p1.y);
        if (s >= step) {
          this.equidistantCache.points.push({
            x: p0.x,
            y: p0.y,
            distance: l
          });
          s -= step;
        }
      }
    }
  }
  getEquidistantPointOnPath(targetDistance, step, precision) {
    this.buildEquidistantCache(step, precision);
    if (targetDistance < 0 || targetDistance - this.getPathLength() > 5e-5) {
      return null;
    }
    const idx = Math.round(targetDistance / this.getPathLength() * (this.equidistantCache.points.length - 1));
    return this.equidistantCache.points[idx] || null;
  }
  constructor(document2, node2, captureTextNodes) {
    super(document2, node2, captureTextNodes);
    this.type = "textPath";
    this.textWidth = 0;
    this.textHeight = 0;
    this.pathLength = -1;
    this.glyphInfo = null;
    this.letterSpacingCache = [];
    this.measuresCache = new Map([
      [
        "",
        0
      ]
    ]);
    const pathElement = this.getHrefAttribute().getDefinition();
    this.text = this.getTextFromNode();
    this.dataArray = this.parsePathData(pathElement);
  }
}
const dataUriRegex = /^\s*data:(([^/,;]+\/[^/,;]+)(?:;([^,;=]+=[^,;=]+))?)?(?:;(base64))?,(.*)$/i;
class ImageElement extends RenderedElement {
  async loadImage(href) {
    try {
      const image = await this.document.createImage(href);
      this.image = image;
    } catch (err) {
      console.error('Error while loading image "'.concat(href, '":'), err);
    }
    this.loaded = true;
  }
  async loadSvg(href) {
    const match = dataUriRegex.exec(href);
    if (match) {
      const data = match[5];
      if (data) {
        if (match[4] === "base64") {
          this.image = atob(data);
        } else {
          this.image = decodeURIComponent(data);
        }
      }
    } else {
      try {
        const response = await this.document.fetch(href);
        const svg = await response.text();
        this.image = svg;
      } catch (err) {
        console.error('Error while loading image "'.concat(href, '":'), err);
      }
    }
    this.loaded = true;
  }
  renderChildren(ctx) {
    const {document: document2, image, loaded} = this;
    const x = this.getAttribute("x").getPixels("x");
    const y = this.getAttribute("y").getPixels("y");
    const width = this.getStyle("width").getPixels("x");
    const height = this.getStyle("height").getPixels("y");
    if (!loaded || !image || !width || !height) {
      return;
    }
    ctx.save();
    ctx.translate(x, y);
    if (typeof image === "string") {
      const subDocument = document2.canvg.forkString(ctx, image, {
        ignoreMouse: true,
        ignoreAnimation: true,
        ignoreDimensions: true,
        ignoreClear: true,
        offsetX: 0,
        offsetY: 0,
        scaleWidth: width,
        scaleHeight: height
      });
      const {documentElement} = subDocument.document;
      if (documentElement) {
        documentElement.parent = this;
      }
      void subDocument.render();
    } else {
      document2.setViewBox({
        ctx,
        aspectRatio: this.getAttribute("preserveAspectRatio").getString(),
        width,
        desiredWidth: image.width,
        height,
        desiredHeight: image.height
      });
      if (this.loaded) {
        if (!("complete" in image) || image.complete) {
          ctx.drawImage(image, 0, 0);
        }
      }
    }
    ctx.restore();
  }
  getBoundingBox() {
    const x = this.getAttribute("x").getPixels("x");
    const y = this.getAttribute("y").getPixels("y");
    const width = this.getStyle("width").getPixels("x");
    const height = this.getStyle("height").getPixels("y");
    return new BoundingBox(x, y, x + width, y + height);
  }
  constructor(document2, node2, captureTextNodes) {
    super(document2, node2, captureTextNodes);
    this.type = "image";
    this.loaded = false;
    const href = this.getHrefAttribute().getString();
    if (!href) {
      return;
    }
    const isSvg = href.endsWith(".svg") || /^\s*data:image\/svg\+xml/i.test(href);
    document2.images.push(this);
    if (!isSvg) {
      void this.loadImage(href);
    } else {
      void this.loadSvg(href);
    }
  }
}
class SymbolElement extends RenderedElement {
  render(_) {
  }
  constructor(...args) {
    super(...args);
    this.type = "symbol";
  }
}
class SVGFontLoader {
  async load(fontFamily, url) {
    try {
      const {document: document2} = this;
      const svgDocument = await document2.canvg.parser.load(url);
      const fonts = svgDocument.getElementsByTagName("font");
      Array.from(fonts).forEach((fontNode) => {
        const font = document2.createElement(fontNode);
        document2.definitions[fontFamily] = font;
      });
    } catch (err) {
      console.error('Error while loading font "'.concat(url, '":'), err);
    }
    this.loaded = true;
  }
  constructor(document2) {
    this.document = document2;
    this.loaded = false;
    document2.fonts.push(this);
  }
}
class StyleElement extends Element {
  constructor(document2, node2, captureTextNodes) {
    super(document2, node2, captureTextNodes);
    this.type = "style";
    const css = compressSpaces(Array.from(node2.childNodes).map((_) => _.textContent).join("").replace(/(\/\*([^*]|[\r\n]|(\*+([^*/]|[\r\n])))*\*+\/)|(^[\s]*\/\/.*)/gm, "").replace(/@import.*;/g, ""));
    const cssDefs = css.split("}");
    cssDefs.forEach((_1) => {
      const def = _1.trim();
      if (!def) {
        return;
      }
      const cssParts = def.split("{");
      const cssClasses = cssParts[0].split(",");
      const cssProps = cssParts[1].split(";");
      cssClasses.forEach((_) => {
        const cssClass = _.trim();
        if (!cssClass) {
          return;
        }
        const props = document2.styles[cssClass] || {};
        cssProps.forEach((cssProp) => {
          const prop = cssProp.indexOf(":");
          const name = cssProp.substr(0, prop).trim();
          const value = cssProp.substr(prop + 1, cssProp.length - prop).trim();
          if (name && value) {
            props[name] = new Property(document2, name, value);
          }
        });
        document2.styles[cssClass] = props;
        document2.stylesSpecificity[cssClass] = getSelectorSpecificity(cssClass);
        if (cssClass === "@font-face") {
          const fontFamily = props["font-family"].getString().replace(/"|'/g, "");
          const srcs = props.src.getString().split(",");
          srcs.forEach((src) => {
            if (src.indexOf('format("svg")') > 0) {
              const url = parseExternalUrl(src);
              if (url) {
                void new SVGFontLoader(document2).load(fontFamily, url);
              }
            }
          });
        }
      });
    });
  }
}
StyleElement.parseExternalUrl = parseExternalUrl;
class UseElement extends RenderedElement {
  setContext(ctx) {
    super.setContext(ctx);
    const xAttr = this.getAttribute("x");
    const yAttr = this.getAttribute("y");
    if (xAttr.hasValue()) {
      ctx.translate(xAttr.getPixels("x"), 0);
    }
    if (yAttr.hasValue()) {
      ctx.translate(0, yAttr.getPixels("y"));
    }
  }
  path(ctx) {
    const {element} = this;
    if (element) {
      element.path(ctx);
    }
  }
  renderChildren(ctx) {
    const {document: document2, element} = this;
    if (element) {
      let tempSvg = element;
      if (element.type === "symbol") {
        tempSvg = new SVGElement(document2);
        tempSvg.attributes.viewBox = new Property(document2, "viewBox", element.getAttribute("viewBox").getString());
        tempSvg.attributes.preserveAspectRatio = new Property(document2, "preserveAspectRatio", element.getAttribute("preserveAspectRatio").getString());
        tempSvg.attributes.overflow = new Property(document2, "overflow", element.getAttribute("overflow").getString());
        tempSvg.children = element.children;
        element.styles.opacity = new Property(document2, "opacity", this.calculateOpacity());
      }
      if (tempSvg.type === "svg") {
        const widthStyle = this.getStyle("width", false, true);
        const heightStyle = this.getStyle("height", false, true);
        if (widthStyle.hasValue()) {
          tempSvg.attributes.width = new Property(document2, "width", widthStyle.getString());
        }
        if (heightStyle.hasValue()) {
          tempSvg.attributes.height = new Property(document2, "height", heightStyle.getString());
        }
      }
      const oldParent = tempSvg.parent;
      tempSvg.parent = this;
      tempSvg.render(ctx);
      tempSvg.parent = oldParent;
    }
  }
  getBoundingBox(ctx) {
    const {element} = this;
    if (element) {
      return element.getBoundingBox(ctx);
    }
    return null;
  }
  elementTransform() {
    const {document: document2, element} = this;
    if (!element) {
      return null;
    }
    return Transform.fromElement(document2, element);
  }
  get element() {
    if (!this.cachedElement) {
      this.cachedElement = this.getHrefAttribute().getDefinition();
    }
    return this.cachedElement;
  }
  constructor(...args) {
    super(...args);
    this.type = "use";
  }
}
function imGet(img, x, y, width, _height, rgba) {
  return img[y * width * 4 + x * 4 + rgba];
}
function imSet(img, x, y, width, _height, rgba, val) {
  img[y * width * 4 + x * 4 + rgba] = val;
}
function m(matrix, i, v) {
  const mi = matrix[i];
  return mi * v;
}
function c(a, m1, m2, m3) {
  return m1 + Math.cos(a) * m2 + Math.sin(a) * m3;
}
class FeColorMatrixElement extends Element {
  apply(ctx, _x, _y, width, height) {
    const {includeOpacity, matrix} = this;
    const srcData = ctx.getImageData(0, 0, width, height);
    for (let y = 0; y < height; y++) {
      for (let x = 0; x < width; x++) {
        const r = imGet(srcData.data, x, y, width, height, 0);
        const g = imGet(srcData.data, x, y, width, height, 1);
        const b = imGet(srcData.data, x, y, width, height, 2);
        const a = imGet(srcData.data, x, y, width, height, 3);
        let nr = m(matrix, 0, r) + m(matrix, 1, g) + m(matrix, 2, b) + m(matrix, 3, a) + m(matrix, 4, 1);
        let ng = m(matrix, 5, r) + m(matrix, 6, g) + m(matrix, 7, b) + m(matrix, 8, a) + m(matrix, 9, 1);
        let nb = m(matrix, 10, r) + m(matrix, 11, g) + m(matrix, 12, b) + m(matrix, 13, a) + m(matrix, 14, 1);
        let na = m(matrix, 15, r) + m(matrix, 16, g) + m(matrix, 17, b) + m(matrix, 18, a) + m(matrix, 19, 1);
        if (includeOpacity) {
          nr = 0;
          ng = 0;
          nb = 0;
          na *= a / 255;
        }
        imSet(srcData.data, x, y, width, height, 0, nr);
        imSet(srcData.data, x, y, width, height, 1, ng);
        imSet(srcData.data, x, y, width, height, 2, nb);
        imSet(srcData.data, x, y, width, height, 3, na);
      }
    }
    ctx.clearRect(0, 0, width, height);
    ctx.putImageData(srcData, 0, 0);
  }
  constructor(document2, node2, captureTextNodes) {
    super(document2, node2, captureTextNodes);
    this.type = "feColorMatrix";
    let matrix = toNumbers(this.getAttribute("values").getString());
    switch (this.getAttribute("type").getString("matrix")) {
      case "saturate": {
        const s = matrix[0];
        matrix = [
          0.213 + 0.787 * s,
          0.715 - 0.715 * s,
          0.072 - 0.072 * s,
          0,
          0,
          0.213 - 0.213 * s,
          0.715 + 0.285 * s,
          0.072 - 0.072 * s,
          0,
          0,
          0.213 - 0.213 * s,
          0.715 - 0.715 * s,
          0.072 + 0.928 * s,
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          1
        ];
        break;
      }
      case "hueRotate": {
        const a = matrix[0] * Math.PI / 180;
        matrix = [
          c(a, 0.213, 0.787, -0.213),
          c(a, 0.715, -0.715, -0.715),
          c(a, 0.072, -0.072, 0.928),
          0,
          0,
          c(a, 0.213, -0.213, 0.143),
          c(a, 0.715, 0.285, 0.14),
          c(a, 0.072, -0.072, -0.283),
          0,
          0,
          c(a, 0.213, -0.213, -0.787),
          c(a, 0.715, -0.715, 0.715),
          c(a, 0.072, 0.928, 0.072),
          0,
          0,
          0,
          0,
          0,
          1,
          0,
          0,
          0,
          0,
          0,
          1
        ];
        break;
      }
      case "luminanceToAlpha":
        matrix = [
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0,
          0.2125,
          0.7154,
          0.0721,
          0,
          0,
          0,
          0,
          0,
          0,
          1
        ];
        break;
    }
    this.matrix = matrix;
    this.includeOpacity = this.getAttribute("includeOpacity").hasValue();
  }
}
class MaskElement extends Element {
  apply(ctx, element) {
    const {document: document2} = this;
    let x = this.getAttribute("x").getPixels("x");
    let y = this.getAttribute("y").getPixels("y");
    let width = this.getStyle("width").getPixels("x");
    let height = this.getStyle("height").getPixels("y");
    if (!width && !height) {
      const boundingBox = new BoundingBox();
      this.children.forEach((child) => {
        boundingBox.addBoundingBox(child.getBoundingBox(ctx));
      });
      x = Math.floor(boundingBox.x1);
      y = Math.floor(boundingBox.y1);
      width = Math.floor(boundingBox.width);
      height = Math.floor(boundingBox.height);
    }
    const ignoredStyles = this.removeStyles(element, MaskElement.ignoreStyles);
    const maskCanvas = document2.createCanvas(x + width, y + height);
    const maskCtx = maskCanvas.getContext("2d");
    document2.screen.setDefaults(maskCtx);
    this.renderChildren(maskCtx);
    new FeColorMatrixElement(document2, {
      nodeType: 1,
      childNodes: [],
      attributes: [
        {
          nodeName: "type",
          value: "luminanceToAlpha"
        },
        {
          nodeName: "includeOpacity",
          value: "true"
        }
      ]
    }).apply(maskCtx, 0, 0, x + width, y + height);
    const tmpCanvas = document2.createCanvas(x + width, y + height);
    const tmpCtx = tmpCanvas.getContext("2d");
    document2.screen.setDefaults(tmpCtx);
    element.render(tmpCtx);
    tmpCtx.globalCompositeOperation = "destination-in";
    tmpCtx.fillStyle = maskCtx.createPattern(maskCanvas, "no-repeat");
    tmpCtx.fillRect(0, 0, x + width, y + height);
    ctx.fillStyle = tmpCtx.createPattern(tmpCanvas, "no-repeat");
    ctx.fillRect(0, 0, x + width, y + height);
    this.restoreStyles(element, ignoredStyles);
  }
  render(_) {
  }
  constructor(...args) {
    super(...args);
    this.type = "mask";
  }
}
MaskElement.ignoreStyles = [
  "mask",
  "transform",
  "clip-path"
];
const noop$1 = () => {
};
class ClipPathElement extends Element {
  apply(ctx) {
    const {document: document2} = this;
    const contextProto = Reflect.getPrototypeOf(ctx);
    const {beginPath, closePath} = ctx;
    if (contextProto) {
      contextProto.beginPath = noop$1;
      contextProto.closePath = noop$1;
    }
    Reflect.apply(beginPath, ctx, []);
    this.children.forEach((child) => {
      if (!("path" in child)) {
        return;
      }
      let transform = "elementTransform" in child ? child.elementTransform() : null;
      if (!transform) {
        transform = Transform.fromElement(document2, child);
      }
      if (transform) {
        transform.apply(ctx);
      }
      child.path(ctx);
      if (contextProto) {
        contextProto.closePath = closePath;
      }
      if (transform) {
        transform.unapply(ctx);
      }
    });
    Reflect.apply(closePath, ctx, []);
    ctx.clip();
    if (contextProto) {
      contextProto.beginPath = beginPath;
      contextProto.closePath = closePath;
    }
  }
  render(_) {
  }
  constructor(...args) {
    super(...args);
    this.type = "clipPath";
  }
}
class FilterElement extends Element {
  apply(ctx, element) {
    const {document: document2, children} = this;
    const boundingBox = "getBoundingBox" in element ? element.getBoundingBox(ctx) : null;
    if (!boundingBox) {
      return;
    }
    let px = 0;
    let py = 0;
    children.forEach((child) => {
      const efd = child.extraFilterDistance || 0;
      px = Math.max(px, efd);
      py = Math.max(py, efd);
    });
    const width = Math.floor(boundingBox.width);
    const height = Math.floor(boundingBox.height);
    const tmpCanvasWidth = width + 2 * px;
    const tmpCanvasHeight = height + 2 * py;
    if (tmpCanvasWidth < 1 || tmpCanvasHeight < 1) {
      return;
    }
    const x = Math.floor(boundingBox.x);
    const y = Math.floor(boundingBox.y);
    const ignoredStyles = this.removeStyles(element, FilterElement.ignoreStyles);
    const tmpCanvas = document2.createCanvas(tmpCanvasWidth, tmpCanvasHeight);
    const tmpCtx = tmpCanvas.getContext("2d");
    document2.screen.setDefaults(tmpCtx);
    tmpCtx.translate(-x + px, -y + py);
    element.render(tmpCtx);
    children.forEach((child) => {
      if (typeof child.apply === "function") {
        child.apply(tmpCtx, 0, 0, tmpCanvasWidth, tmpCanvasHeight);
      }
    });
    ctx.drawImage(tmpCanvas, 0, 0, tmpCanvasWidth, tmpCanvasHeight, x - px, y - py, tmpCanvasWidth, tmpCanvasHeight);
    this.restoreStyles(element, ignoredStyles);
  }
  render(_) {
  }
  constructor(...args) {
    super(...args);
    this.type = "filter";
  }
}
FilterElement.ignoreStyles = [
  "filter",
  "transform",
  "clip-path"
];
class FeDropShadowElement extends Element {
  apply(_, _x, _y, _width, _height) {
  }
  constructor(document2, node2, captureTextNodes) {
    super(document2, node2, captureTextNodes);
    this.type = "feDropShadow";
    this.addStylesFromStyleDefinition();
  }
}
class FeMorphologyElement extends Element {
  apply(_, _x, _y, _width, _height) {
  }
  constructor(...args) {
    super(...args);
    this.type = "feMorphology";
  }
}
class FeCompositeElement extends Element {
  apply(_, _x, _y, _width, _height) {
  }
  constructor(...args) {
    super(...args);
    this.type = "feComposite";
  }
}
class FeGaussianBlurElement extends Element {
  apply(ctx, x, y, width, height) {
    const {document: document2, blurRadius} = this;
    const body = document2.window ? document2.window.document.body : null;
    const canvas = ctx.canvas;
    canvas.id = document2.getUniqueId();
    if (body) {
      canvas.style.display = "none";
      body.appendChild(canvas);
    }
    canvasRGBA(canvas, x, y, width, height, blurRadius);
    if (body) {
      body.removeChild(canvas);
    }
  }
  constructor(document2, node2, captureTextNodes) {
    super(document2, node2, captureTextNodes);
    this.type = "feGaussianBlur";
    this.blurRadius = Math.floor(this.getAttribute("stdDeviation").getNumber());
    this.extraFilterDistance = this.blurRadius;
  }
}
class TitleElement extends Element {
  constructor(...args) {
    super(...args);
    this.type = "title";
  }
}
class DescElement extends Element {
  constructor(...args) {
    super(...args);
    this.type = "desc";
  }
}
const elements = {
  svg: SVGElement,
  rect: RectElement,
  circle: CircleElement,
  ellipse: EllipseElement,
  line: LineElement,
  polyline: PolylineElement,
  polygon: PolygonElement,
  path: PathElement,
  pattern: PatternElement,
  marker: MarkerElement,
  defs: DefsElement,
  linearGradient: LinearGradientElement,
  radialGradient: RadialGradientElement,
  stop: StopElement,
  animate: AnimateElement,
  animateColor: AnimateColorElement,
  animateTransform: AnimateTransformElement,
  font: FontElement,
  "font-face": FontFaceElement,
  "missing-glyph": MissingGlyphElement,
  glyph: GlyphElement,
  text: TextElement,
  tspan: TSpanElement,
  tref: TRefElement,
  a: AElement,
  textPath: TextPathElement,
  image: ImageElement,
  g: GElement,
  symbol: SymbolElement,
  style: StyleElement,
  use: UseElement,
  mask: MaskElement,
  clipPath: ClipPathElement,
  filter: FilterElement,
  feDropShadow: FeDropShadowElement,
  feMorphology: FeMorphologyElement,
  feComposite: FeCompositeElement,
  feColorMatrix: FeColorMatrixElement,
  feGaussianBlur: FeGaussianBlurElement,
  title: TitleElement,
  desc: DescElement
};
function createCanvas(width, height) {
  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;
  return canvas;
}
async function createImage(src) {
  let anonymousCrossOrigin = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : false;
  const image = document.createElement("img");
  if (anonymousCrossOrigin) {
    image.crossOrigin = "Anonymous";
  }
  return new Promise((resolve, reject) => {
    image.onload = () => {
      resolve(image);
    };
    image.onerror = (_event, _source, _lineno, _colno, error) => {
      reject(error);
    };
    image.src = src;
  });
}
const DEFAULT_EM_SIZE = 12;
class Document {
  bindCreateImage(createImage1, anonymousCrossOrigin) {
    if (typeof anonymousCrossOrigin === "boolean") {
      return (source, forceAnonymousCrossOrigin) => createImage1(source, typeof forceAnonymousCrossOrigin === "boolean" ? forceAnonymousCrossOrigin : anonymousCrossOrigin);
    }
    return createImage1;
  }
  get window() {
    return this.screen.window;
  }
  get fetch() {
    return this.screen.fetch;
  }
  get ctx() {
    return this.screen.ctx;
  }
  get emSize() {
    const {emSizeStack} = this;
    return emSizeStack[emSizeStack.length - 1] || DEFAULT_EM_SIZE;
  }
  set emSize(value) {
    const {emSizeStack} = this;
    emSizeStack.push(value);
  }
  popEmSize() {
    const {emSizeStack} = this;
    emSizeStack.pop();
  }
  getUniqueId() {
    return "canvg".concat(++this.uniqueId);
  }
  isImagesLoaded() {
    return this.images.every((_) => _.loaded);
  }
  isFontsLoaded() {
    return this.fonts.every((_) => _.loaded);
  }
  createDocumentElement(document2) {
    const documentElement = this.createElement(document2.documentElement);
    documentElement.root = true;
    documentElement.addStylesFromStyleDefinition();
    this.documentElement = documentElement;
    return documentElement;
  }
  createElement(node2) {
    const elementType = node2.nodeName.replace(/^[^:]+:/, "");
    const ElementType = Document.elementTypes[elementType];
    if (ElementType) {
      return new ElementType(this, node2);
    }
    return new UnknownElement(this, node2);
  }
  createTextNode(node2) {
    return new TextNode(this, node2);
  }
  setViewBox(config2) {
    this.screen.setViewBox({
      document: this,
      ...config2
    });
  }
  constructor(canvg, {rootEmSize = DEFAULT_EM_SIZE, emSize = DEFAULT_EM_SIZE, createCanvas: createCanvas1 = Document.createCanvas, createImage: createImage2 = Document.createImage, anonymousCrossOrigin} = {}) {
    this.canvg = canvg;
    this.definitions = {};
    this.styles = {};
    this.stylesSpecificity = {};
    this.images = [];
    this.fonts = [];
    this.emSizeStack = [];
    this.uniqueId = 0;
    this.screen = canvg.screen;
    this.rootEmSize = rootEmSize;
    this.emSize = emSize;
    this.createCanvas = createCanvas1;
    this.createImage = this.bindCreateImage(createImage2, anonymousCrossOrigin);
    this.screen.wait(() => this.isImagesLoaded());
    this.screen.wait(() => this.isFontsLoaded());
  }
}
Document.createCanvas = createCanvas;
Document.createImage = createImage;
Document.elementTypes = elements;
class Canvg {
  static async from(ctx, svg) {
    let options = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
    const parser = new Parser(options);
    const svgDocument = await parser.parse(svg);
    return new Canvg(ctx, svgDocument, options);
  }
  static fromString(ctx, svg) {
    let options = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
    const parser = new Parser(options);
    const svgDocument = parser.parseFromString(svg);
    return new Canvg(ctx, svgDocument, options);
  }
  fork(ctx, svg) {
    let options = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
    return Canvg.from(ctx, svg, {
      ...this.options,
      ...options
    });
  }
  forkString(ctx, svg) {
    let options = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : {};
    return Canvg.fromString(ctx, svg, {
      ...this.options,
      ...options
    });
  }
  ready() {
    return this.screen.ready();
  }
  isReady() {
    return this.screen.isReady();
  }
  async render() {
    let options = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    this.start({
      enableRedraw: true,
      ignoreAnimation: true,
      ignoreMouse: true,
      ...options
    });
    await this.ready();
    this.stop();
  }
  start() {
    let options = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : {};
    const {documentElement, screen, options: baseOptions} = this;
    screen.start(documentElement, {
      enableRedraw: true,
      ...baseOptions,
      ...options
    });
  }
  stop() {
    this.screen.stop();
  }
  resize(width) {
    let height = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : width, preserveAspectRatio = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : false;
    this.documentElement.resize(width, height, preserveAspectRatio);
  }
  constructor(ctx, svg, options = {}) {
    this.parser = new Parser(options);
    this.screen = new Screen(ctx, options);
    this.options = options;
    const document2 = new Document(this, options);
    const documentElement = document2.createDocumentElement(svg);
    this.document = document2;
    this.documentElement = documentElement;
  }
}
export {AElement, AnimateColorElement, AnimateElement, AnimateTransformElement, BoundingBox, CB1, CB2, CB3, CB4, Canvg, CircleElement, ClipPathElement, DefsElement, DescElement, Document, Element, EllipseElement, FeColorMatrixElement, FeCompositeElement, FeDropShadowElement, FeGaussianBlurElement, FeMorphologyElement, FilterElement, Font, FontElement, FontFaceElement, GElement, GlyphElement, GradientElement, ImageElement, LineElement, LinearGradientElement, MarkerElement, MaskElement, Matrix, MissingGlyphElement, Mouse, PSEUDO_ZERO, Parser, PathElement, PathParser, PatternElement, Point, PolygonElement, PolylineElement, Property, QB1, QB2, QB3, RadialGradientElement, RectElement, RenderedElement, Rotate, SVGElement, SVGFontLoader, Scale, Screen, Skew, SkewX, SkewY, StopElement, StyleElement, SymbolElement, TRefElement, TSpanElement, TextElement, TextPathElement, TitleElement, Transform, Translate, UnknownElement, UseElement, ViewPort, compressSpaces, elements, getSelectorSpecificity, normalizeAttributeName, normalizeColor, parseExternalUrl, index as presets, toMatrixValue, toNumbers, trimLeft, trimRight, vectorMagnitude, vectorsAngle, vectorsRatio};
export default null;
