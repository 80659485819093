import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "shards-react";

const Footer = ({ primaryColor }) => {
  const { t } = useTranslation(["contact"]);

  const primaryColorText = {
    color: primaryColor,
    fontSize: "12pt",
  };

  const buttonStyle = {
    backgroundColor: "white",
    color: primaryColor,
    border: "1px solid " + primaryColor,
    fontSize: "12pt",
  };

  return (
    <footer className="footer">
      <Button
        className="button contact-button"
        style={buttonStyle}
        onClick={() => {
          document.getElementById("support_form").style.display = "block";
        }}
      >
        <FontAwesomeIcon icon={faEnvelope} />
        {" " + t("contact_us")}
      </Button>
      <span className="contact-text" style={primaryColorText}>
        © 2021 - {new Date().getFullYear() + " NINE SPORT s.r.o."}
      </span>
    </footer>
  );
};

export default Footer;
