import Cookies from "universal-cookie";

import "bootstrap/dist/css/bootstrap.min.css";
import "shards-ui/dist/css/shards.min.css";
import "./App.css";
import "./Components/Club/Club.css";
import "./Components/ClubResults/ClubResults.css";
import "./Components/DeleteDialog/DeleteDialog.css";
import "./Components/EnlargeImage/EnlargeImage.css";
import "./Components/Exercise/Exercise.css";
import "./Components/HealthRecord/HealthRecord.css";
import "./Components/Login/Login.css";
import "./Components/Maintenance/Maintenance.css";
import "./Components/Notification/Notification.css";
import "./Components/PaymentResult/PaymentResult.css";
import "./Components/Profile/Profile.css";
import "./Components/Review/Review.css";
import "./Components/RPE/RPE.css";
import "./Components/Sales/Sales.css";
import "./Components/SharedFiles/SharedFiles.css";
import "./Components/Sidebar/Sidebar.css";
import "./Components/Squad/Squad.css";
import "./Components/SquadResults/SquadResults.css";
import "./Components/SubscriptionManagement/SubscriptionManagement.css";
import "./Components/TestResults/TestResults.css";
import "./Components/TestTemplate/TestTemplate.css";
import "./Components/ClubDashboard/ClubDashboard.css";
import "./Components/Footer/Footer.css";

import tinycolor from "tinycolor2";
import { changeLanguage } from "i18next";
import React, { Suspense, useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  default as AuthService,
  default as authService,
} from "./Components/AuthService/AuthService";
import { Notification } from "./Components/Notification/Notification";
import Sidebar from "./Components/Sidebar/Sidebar";
import SquadForm from "./Components/Squad/SquadForm";
import MessageForm from "./Components/MessageForm/MessageForm";
import Footer from "./Components/Footer/Footer";
import ContactForm from "./Components/ContactForm/ContactForm";
import {ThemeProvider} from "./Components/Theming/ThemingProvider";
import {PopupProvider} from "./Components/Popup/PopupProvider";
import {ExportProvider} from "./Components/Export/ExportProvider";

const LazyAccountActivation = React.lazy(() =>
    import("./Components/AccountActivation/AccountActivation")
);
const LazyPasswordReset = React.lazy(() =>
    import("./Components/PasswordReset/PasswordReset")
);
const LazyLogin = React.lazy(() => import("./Components/Login/Login"));

const LazyMaintenance = React.lazy(() =>
    import("./Components/Maintenance/Maintenance")
);

const LazyProfile = React.lazy(() => import("./Components/Profile/Profile"));
const LazyMemberProfile = React.lazy(() =>
    import("./Components/Profile/MemberProfile")
);
const LazyMyTestResults = React.lazy(() =>
    import("./Components/TestResults/MyTestResults")
);
const LazyTestTemplateList = React.lazy(() =>
    import("./Components/TestTemplate/TestTemplateList")
);
const LazyExercisesContainer = React.lazy(() =>
    import("./Components/Exercise/ExercisesContainer")
);
const LazyMyAssignedExercises = React.lazy(() =>
    import("./Components/AssignedExercises/MyAssignedExercises")
);
const LazyEditAssignedExercises = React.lazy(() =>
    import("./Components/AssignedExercises/EditAssignedExercisesContainer")
);
const LazyEditTestResults = React.lazy(() =>
    import("./Components/TestResults/EditTestResultsContainer")
);
const LazyEditReview = React.lazy(() =>
    import("./Components/Review/EditReview")
);
const LazyClubsManagement = React.lazy(() =>
    import("./Components/Club/ClubsManagement")
);
const LazyClubResults = React.lazy(() =>
    import("./Components/ClubResults/ClubResults")
);
const LazySquadResults = React.lazy(() =>
    import("./Components/SquadResults/SquadResults")
);
const LazySubscriptionManagement = React.lazy(() =>
    import("./Components/SubscriptionManagement/SubscriptionManagement")
);
const LazyPaymentResult = React.lazy(() =>
    import("./Components/PaymentResult/PaymentResult")
);
const LazyImport = React.lazy(() =>
    import("./Components/Import/TestSelectList")
);
const LazySales = React.lazy(() => import("./Components/Sales/SalesList"));
const LazyHealthRecords = React.lazy(() =>
    import("./Components/HealthRecord/HealthRecordList")
);
const LazySquadTestResults = React.lazy(() =>
    import("./Components/SquadTestResults/SquadTestResults")
);
const LazySquad = React.lazy(() => import("./Components/Squad/SquadDetail"));
const LazySettings = React.lazy(() => import("./Components/Settings/Settings"));
const LazyClubDashboard = React.lazy(() =>
    import("./Components/ClubDashboard/ClubDashboard")
);
const LazyUserDashboard = React.lazy(() =>
    import("./Components/UserDashboard/UserDashboard")
);
const LazySharedDocuments = React.lazy(() =>
    import("./Components/SharedFiles/SharedDocuments")
);
const LazySharedPhotos = React.lazy(() =>
    import("./Components/SharedFiles/SharedPhotos")
);
const LazySharedVideos = React.lazy(() =>
    import("./Components/SharedFiles/SharedVideos")
);
const LazyUserOverview = React.lazy(() =>
    import("./Components/User/UserOverview")
);
const LazyTestOverview = React.lazy(() =>
    import("./Components/TestResults/TestResultsOverview")
);
const LazyClubTestOverview = React.lazy(() =>
    import("./Components/ClubResults/ClubResultsOverview")
);

function App() {
  const [colorRefresh, setColorRefresh] = useState(false);

  const [pc, setPc] = useState("rgb(105, 105, 105)");

  const [user, setUser] = useState(null);
  const [club, setClub] = useState(null);
  const [squad, setSquad] = useState(null);

  const [squadResultsShow, setSquadResultsShow] = useState(null);

  const [isAdmin, setIsAdmin] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [isClubManager, setIsClubManager] = useState(false);
  const [isSquadManager, setIsSquadManager] = useState(false);
  const [isRightClubManager, setIsRightClubManager] = useState(false);
  const [isRightSquadManager, setIsRightSquadManager] = useState(false);
  const [isRightUser, setIsRightUser] = useState(false);
  const [isExerciseManager, setIsExerciseManager] = useState(false);

  const [noClubs, setNoClubs] = useState(true);
  const [isExpired, setIsExpired] = useState(false);
  const [afterExpiration, setAfterExpiration] = useState(false);

  const [sidebarRefresh, setSidebarRefresh] = useState(false);

  useEffect(() => {
    const cookies = new Cookies();
    setPc(
        cookies.get("primaryColor")
            ? cookies.get("primaryColor")
            : "rgb(105, 105, 105)"
    );
  }, [colorRefresh]);



  useEffect(() => {
    const lighterPc = tinycolor(pc).brighten(85).toString();
    const root = document.documentElement;
    root.style.setProperty("--primary-color", "" + pc);
    root.style.setProperty("--lighter-primary-color", "" + lighterPc);
  }, [pc]);

  useEffect(() => {
    if (AuthService.getUserId()) {
      const roles = AuthService.getRoles();
      setIsAdmin(roles.includes("ADMIN"));
      setIsUser(roles.includes("USER"));
      setIsClubManager(roles.includes("CLUB_MANAGER"));
      setIsSquadManager(roles.includes("SQUAD_MANAGER"));
      setIsExerciseManager(roles.includes("EXERCISE_MANAGER"));

      if (
          !roles.includes("ADMIN") &&
          parseInt(authService.getUserId()) !== 259
      ) {
        changeLanguage("sk");
      }
    }
  }, []);

  if (process.env.REACT_APP_MAINTENANCE_MODE === "true") {
    return (
        <div className="App">
          <Suspense fallback={null}>
            <LazyMaintenance />
          </Suspense>
        </div>
    );
  } else {
    return (
        <div className="App">
          <CookieConsent
              style={{
                background: "white",
                borderColor: "rgb(105, 105, 105)",
                borderStyle: "solid",
                borderWidth: "1px",
                borderRadius: "4pt",
                color: "rgb(105, 105, 105)",
              }}
              buttonStyle={{
                background: "rgb(105, 105, 105)",
                borderColor: "rgb(105, 105, 105)",
                borderRadius: "4pt",
                color: "white",
              }}
              buttonText="Rozumiem"
          >
            Táto webová stránka používa súbory cookies. Prehliadaním webovej
            stránky vyjadrujete súhlas s ich používaním.
          </CookieConsent>
          <Notification primaryColor={pc} />
          <ThemeProvider pc={pc}>
            <ExportProvider>
                <PopupProvider>
                  <BrowserRouter>
                    {!AuthService.getUserId() && (
                        <Suspense fallback={null}>
                          <Routes>
                            <Route
                                exact
                                path="/activate"
                                element={<LazyAccountActivation />}
                            />
                            <Route
                                exact
                                path="/passwordreset"
                                element={<LazyPasswordReset />}
                            />
                            <Route path="*" element={<LazyLogin />} />
                          </Routes>
                        </Suspense>
                    )}
                    {AuthService.getUserId() && (
                        <Suspense fallback={null}>
                          <Sidebar
                              primaryColor={pc}
                              isAdmin={isAdmin}
                              isClubManager={isClubManager}
                              isSquadManager={isSquadManager}
                              isExerciseManager={isExerciseManager}
                              isUser={isUser}
                              noClubs={noClubs}
                              setNoClubs={setNoClubs}
                              setColorRefresh={setColorRefresh}
                              isExpired={isExpired}
                              setIsExpired={setIsExpired}
                              setAfterExpiration={setAfterExpiration}
                              globalSquad={squad}
                              setGlobalSquad={setSquad}
                              setGlobalClub={setClub}
                              sidebarRefresh={sidebarRefresh}
                              setSquadResultsShow={setSquadResultsShow}
                              isRightClubManager={isRightClubManager}
                              isRightSquadManager={isRightSquadManager}
                              isRightUser={isRightUser}
                              setIsRightClubManager={setIsRightClubManager}
                              setIsRightSquadManager={setIsRightSquadManager}
                              setIsRightUser={setIsRightUser}
                          />
                          <SquadForm
                              id="global_squad_form"
                              primaryColor={pc}
                              squad={null}
                              club={club}
                              setClub={setClub}
                              setRefresh={setSidebarRefresh}
                              setDeleteText={""}
                              setOnDelete={function () {}}
                          />
                          {(isClubManager || isSquadManager) && (
                              <MessageForm
                                  id="global_message_form"
                                  primaryColor={pc}
                                  isClubManager={isClubManager}
                              />
                          )}
                          <ContactForm primaryColor={pc} />
                          <div className="main-app-container cont_width">
                            <Footer primaryColor={pc} />
                            <Routes>
                              <Route
                                  exact
                                  path="/"
                                  element={
                                    (isRightClubManager || isRightSquadManager) && !isExpired ? (
                                        <LazyClubDashboard
                                            primaryColor={pc}
                                            setUser={setUser}
                                            setGlobalSquad={setSquad}
                                            setSidebarRefresh={setSidebarRefresh}
                                            setSquadResultsShow={setSquadResultsShow}
                                            isClubManager={isClubManager}
                                            club={club}
                                        />
                                    ) : (
                                        isRightUser &&
                                        !isExpired && (
                                            <LazyUserDashboard
                                                primaryColor={pc}
                                                setSidebarRefresh={setSidebarRefresh}
                                            />
                                        )
                                    )
                                  }
                              />
                              {isRightUser &&
                                  (isRightClubManager || isRightSquadManager) &&
                                  !isExpired && (
                                      <Route
                                          exact
                                          path="/userdashboard"
                                          element={
                                            <LazyUserDashboard
                                                primaryColor={pc}
                                                setSidebarRefresh={setSidebarRefresh}
                                            />
                                          }
                                      />
                                  )}
                              {(isRightClubManager || isRightSquadManager) &&
                                  isRightUser &&
                                  !isExpired && (
                                      <Route
                                          exact
                                          path="/clubdashboard"
                                          element={
                                            <LazyClubDashboard
                                                primaryColor={pc}
                                                setUser={setUser}
                                                setGlobalSquad={setSquad}
                                                setSidebarRefresh={setSidebarRefresh}
                                                setSquadResultsShow={setSquadResultsShow}
                                                isClubManager={isClubManager}
                                                club={club}
                                            />
                                          }
                                      />
                                  )}
                              <Route
                                  exact
                                  path="/member"
                                  element={
                                    <LazyMemberProfile
                                        primaryColor={pc}
                                        member={user}
                                        isAdmin={isAdmin}
                                        isClubManager={isClubManager}
                                        isSquadManager={isSquadManager}
                                    />
                                  }
                              />
                              <Route
                                  exact
                                  path="/profile"
                                  element={<LazyProfile primaryColor={pc} isUser={isUser} />}
                              />
                              {!isExpired && (
                                  <Route
                                      exact
                                      path="/testresults"
                                      element={<LazyMyTestResults primaryColor={pc} />}
                                  />
                              )}
                              {!isExpired && (
                                  <Route
                                      exact
                                      path="/testoverview"
                                      element={<LazyTestOverview primaryColor={pc} />}
                                  />
                              )}
                              {!isExpired && (
                                  <Route
                                      exact
                                      path="/testmanagement"
                                      element={<LazyTestTemplateList primaryColor={pc} />}
                                  />
                              )}
                              {(isAdmin ||
                                  (isRightClubManager && !isExpired) ||
                                  (isExerciseManager && !isExpired)) && (
                                  <Route
                                      exact
                                      path="/exercisemanagement"
                                      element={
                                        <LazyExercisesContainer
                                            primaryColor={pc}
                                            isAdmin={isAdmin}
                                            isClubManager={isClubManager}
                                            isSquadManager={isSquadManager}
                                            isExerciseManager={isExerciseManager}
                                        />
                                      }
                                  />
                              )}
                              {isAdmin && (
                                  <Route
                                      exact
                                      path="/useroverview"
                                      element={
                                        <LazyUserOverview primaryColor={pc} isAdmin={isAdmin} />
                                      }
                                  />
                              )}
                              <Route
                                  exact
                                  path="/assignedexercises"
                                  element={<LazyMyAssignedExercises primaryColor={pc} />}
                              />
                              <Route
                                  exact
                                  path="/editassignedexercises"
                                  element={
                                    <LazyEditAssignedExercises
                                        primaryColor={pc}
                                        user={user}
                                        club={club}
                                        squad={squad}
                                        isClubManager={isClubManager}
                                        isSquadManager={isSquadManager}
                                        isAdmin={isAdmin}
                                    />
                                  }
                              />
                              <Route
                                  exact
                                  path="/edittestresults"
                                  element={
                                    <LazyEditTestResults
                                        primaryColor={pc}
                                        user={user}
                                        isClubManager={isClubManager}
                                        isSquadManager={isSquadManager}
                                        isAdmin={isAdmin}
                                    />
                                  }
                              />
                              <Route
                                  exact
                                  path="/editreview"
                                  element={
                                    <LazyEditReview
                                        primaryColor={pc}
                                        user={user}
                                        isClubManager={isClubManager}
                                        isSquadManager={isSquadManager}
                                        isAdmin={isAdmin}
                                    />
                                  }
                              />
                              {isAdmin && (
                                  <Route
                                      exact
                                      path="/clubmanagement"
                                      element={
                                        <LazyClubsManagement
                                            primaryColor={pc}
                                            isAdmin={isAdmin}
                                            setGlobalClub={setClub}
                                            setUser={setUser}
                                            setSquad={setSquad}
                                        />
                                      }
                                  />
                              )}
                              {isRightClubManager && (
                                  <Route
                                      exact
                                      path="/clubresults"
                                      element={
                                        <LazyClubResults
                                            primaryColor={pc}
                                            isClubManager={isClubManager}
                                        />
                                      }
                                  />
                              )}
                              {(isRightClubManager || isRightSquadManager) && (
                                  <Route
                                      exact
                                      path="/squadresults"
                                      element={
                                        <LazySquadResults
                                            primaryColor={pc}
                                            isClubManager={isClubManager}
                                            isSquadManager={isSquadManager}
                                            externalShow={squadResultsShow}
                                            setUser={setUser}
                                        />
                                      }
                                  />
                              )}
                              {(isRightClubManager || isRightSquadManager) && (
                                  <Route
                                      exact
                                      path="/clubtestoverview"
                                      element={
                                        <LazyClubTestOverview
                                            primaryColor={pc}
                                            isClubManager={isClubManager}
                                        />
                                      }
                                  />
                              )}
                              {isRightClubManager && (
                                  <Route
                                      exact
                                      path="/clubsubscription"
                                      element={
                                        <LazySubscriptionManagement
                                            primaryColor={pc}
                                            isClubManager={isClubManager}
                                            club={club}
                                        />
                                      }
                                  />
                              )}
                              {isRightClubManager && (
                                  <Route
                                      exact
                                      path="/paymentresult"
                                      element={<LazyPaymentResult primaryColor={pc} />}
                                  />
                              )}
                              {(isRightClubManager || isRightSquadManager) && (
                                  <Route
                                      exact
                                      path="/import"
                                      element={<LazyImport primaryColor={pc} />}
                                  />
                              )}
                              <Route
                                  exact
                                  path="/sales"
                                  element={<LazySales primaryColor={pc} isAdmin={isAdmin} />}
                              />
                              <Route
                                  exact
                                  path="/shareddocuments"
                                  element={
                                    <LazySharedDocuments
                                        primaryColor={pc}
                                        isClubManager={isClubManager}
                                        isSquadManager={isSquadManager}
                                        isUser={isUser}
                                    />
                                  }
                              />
                              <Route
                                  exact
                                  path="/sharedphotos"
                                  element={
                                    <LazySharedPhotos
                                        primaryColor={pc}
                                        isClubManager={isClubManager}
                                        isSquadManager={isSquadManager}
                                        isUser={isUser}
                                    />
                                  }
                              />
                              <Route
                                  exact
                                  path="/sharedvideos"
                                  element={
                                    <LazySharedVideos
                                        primaryColor={pc}
                                        isClubManager={isClubManager}
                                        isSquadManager={isSquadManager}
                                        isUser={isUser}
                                    />
                                  }
                              />
                              <Route
                                  exact
                                  path="/healthrecords"
                                  element={
                                    <LazyHealthRecords primaryColor={pc} user={user} />
                                  }
                              />

                              {(isRightClubManager || isRightSquadManager) && (
                                  <Route
                                      exact
                                      path="/editsquadresults"
                                      element={
                                        <LazySquadTestResults primaryColor={pc} squad={squad} />
                                      }
                                  />
                              )}

                              <Route
                                  exact
                                  path="/squad"
                                  element={
                                    <LazySquad
                                        primaryColor={pc}
                                        squad={squad}
                                        club={club}
                                        isClubManager={isClubManager}
                                        isSquadManager={isSquadManager}
                                        isAdmin={isAdmin}
                                        isExpired={isExpired}
                                        setUser={setUser}
                                        setSquad={setSquad}
                                        setSidebarRefresh={setSidebarRefresh}
                                    />
                                  }
                              />
                              <Route
                                  exact
                                  path="/settings"
                                  element={
                                    <LazySettings
                                        primaryColor={pc}
                                        isClubManager={isClubManager}
                                        isExpired={isExpired}
                                        afterExpiration={afterExpiration}
                                        setColorRefresh={setColorRefresh}
                                        setGlobalClub={setClub}
                                    />
                                  }
                              />
                              <Route path="*" element={<div />} />
                            </Routes>
                          </div>
                        </Suspense>
                    )}
                  </BrowserRouter>
                </PopupProvider>
              </ExportProvider>
          </ThemeProvider>
        </div>
    );
  }
}

export default App;
