import { Canvg } from '../../lib/canvg/canvg@^4.0.0.js';

export class ExportUtil {
    static getDataUrlFromSvg(svgElement) {
        const svgString = new XMLSerializer().serializeToString(svgElement);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const v = Canvg.fromString(ctx, svgString);

        // Start SVG rendering with animations and mouse handling.
        v.start();
        return canvas.toDataURL();
    }

    static getDataUrlFromCanvas(canvasElement) {
        return canvasElement.toDataURL();
    }

    static downloadURI(dataUrl, name){
        const link = document.createElement("a");
        link.download = name;
        link.href = dataUrl;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    static getTimestamp(){
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(now.getDate()).padStart(2, '0');
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
    }

    static async shareBlob(blob, fileName) {
        if (!navigator.share) {
            console.error('Web Share API is not supported in this browser.');
            return;
        }
        try {
            const file = new File([blob], fileName, { type: blob.type });
            await navigator.share({
                files: [file],
                title: `Share ${fileName}`,
                text: `Sharing a ${fileName}`
            });
            console.log('Data successfully shared!');
        } catch (error) {
            console.error('Error sharing the data', error);
        }
    }

}


