import axios from "axios";
import $ from "jquery";
import moment from "moment";
import Cookies from "universal-cookie";
import API from "../Api/API";
import { handleErrorMessage } from "../ErrorMessages/ErrorMessages";

const getUserId = () => {
  return JSON.parse(localStorage.getItem("userId"));
};

const checkExpiration = async () => {
  return null;
};

const login = (e, email, password, lang) => {
  e.preventDefault();
  axios
    .post(
      API.url + "login",
      {
        email: email,
        password: password,
      },
      { withCredentials: true }
    )
    .then(async (response) => {
      saveTokens(response);

      if (response.data.roles.length === 0) {
        throw new Error("No roles");
      }

      const res = await fetchClubs();
      let sorted = res.sort((a, b) => a.name.localeCompare(b.name, lang));

      const cookies = new Cookies();
      const clubId = cookies.get("selectedClubId");
      if (!clubId) {
        if (sorted.length > 0) {
          const club = sorted[0];
          cookies.set("selectedClubId", club.id, {
            expires: new Date(moment().add(365, "d").format()),
          });
          if (club.primaryColor) {
            cookies.set("primaryColor", club.primaryColor, {
              expires: new Date(moment().add(365, "d").format()),
            });
          } else {
            cookies.remove("primaryColor");
          }
        }
      } else {
        const club = sorted.find((c) => c.id === parseInt(clubId));
        if (club) {
          if (club.primaryColor) {
            cookies.set("primaryColor", club.primaryColor, {
              expires: new Date(moment().add(365, "d").format()),
            });
          } else {
            cookies.remove("primaryColor");
          }
        }
        cookies.set("selectedClubId", clubId, {
          expires: new Date(moment().add(365, "d").format()),
        });
      }

      window.location.reload();
    })
    .catch(() => {
      $("#wrong_login_text").fadeIn(200);
    });
};

const fetchClubs = async () => {
  return axios
    .get(API.url + "api/club/allforuser", { withCredentials: true })
    .then((res) => res.data)
    .catch((error) => handleErrorMessage(error.response.status));
};

const logout = (e) => {
  if (e) e.preventDefault();
  axios.get(API.url + "logout", { withCredentials: true });
  localStorage.removeItem("roles");
  localStorage.removeItem("userId");
  window.location = "/";
};

const getCurrentUser = () => {
  return getUserId();
};

const saveTokens = (response) => {
  if (response.data.roles && response.data.userId) {
    localStorage.setItem("roles", JSON.stringify(response.data.roles));
    localStorage.setItem("userId", parseInt(response.data.userId));
  }
};

const getRoles = () => {
  return JSON.parse(localStorage.getItem("roles"));
};

const authService = {
  getCurrentUser,
  checkExpiration,
  login,
  logout,
  getRoles,
  getUserId,
};

export default authService;
