import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MenuItem = ({
  icon,
  text,
  href,
  navigate,
  className,
  id,
  clickEvent,
  setRefresh, 
  chevron
}) => {
  const Navigate = (e) => {
    e.preventDefault();
    if (navigate) {
      document.getElementById("sidebar").classList.remove("active");
      navigate(href);
      setRefresh(r => !r);
    }
  };

  return (
    <li id={id} className={className}>
      <a
        href="/#"
        onClick={(e) => {
          if (clickEvent) {
            e.preventDefault();
            clickEvent();
          }
          Navigate(e);
        }}
      >
        <FontAwesomeIcon icon={icon}/>
        <span className="links_name">{text}</span>
        {chevron && <FontAwesomeIcon icon={chevron} className="chevron-icon"/>}
      </a>
    </li>
  );
};

export default MenuItem;
