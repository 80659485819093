import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import tinycolor from "tinycolor2";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormInput,
  FormSelect,
  Row,
} from "shards-react";
import API from "../Api/API";
import AuthService from "../AuthService/AuthService";
import { handleErrorMessage } from "../ErrorMessages/ErrorMessages";
import { showNotification } from "../Notification/Notification";
import SquadManagersList from "./SquadManagersList";

const SquadForm = ({
  primaryColor,
  squad,
  club,
  setClub,
  setRefresh,
  setDeleteText,
  setOnDelete,
  id
}) => {
  const [name, setName] = useState("");
  const [sport, setSport] = useState(0);
  const [sports, setSports] = useState([]);

  const { t } = useTranslation(["club", "sports"]);

  useEffect(() => {
    const getData = async () => {
      if (squad != null) {
        setName(squad.name);
        setSport(squad.sport ? squad.sport.id : 0);
      } else {
        setName("");
        setSport(0);
      }
    };
    getData();
  }, [squad]);

  useEffect(() => {
    const getData = async () => {
      const response = await fetchSports();
      setSports(response);
    };
    getData();
  }, []);

  const fetchSports = async () => {
    return axios
      .get(API.url + "api/sport/all", { withCredentials: true })
      .then((res) => res.data)
      .catch((error) => handleErrorMessage(error.response.status));
  };

  const primaryColorText = {
    color: primaryColor,
  };

  const primaryColorBg = {
    backgroundColor: primaryColor,
    borderColor: primaryColor,
  };

  const lighterPrimaryBgColor = tinycolor(primaryColor).brighten(85).toString();
  const backgroundColorStyle = {
    backgroundColor: lighterPrimaryBgColor,
  };

  const hideSquadForm = (e) => {
    if (e) e.preventDefault();
    document.getElementById(id ? id : "squad_form").style.display = "none";
    if (squad)
      document.getElementById("new_manager_form_existing_squad").style.display =
        "none";
    if (squad)
      document.getElementById(
        "new_manager_email_form_existing_squad"
      ).style.display = "none";
    document.getElementById("squad_edit_form").style.display = "block";
    if (squad)
      document.getElementById("squad_managers").style.display = "block";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      id: squad ? squad.id : 0,
      name: name,
      clubId: club.id,
      members: squad ? squad.members : [],
      managers: squad ? squad.managers : [],
      sport:
        sport !== 0
          ? {
              id: sport,
            }
          : null,
    };
    savePayload(e, payload);
  };

  const savePayload = (e, payload) => {
    AuthService.checkExpiration().then(() => {
      axios
        .post(API.url + "api/squad/save", payload, { withCredentials: true })
        .then(() => {
          hideSquadForm(e);
          if (payload.id === 0) {
            showNotification(t("team_created"));
            setRefresh(r => !r);
          } else {
            showNotification(t("team_updated"));

            let tempSquads = [...club.squads];
            let index = tempSquads.findIndex((s) => s.id === payload.id);
            tempSquads[index] = payload;
            setClub({ ...club, squads: tempSquads });
          }
        })
        .catch((error) => {
          console.log(error);
          handleErrorMessage(error.response.status);
          hideSquadForm(e);
        });
    });
  };

  return (
    <div
      id={id ? id : "squad_form"}
      className="popup_form_container"
      style={{ display: "none" }}
    >
      <Card
        className="popup_form"
        style={backgroundColorStyle}
        onMouseDown={function (event) {
          event.stopPropagation();
        }}
      >
        <Button
          className={"close_dialog_button"}
          style={primaryColorText}
          onClick={hideSquadForm}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
        <CardBody>
          <Container className="height-100">
            <Row className="popup_form_title_container">
              <Col lg={{ size: 10, offset: 1 }}>
                <h1 className="squad_management_title" style={primaryColorText}>
                  {squad == null ? t("new_team") : t("team_edit")}
                </h1>
              </Col>
            </Row>
            <div className="popup_form_content_container">
              <Form
                onSubmit={handleSubmit}
                className="width-100"
                id="squad_edit_form"
              >
                <Row className="width-100 align_center popup_form_label_row">
                  <Col lg={{ size: 10, offset: 1 }}>
                    <label htmlFor="squad_name_input" className="form-label">
                      {t("team_name")}
                    </label>
                  </Col>
                </Row>
                <Row className="width-100 align_right popup_form_content_row ">
                  <Col lg={{ size: 10, offset: 1 }}>
                    <FormInput
                      size="lg"
                      type="text"
                      id="squad_name_input"
                      placeholder={t("team_name")}
                      value={name}
                      onChange={(event) => {
                        setName(event.target.value);
                      }}
                      required
                    />
                  </Col>
                </Row>
                <Row className="width-100 align_center popup_form_label_row">
                  <Col lg={{ size: 10, offset: 1 }}>
                    <label
                      htmlFor="squad_club_name_input"
                      className="form-label"
                    >
                      {t("club")}
                    </label>
                  </Col>
                </Row>
                <Row className="width-100 align_right popup_form_content_row ">
                  <Col lg={{ size: 10, offset: 1 }}>
                    <FormInput
                      size="lg"
                      type="text"
                      id="squad_club_name_input"
                      placeholder={t("club")}
                      value={club ? club.name : ""}
                      disabled
                    />
                  </Col>
                </Row>
                <Row className="width-100 align_center popup_form_label_row">
                  <Col lg={{ size: 10, offset: 1 }}>
                    <label htmlFor="squad_sport_input" className="form-label">
                      {t("sport", { ns: "sports" })}
                    </label>
                  </Col>
                </Row>
                <Row className="width-100 align_right popup_form_content_row ">
                  <Col lg={{ size: 10, offset: 1 }}>
                    <FormSelect
                      size="lg"
                      type="text"
                      id="squad_sport_input"
                      style={{
                        borderRadius: "0.375rem",
                        margin: "0px",
                        width: "100%",
                      }}
                      value={sport ? sport : 0}
                      onChange={(event) => {
                        setSport(event.target.value);
                      }}
                    >
                      {sports.map((sport, index) => (
                        <option key={"sport_option_" + index} value={sport.id}>
                          {t(sport.name, { ns: "sports" })}
                        </option>
                      ))}
                    </FormSelect>
                  </Col>
                </Row>
                <Row className="width-100 mt-4">
                  <Col lg={{ size: 4, offset: 4 }}>
                    <Button
                      className="width-100 button save-button"
                      style={primaryColorBg}
                    >
                      {t("save")}
                    </Button>
                  </Col>
                </Row>
              </Form>
              {squad && (
                <Row className="width-100">
                  <Col lg={{ size: 12 }}>
                    <SquadManagersList
                      primaryColor={primaryColor}
                      squad={squad}
                      setRefresh={setRefresh}
                      setDeleteText={setDeleteText}
                      setOnDelete={setOnDelete}
                    />
                  </Col>
                </Row>
              )}
            </div>
          </Container>
        </CardBody>
      </Card>
    </div>
  );
};

export default SquadForm;
