import { faInfoCircle, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import tinycolor from "tinycolor2";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormSelect,
  FormTextarea,
  Row,
  Tooltip,
} from "shards-react";
import Cookies from "universal-cookie";
import API from "../Api/API";
import authService from "../AuthService/AuthService";
import { handleErrorMessage } from "../ErrorMessages/ErrorMessages";
import { showNotification } from "../Notification/Notification";

const MessageForm = ({ primaryColor, id, isClubManager }) => {
  const { i18n, t } = useTranslation(["message"]);

  const [users, setUsers] = useState([]);
  const [squads, setSquads] = useState([]);
  const [club, setClub] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [showClub, setShowClub] = useState(false);
  const [messageFor, setMessageFor] = useState("user");
  const [messageInfo, setMessageInfo] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const cookies = new Cookies();
      const clubId = cookies.get("selectedClubId");

      let response = await fetchAllUsers(clubId);
      response = response.sort((a, b) => {
        return (a.lastName + " " + a.firstName).localeCompare(
          b.lastName + " " + b.firstName,
          i18n.language
        );
      });
      setUsers(response);

      response = await fetchAllSquads(clubId);
      response = response.sort((a, b) => {
        return a.name.localeCompare(b.name, i18n.language);
      });
      setSquads(response);

      response = await fetchClub(clubId);
      setClub(response);
    };
    getData();
  }, []);

  useEffect(() => {
    const userId = authService.getUserId();
    let found = false;
    if (club && club.managers) {
      for (const element of club.managers) {
        if (isClubManager && element.id === parseInt(userId)) {
          setShowClub(true);
          found = true;
          break;
        }
      }
    }
    if (!found) {
      setShowClub(false);
    }
  }, [club, isClubManager]);

  const fetchAllUsers = (clubId) => {
    return axios
      .get(API.url + "api/user/all-for-user/" + clubId, {
        withCredentials: true,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        handleErrorMessage(err.response.status);
      });
  };

  const fetchAllSquads = (clubId) => {
    return axios
      .get(API.url + "api/squad/all-for-user/" + clubId, {
        withCredentials: true,
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        handleErrorMessage(err.response.status);
      });
  };

  const fetchClub = async (id) => {
    return axios
      .get(API.url + "api/club/" + id, {
        withCredentials: true,
      })
      .then((res) => res.data)
      .catch((error) => handleErrorMessage(error.response.status));
  };

  const primaryColorText = {
    color: primaryColor,
  };

  const primaryColorBg = {
    backgroundColor: primaryColor,
    borderColor: primaryColor,
  };

  const lighterPrimaryColor = tinycolor(primaryColor).brighten(85).toString();
  const backgroundColorStyle = {
    backgroundColor: lighterPrimaryColor,
  };

  const lighterPrimaryColorBg = {
    backgroundColor: "lightgray",
    borderColor: "lightgray",
    color: "#555555",
  };

  const lighterBgColorStyle = {
    backgroundColor: "white",
    border: "1px solid " + primaryColor,
  };

  const closeDialog = (e) => {
    e.preventDefault();
    document.getElementById(id ? id : "message_form").style.display = "none";
    document.getElementById("message_input").value = "";
    if (document.getElementById("for_input")) {
      document.getElementById("for_input").value = 0;
    }
    setMessageFor("user");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisabled(true);
    const text = document.getElementById("message_input").value;

    const cookies = new Cookies();
    const clubId = cookies.get("selectedClubId");

    let userId = 0,
      squadId = 0;
    if (messageFor === "user") {
      userId = document.getElementById("for_input").value;
      if (userId === 0) {
        return;
      }
    } else if (messageFor === "squad") {
      squadId = document.getElementById("for_input").value;
      if (squadId === 0) {
        return;
      }
    }

    const payload = {
      text: text,
      userId: userId,
      squadId: squadId,
      clubId: clubId,
    };

    axios
      .post(API.url + "api/mail/send", payload, { withCredentials: true })
      .then(() => {
        closeDialog(e);
        setDisabled(false);
        document.getElementById("message_input").value = "";
        showNotification(t("message_sent_successfully"));
      })
      .catch((error) => handleErrorMessage(error.response.status));
  };

  return (
    <div
      id={id ? id : "message_form"}
      className="popup_form_container"
      style={{ display: "none" }}
    >
      <Card
        className="popup_form"
        style={backgroundColorStyle}
        onMouseDown={function (event) {
          event.stopPropagation();
        }}
      >
        <Button
          className={"close_dialog_button"}
          style={primaryColorText}
          onClick={closeDialog}
        >
          <FontAwesomeIcon icon={faTimes} />
        </Button>
        <CardBody>
          <Container>
            <Form onSubmit={handleSubmit}>
              <Row className="popup_form_title_container mb-3">
                <Col lg={{ size: 10, offset: 1 }}>
                  <h1
                    className="test_management_title"
                    style={primaryColorText}
                  >
                    {t("new_message") + " "}
                    <FontAwesomeIcon
                      id="assigned_exercise_info"
                      icon={faInfoCircle}
                      className="info_icon"
                    />
                    <Tooltip
                      target={"#assigned_exercise_info"}
                      className="tooltip"
                      open={messageInfo}
                      toggle={() => setMessageInfo(!messageInfo)}
                      placement="bottom"
                      style={lighterBgColorStyle}
                      noArrow={true}
                      trigger={window.innerWidth <= 640 ? "click" : "hover"}
                    >
                      <span style={primaryColorText} className="mb-0">
                        {t("message_info")}
                      </span>
                    </Tooltip>
                  </h1>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg={{ size: 10, offset: 1 }}>
                  <ButtonGroup className="buttons-container">
                    <Button
                      style={
                        messageFor === "user"
                          ? primaryColorBg
                          : lighterPrimaryColorBg
                      }
                      onClick={() => setMessageFor("user")}
                      className="button left-button wider-button"
                    >
                      <span>{t("user")}</span>
                    </Button>
                    <Button
                      style={
                        messageFor === "squad"
                          ? primaryColorBg
                          : lighterPrimaryColorBg
                      }
                      onClick={() => setMessageFor("squad")}
                      className={
                        "button wider-button " +
                        (showClub ? "middle-button" : "right-button")
                      }
                    >
                      <span>{t("squad")}</span>
                    </Button>
                    {showClub && (
                      <Button
                        style={
                          messageFor === "club"
                            ? primaryColorBg
                            : lighterPrimaryColorBg
                        }
                        onClick={() => setMessageFor("club")}
                        className="button right-button wider-button"
                      >
                        <span>{t("club")}</span>
                      </Button>
                    )}
                  </ButtonGroup>
                </Col>
              </Row>
              {(messageFor === "user" || messageFor === "squad") && (
                <Row>
                  <Col lg={{ size: 10, offset: 1 }}>
                    <FormSelect
                      id={"for_input"}
                      className="ml-0 mb-1"
                      style={{
                        borderRadius: "0.375rem",
                        margin: 0,
                        width: "100%",
                      }}
                    >
                      {messageFor === "user" && [
                        <option key="select_user" value={0}>
                          {t("select_user")}
                        </option>,
                        users.map((user) => (
                          <option value={user.id} key={"user_" + user.id}>
                            {user.lastName + " " + user.firstName}
                          </option>
                        )),
                      ]}
                      {messageFor === "squad" && [
                        <option key="select_squad" value={0}>
                          {t("select_squad")}
                        </option>,
                        squads.map((squad) => (
                          <option value={squad.id} key={"squad_" + squad.id}>
                            {squad.name}
                          </option>
                        )),
                      ]}
                    </FormSelect>
                  </Col>
                </Row>
              )}
              <Row className="mb-3">
                <Col lg={{ size: 10, offset: 1 }}>
                  <FormTextarea
                    id={"message_input"}
                    className={"overall_review_input"}
                    placeholder={t("message")}
                  />
                </Col>
              </Row>
              <Row className="mt-4">
                <Col lg={{ size: 4, offset: 4 }}>
                  <Button
                    className="width-100 button save-button"
                    style={primaryColorBg}
                    disabled={disabled}
                  >
                    {t("send")}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        </CardBody>
      </Card>
    </div>
  );
};

export default MessageForm;
